window._ = require('lodash');
window.moment = require('moment');
window.$ = window.jQuery = require('jquery');

import "../src/styles/main.css";
import "../src/styles/paperStyles.js";

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
import {GenerateScrollPickerValues} from "@/composables/setUp.js"
import './registerServiceWorker'

//install highlight
import { H } from 'highlight.run';
if (process.env.NODE_ENV != 'development'){
  H.init('2d1k1jer', {
    environment: 'production',
    version: 'commit:abcdefg12345',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://www.googleapis.com/identitytoolkit",
      "https://securetoken.googleapis.com",
      ],
    },
      enableCanvasRecording: true,        // enable canvas recording
      samplingStrategy: {
        canvas: 2,                        // snapshot at 2 fps
        canvasMaxSnapshotDimension: 480,  // snapshot at a max 480p resolution
      },
  });
}

async function main() {
  
  const app = createApp(App)
  registerPlugins(app)
  app.mount('#app')
  GenerateScrollPickerValues()
}

main();