<template>
  <v-btn class="myBtn" flat variant="outlined">
    <span style="font-size: 14px" class="mr-1">{{selectedSection.name}} <v-icon size="small" style="margin-bottom: 1px" color="grey-darken-2">mdi:mdi-lock</v-icon></span>
    <v-menu :close-on-content-click="false" v-model="menuOpen">
      <v-list height="360px" width="245px" style="padding: 0px !important; overflow-y:hidden" >

        <!-- Filter By Height -->
        <div class="px-2 py-1">
          <span style="font-size:12px">Filter By Height</span>
          <v-range-slider
            color="rgb(var(--v-theme-primary))"
            strict
            hide-details
            density="compact"
            :min="minShapeHeight"
            :max="maxShapeHeight"
            :model-value="heightRange"
            :step="1"
            @update:modelValue="filterByHeight($event, true)"
          >
            <template v-slot:prepend>
            <span v-if="heightRange">{{heightRange[0]}}</span>
            </template>
            <template v-slot:append>
            <span v-if="heightRange">{{heightRange[1]}}</span>
            </template>
          </v-range-slider>
        </div>

        <v-divider />

        <!-- Filter By Shape -->
        <div class="px-2 py-1">
          <div class="d-flex justify-space-between">
            <span style="font-size:12px;" >Filter By Shape</span>
          </div>
          <div class="d-flex justify-space-between">
            <div class="rounded" style="border: 1px solid lightGrey; width:fit-content; height: fit-content; padding:1px">
              <v-btn v-for="shape in shapeOptions" :key="shape" size="34"  @click="toggleActive(shape)"
                flat :style="shape.active ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : ''" style="margin:1px">
                <v-icon>{{shape.icon}}</v-icon>
              </v-btn>
            </div>
            <div class="rounded d-flex ml-1" style="border: 1px solid lightGrey; width:fit-content; height: fit-content; padding:1px">
              <v-btn :style="shapeUnit=='SI' ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : ''"
                size="34" flat style="margin:1px" :disabled="activeShape=='Custom'" @click="changeShapeUnit('SI')">
                SI
              </v-btn>
              <v-btn :style="shapeUnit=='US' ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : ''"
                size="34" flat style="margin:1px" :disabled="activeShape=='Custom'" @click="changeShapeUnit('US')">
                US
              </v-btn>
            </div>
          </div>
        </div>

        <v-divider class="mb-1"></v-divider>
        
        <!-- Steel Shapes List -->
        <v-infinite-scroll  :height="220" :items="shapesChunk" @load="loadMoreShapes">
          <template v-for="shape in shapesChunk" :key="shape">
            <v-list-item 
              min-height=40 @click="selectShape(shape)" :value="shape">
              <v-list-item-title style="font-size: 14px" class="d-flex justify-space-between">
                {{shape.name}}
              </v-list-item-title>
            </v-list-item> 
          </template>
          <template v-slot:empty>
          </template>
        </v-infinite-scroll>

      </v-list>
    </v-menu>
  </v-btn>

</template>

<script>
import {shapes_imperial, shapes_metric} from '@/constants/SteelShapes'
import {materials_imperial, materials_metric} from '@/constants/materials'
import * as utils from '@/utilities/Utils'
import {useStore} from '@/store/store'

export default{
  props: {
    inputSize: {
      type: Object,
      required: false
    },
  },
  components: {
  },
  setup(){
    const store = useStore()
    return {store, materials_imperial, materials_metric}
  },
  data: () => ({
    menuOpen: false,
    filteredShapes: null,
    minShapeHeight: null,
    maxShapeHeight: null,
    heightRange: null,
    filteredShapes: [],
    selectedSection: null,
    shapeOptions: {
      I: {active: false, icon: "mdi:mdi-format-italic", shape: "I"},
      Rect: {active: false, icon: "mdi:mdi-checkbox-blank-outline", shape: "Rect"},
      Circle: {active: false, icon: "mdi:mdi-checkbox-blank-circle-outline", shape: "Circle"}
    },
    allShapes: null,
    shapesChunk: [],
    itemsPerChunk: 50,
    filteredByShapeOnly: null,
    shapeUnit: null,
    createCustomSectionDialog: false,
    customSections: null,
  }),
  async created(){
    //Set defaults based on input size
    if (this.inputSize){
      if (this.inputSize == 'varies'){
        this.selectedSection = {name: 'varies', shape: 'I', family: 'imperial'}
      }
      else this.selectedSection = this.inputSize
      let shape = this.selectedSection.shape ? this.selectedSection.shape : 'Custom'
      this.shapeOptions[shape].active = true
    }

    //Set default shapes based on unit
    let sectionUnit
    if (this.selectedSection.shape){
      sectionUnit = utils.getSectionUnit(this.selectedSection)
    } else {
      sectionUnit = this.store.units == 'imperial' ? 'US' : 'SI'
    }
    if (sectionUnit == 'US'){
      this.allShapes = shapes_imperial
      this.shapeUnit = 'US'
      if (!this.selectedSection) {
        this.selectedSection = 'W14X40'
        this.shapeOptions["I"].active = true
      }
    }
    else {
      this.allShapes = shapes_metric
      this.shapeUnit = 'SI'
      if (!this.selectedSection) {
        this.selectedSection = 'W100X19.3'
        this.shapeOptions["I"].active = true
      }
    }
    //Apply Filters
    this.filterByShape()
    this.filteredShapes = this.filteredByShapeOnly
    this.setMinMaxShapeHeight()
    
    await this.loadMoreShapes({})
  },
  computed: {
    GridOn(){
      return this.store.gridState
    },
    activeShape(){
      for (var shape in this.shapeOptions){
        if (this.shapeOptions[shape].active) return shape
      }
    },
  },
  mounted() {
  },
  methods: {
    async sectionCreated(){
      let customSections = await this.getCustomSections()
      this.allShapes['Custom'] = customSections
      this.filterByShape()
    },
    changeShapeUnit(newUnit){
      if (this.shapeUnit == newUnit) return
      this.shapeUnit = newUnit

      if (this.shapeUnit == 'SI') {
        this.allShapes = shapes_metric
      }
      else {
        this.allShapes = shapes_imperial
      }
      this.shapesChunk = []
      this.filterByShape()
      this.filteredShapes = this.filteredByShapeOnly
      this.setMinMaxShapeHeight()
      this.loadMoreShapes({});
    },  
    filterByShape(){
      this.filteredByShapeOnly = this.allShapes[this.activeShape].flat()
      return this.filteredByShapeOnly
    },
    setMinMaxShapeHeight(){
      let heights = this.filteredByShapeOnly.map(shape => this.getHeightValue(shape))
      if (heights.length == 0) {
        this.minShapeHeight = 0
        this.maxShapeHeight = 0
        this.heightRange = [0, 0]
      } 
      else {
        this.minShapeHeight = Math.min(...heights)
        this.maxShapeHeight = Math.max(...heights)
      }
      this.heightRange = [this.minShapeHeight, this.maxShapeHeight]
    },
    getHeightValue(shape){
      return Number(shape.height && shape.height.value ? shape.height.value : shape.diameter ? shape.diameter.value : shape.height)
    },
    filterByHeight(e){
      //Set Range
      let rangeDecreasing = false
      let startChanging = false
      if (e[1]-e[0] < this.heightRange[1]-this.heightRange[0]){
        rangeDecreasing = true
      }
      if (e[0] != this.heightRange[0]){
        startChanging = true
      }
      if (e){
        this.heightRange[0] = e[0]
        this.heightRange[1] = e[1]
      }

      //Filter
      this.filteredShapes = this.filteredByShapeOnly.filter(shape => this.getHeightValue(shape) >= this.heightRange[0] && this.getHeightValue(shape) <= this.heightRange[1])

      //Set Shapes Chunk
      if (rangeDecreasing){
        let filteredShapeNames = this.filteredShapes.map(shape => shape.name)
        this.shapesChunk = this.shapesChunk.filter(shape => filteredShapeNames.includes(shape.name))
      }
      else {
        if (startChanging){
          if (!this.shapesChunk[0]) return
          let index = this.filteredShapes.findIndex(shape => shape.name == this.shapesChunk[0].name)
          let shapesToAdd = this.filteredShapes.slice(0,index)
          shapesToAdd.reverse().forEach(shape => this.shapesChunk.unshift(shape)) 
        }
        else {
          if (!this.shapesChunk[0]) return
          let index = this.filteredShapes.findIndex(shape => shape.name == this.shapesChunk[0].name)
          let shapesToAdd = this.filteredShapes.slice(0,index)
          let tempChunk = this.shapesChunk
          shapesToAdd.forEach(shape => tempChunk.unshift(shape))
          this.shapesChunk = this.shapesChunk.slice(0, this.shapesChunk.length+10)
        }
      }
    },
    selectShape(e){
      this.selectedSection = e
      this.$emit('sizeSelected', e)
      this.menuOpen = false
    },
    shapeSubtitle(shape){
      let material = shape.material.name ? shape.material : this.shapeUnit == 'US' ? shape.material.imperial : shape.material.metric
      let subtitle = `Section: ${shape.sectionType}<br> Material: ${material.name}`
      return subtitle
    },
    getCustomShapeTooltip(shape){
      let sectionText = `<b>Section Properties</b> <br>`
      let sectionPropsToNotInclude = ['name', 'sectionType', 'userId', 'material']
      for (let prop in shape){
        if (!sectionPropsToNotInclude.includes(prop)){
          let value = utils.Round(shape[prop].value, 2)
          sectionText = sectionText.concat(`${prop}: ${value} ${shape[prop].unit} <br>`)
        }
      }
      let materialText = `<br> <b>Material Properties</b> <br>`
      let materialPropsToNotInclude = ['name', 'id', 'userId']
      let material = shape.material[this.store.units] ? shape.material[this.store.units] : shape.material
      for (let prop in material){
        if (!materialPropsToNotInclude.includes(prop)){
          let materialName = material[prop].name ? material[prop].name : prop
          materialText = materialText.concat(`${materialName}: ${material[prop].value} ${material[prop].unit} <br>`)
        }
      }
      let text = sectionText.concat(materialText)
      return text
    },  
    toggleActive(shape){
      //Deactivate all shapes
      for (let shape in this.shapeOptions){
        this.shapeOptions[shape].active = false
      }
      //Activate selected shape
      this.shapeOptions[shape.shape].active = true
      //Apply Filters
      this.filterByShape()
      this.setMinMaxShapeHeight()
      if (shape.shape == 'Custom'){
        this.filteredShapes = this.filteredByShapeOnly
        this.shapesChunk = this.filteredByShapeOnly
      }
      else {
        this.filteredShapes = this.filteredByShapeOnly.filter(shape => shape.height >= this.heightRange[0] && shape.height <= this.heightRange[1])
        this.shapesChunk = this.filteredShapes.slice(0,this.itemsPerChunk)
      }
    },
    async getCustomSections(){
      try{
        let sections = await this.$api({
          url: "/api/section/_section/get-sections",
          method: "POST",
        })
        sections.data.forEach(section => {
          let defaultMaterial = this.isDefaultMaterial(section.material)
          if (defaultMaterial) section.material = defaultMaterial
        })
        return sections.data
      } catch (error){
        
      }
    },
    isDefaultMaterial(material){
      let defaultMat
      this.materials_imperial.forEach(defaultMaterial => {
        if (defaultMaterial.name == material) {
          defaultMat = defaultMaterial
        }
      })
      return defaultMat
    },
    async getNewShapes() {
      return new Promise(resolve => {
        const startIndex = this.shapesChunk.length;
        const endIndex = startIndex + this.itemsPerChunk;
        const newShapes = this.filteredShapes.slice(startIndex, endIndex);
        resolve(newShapes);
      });
    },
    async loadMoreShapes({ done }) {
      if (this.shapesChunk.length == this.filteredShapes.length) {
        try{
          done('empty')
        } catch {}
        return
      }
      // Get New Shapes
      const startIndex = this.shapesChunk.length;
      const endIndex = startIndex + this.itemsPerChunk;
      const newShapes = this.filteredShapes.slice(startIndex, endIndex);

      // Add the new shapes to the existing shapes array
      this.shapesChunk = this.shapesChunk.concat(newShapes);

      // Indicate that loading is complete
      try{
        done('ok')
      }
      catch {}
    },
  }
}
</script>

<style scoped>
.v-btn--size-default{
  padding: 0 8px !important
}
.myBtn.v-btn {
  letter-spacing: 0;
  font-weight:normal
}
</style>
