import {useStore} from '@/store/store'
import { shapes_imperial, shapes_metric } from '@/constants/SteelShapes';

//rgbComponents is an array formatted as [r,g,b] 
//converts rgb values between 0 and 1
export function rgbToHex(rgbComponents){
  const r = Math.round(rgbComponents[0] * 255);
  const g = Math.round(rgbComponents[1] * 255);
  const b = Math.round(rgbComponents[2] * 255);
  
  function componentToHex(c) {
    return c.toString(16).padStart(2, '0').toUpperCase();
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function interpolateColors(colors, factor){
  const n = colors.length - 1;
  const scaledFactor = factor * n;
  const i = Math.floor(scaledFactor);
  const localFactor = scaledFactor - i;

  const color1 = colors[i];
  const color2 = colors[i + 1];

  let rgbColor = color1.slice();
  for (let j = 0; j < 3; j++) {
    rgbColor[j] = Math.round(color1[j] + localFactor * (color2[j] - color1[j]));
  }

  //converts rgb values between 0 and 255
  function rgbToHex2(rgb) {
    return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
  }

  let hexColor = rgbToHex2(rgbColor)
  return hexColor;
}

export function findAssociatedText(item){
  const store = useStore()
  if (item.data.layer == store.drawingLayer.name){
    return store.memberSizesLayer.children.find(text => 
      text.data.elementId == item._id)
  }
  if (item.data.layer ==  store.loadLayer.name){
    return store.loadValuesLayer.children.find(text => 
      text.data.loadId == item._id)
  }
}
export function findAssociatedLoads(item){
  const store = useStore()
  return store.loadLayer.children.filter(element => 
    element.data.elementId == item._id)
}
export function findAssociatedLine(load){
  const store = useStore()
  return store.drawingLayer.children.find(element => 
    element._id == load.data.elementId)
}
export function findLineNodes(line){
  const store = useStore()
  return store.nodeLayer.children.filter(node => 
    node.data.lineId == line._id
  )  
}
export function findLineReleases(line){
  const store = useStore()
  return store.releaseLayer.children.filter(node => 
    node.data.elementId == line._id
  )  
}
export function findNodesSameLocation(searchNode, layerToSearch){
  const store = useStore()
  if (!layerToSearch) layerToSearch = store.nodeLayer
  return layerToSearch.children.filter(node => 
    ((Math.abs(node.data.location.x-searchNode.data.location.x) < 0.01) &&
      (Math.abs(node.data.location.y-searchNode.data.location.y) < 0.01))
  )
}

export function findLineByNode(node){
  const store = useStore()  
  let assocline
  store.drawingLayer.children.forEach(line => {
    if (line._id == node.data.lineId) return assocline = line
  })
  return assocline
}

export function findSupportSamePosition(point){
  const store = useStore()
  var supports = []
  store.supportLayer.children.forEach(support => {
    if (Math.abs(support.data.location.x - point.x) < 0.01 && Math.abs(support.data.location.y - point.y) < 0.01){
      supports.push(support)
    }
  })
  return supports
}

export function findPointLoadSamePosition(point){
  const store = useStore()
  var pointLoads = []
  store.loadLayer.children.forEach(load => {
    if (load.data.type == 'Point Load' && load.data.location){
      if (Math.abs(load.data.location.x - point.x) < 0.01 && Math.abs(load.data.location.y - point.y) < 0.01){
        pointLoads.push(load)
      }
    }
  })
  return pointLoads
}

export function findLineById(id){
  const store = useStore()
  return store.drawingLayer.children.find(element => 
    element._id == id)
}

export function pointsSameLocation(point1, point2){
  if (Math.abs(point1.x - point2.x) < 0.01 && Math.abs(point1.y - point2.y) < 0.01) return true
  else return false
}

/// return closest element end-point
export function getClosestElementPoint(e, tolerance, store){
  for (let i = 0; i < store.drawingLayer.children.length; i++){
    var path = store.drawingLayer.children[i]
    for (let j = 0; j < path.segments.length; j++){
      var endPoint = path.segments[j].point
      var distance = distance2Points(endPoint, e.point)
      if (distance < tolerance){
        return endPoint
      }
    }
  }
}

export function getAssociatedDimension(line){
  const store = useStore()
  let lineId = line._id
  let associatedDimension = null
  store.dimensionLayer.children.forEach(dimensionGroup => {
    if (dimensionGroup.data.elementId == lineId){
      associatedDimension = dimensionGroup
      return
    }
  })
  return associatedDimension
}

export function linesAreColinear(line1, line2){
  let line1Vector = line1.firstSegment.point.subtract(line1.lastSegment.point).normalize()
  let line2Vector = line2.firstSegment.point.subtract(line2.lastSegment.point).normalize()
  if (Math.abs(line1Vector.x - line2Vector.x) < 0.01 && Math.abs(line1Vector.y - line2Vector.y) < 0.01) return true
  else return false
}

export function getClosestLinePoint(e, tolerance){
  const store = useStore()
  var hitOptions = {
    stroke: true,
    fill: true,
    curves: true,
    ends: true,
    tolerance: tolerance,
  }
  let hitElement = store.drawingLayer.hitTest(e, hitOptions)
  if (hitElement){
    if (hitElement.type == 'segment') {
      return [null, hitElement.point]
    }
    else return [hitElement.item, hitElement.point]
  }
  else return [null, null]
}

export function deconstructPoint(point){
  return {x: point.x, y: point.y}
}

const distance2Points = (point1, point2) => {
  return Math.sqrt(Math.pow(point1.x-point2.x, 2) + Math.pow(point1.y-point2.y, 2))
}

const Round = (number, places) => {
  var multiplier = Math.pow(10, places)
  var roundedNumber = Math.round(number*multiplier)/multiplier
  return roundedNumber
}
export function formatNum(num, places){
  if (Number.isInteger(num)) return num
  else return num.toFixed(places)
}
export function getFrameType(line){
  const store = useStore()
  let zeroThreshold = 0.0001
  let lineHeight = Math.abs(line.bounds.height) < zeroThreshold ? 0 : line.bounds.height
  let lineWidth = Math.abs(line.bounds.width) < zeroThreshold ? 0 : line.bounds.width
  
  if (lineHeight != 0 && ToDegrees(Math.atan(lineWidth/lineHeight)) <= store.columnDegreesFromVert){
    return "column"
  }
  else if (lineWidth !=0 && ToDegrees(Math.atan(lineHeight/lineWidth)) <= store.beamDegreesFromHorz){
    return "beam"
  }
  else return "brace"
  function ToDegrees(radians){
    return radians*180 /Math.PI
  }
}

export function shallowClone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

//Custom deepClone function that is much faster than lodash version
export function deepClone(obj) {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(key =>{
    if (key.includes('_')) return
    if (typeof obj[key] === 'object') {
      if (key == 'location'){
        clone[key] = deconstructPoint(obj[key])
      }
      else clone[key] = deepClone(obj[key])
    }
    else clone[key] = obj[key]
  })
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export function calcWeight(line){
  const store = useStore()
  let weightPerLength
  let frameWeight = 0
  if (!line.data.notSelectable){
    if (line.data.frameProps.size.userId){
      weightPerLength = getWeightPerLength(line.data.frameProps.size)[store.units]
    } 
    else {
      weightPerLength = line.data.frameProps.size.weight
      if (!line.data.frameProps.size.family.includes(store.units)){
        let convertedWeight = weightPerLengthConversion(line.data.frameProps.size.weight)
        weightPerLength = convertedWeight
      }
    }
    let gridCellSizeProjected = store.grid.cellSize/store.grid.canvasZoom
    let length = (line.length/gridCellSizeProjected)*store.grid.realGridSpacing
    frameWeight = weightPerLength*length
  }
  return frameWeight
}

export function getBoundingBox(items){
  if (items.length > 1){
    var unitedBounds = items.reduce((bbox, item) => {
      let bounds
      if (item.bounds) bounds = item.bounds
      else bounds = item._bounds["000"].rect
      return !bbox ? bounds : bbox.unite(bounds)
    }, null)  
    return unitedBounds
  }
  else {
    if (items[0].bounds) return items[0].bounds
    else return items[0]._bounds["000"].rect
  }
}

export function tryConvertSectionSizeForKaramba(section){
  if (section.family == 'HSS-imperial'){
    let index = shapes_imperial.Circle.findIndex(shape => shape.name == section.name)
    return shapes_metric.Circle[index]
  }
}
export function getSectionUnit(section){
  if (section.family.includes("imperial")) return "US"
  else return "SI"
}

export function weightPerLengthConversion(weightPerLength){
  const store = useStore()
  if (store.units == 'imperial'){
    // 1 kg/m = 0.671969 lb/ft
    return weightPerLength*0.671969 
  }
  else {
    // 1 lb/ft = 1.48816 kg/m
    return weightPerLength*1.48816
  }
}

export function getWeightPerLength(shape){
  let areaM2 = shape.area.unit == 'cm2' ? shape.area.value/10000 : shape.area.value * 0.00064516
  let material = shape.material.metric ? shape.material.metric : shape.material
  let densityKGPerM3 = material.density.unit == 'kg/m³' ? material.density.value : material.density.value * 16.0184634
  let weightMetric = areaM2 * densityKGPerM3
  let weightImperial = weightMetric * 0.672
  return {
    metric: weightMetric,
    imperial: weightImperial
  }
}

// Karamba units are Length (m), Force (kN), E (kN/cm2), I (cm4), A (cm2), section Geom (cm), stress (kN/cm2)
// "ConvertTo" converts from user units to Karamba units
// "ConvertFrom" converts from Karamba units to user units 

export function inertiaConvertTo(inertia, currentUnit){
  if (currentUnit.includes('cm')) return inertia
  else return inertia*41.6231
}
export function areaConvertTo(area, currentUnit){
  if (currentUnit.includes('cm')) return area
  else return area*6.4516
}
export function EconvertTo(E, currentUnit){
  if (currentUnit.includes('GPa')) return E*100
  else return E*0.68947573
}
export function sectionGeomConvertTo(value, currentUnit){
  if (currentUnit.includes('cm')) return value
  else return value*2.54 
}
export function densityConvertTo(value, currentUnit){
  if (currentUnit.includes('kg/m³')) return value
  else return value * 16.0185
}
export function stressConvertTo(value, currentUnit){
  if (currentUnit.includes('MPa')) return value/10
  else return value * 6.89476
}

const forceConvertTo = (value) =>{
  var store = useStore()
  let unit = store.getPointLoadUnit
  if (unit == 'kips'){
    return value/0.2248089431
  }
  else if (unit == 'lbs'){
    return value/224.8089431
  }
  else if (unit == 'kN'){
    return value
  }
  else {
    return value/1000
  }
}
const lineLoadForceConvertTo = (value) => {
  var store = useStore()
  let lineLoadUnit = store.getLineLoadUnit
  let forceUnit = lineLoadUnit.split('/')[0]
  if (forceUnit == 'kips'){
    return value/0.2248089431
  }
  else if (forceUnit == 'lbs'){
    return value/224.8089431
  }
  else if (forceUnit == 'kN'){
    return value
  }
  else {
    return value/1000
  }
}
const areaforceConvertTo = (value) =>{
  var store = useStore()
  let unit = store.getAreaLoadUnit
  if (unit == 'kips'){
    return value/0.2248089431
  }
  else if (unit == 'lbs'){
    return value/224.8089431
  }
  else if (unit == 'kN'){
    return value
  }
  else {
    return value*1000
  }
}
const forceConvertFrom = (value) => {
  var store = useStore()
  var unit = store.getResultantForceUnit
  if (unit == 'kips'){
    return value*0.2248089431
  }
  else if (unit == 'lbs'){
    return value*224.8089431
  }
  else if (unit == 'kN'){
    return value
  }
  else {
    return value*1000
  }
}
const lengthConvertFrom = (value) => {
  var store = useStore()
  let unit = store.getLengthUnit
  if (unit == 'ft'){
    return value*3.281
  }
  else if (unit == 'in'){
    return value*39.37
  }
  else if (unit == 'm'){
    return value
  }
  else if (unit == 'cm'){
    return value*100
  }
}
const lengthConvertTo = (value) =>{
  var store = useStore()
  let unit = store.getLengthUnit
  if (unit == 'ft'){
    return value/3.281
  }
  else if (unit == 'in'){
    return value/39.37
  }
  else if (unit == 'm'){
    return value
  }
  else if (unit == 'cm'){
    return value/100
  }
}
export function deflectionConvertFrom(value){
  var store = useStore()
  let unit = store.getDeflectionUnit
  if (unit == 'ft'){
    return value*3.281
  }
  else if (unit == 'in'){
    return value*39.37
  }
  else if (unit == 'm'){
    return value
  }
  else if (unit == 'cm'){
    return value*100
  }
}
export function distributedLoadConvertTo(value){
  let force = lineLoadForceConvertTo(value)
  let forceOverLength = lengthConvertFrom(force)
  return forceOverLength
}
export function areaDistributedLoadConvertFrom(value){
  let force = areaforceConvertTo(value)
  let forceOverLength = lengthConvertFrom(force)
  return forceOverLength
}
export function momentConvertFrom(value){
  let force = forceConvertFrom(value)
  let forceLength = lengthConvertFrom(force)
  return forceLength
}
export function reorientLine(line){
  var leftPoint
  var rightPoint
  //non-vertical line
  if (line.segments[0].point.x < line.segments[1].point.x){
    leftPoint = line.segments[0]
    rightPoint = line.segments[1]
  }
  else if (line.segments[0].point.x > line.segments[1].point.x){
    rightPoint = line.segments[0]
    leftPoint = line.segments[1]
  }
  else{
    if (line.segments[0].point.y > line.segments[1].point.y){
      leftPoint = line.segments[0]
      rightPoint = line.segments[1]
    }
    else {
      rightPoint = line.segments[0]
      leftPoint = line.segments[1]
    }
  }
  return [leftPoint.point, rightPoint.point]
}

export {forceConvertTo, forceConvertFrom, areaforceConvertTo, lengthConvertFrom, lengthConvertTo, distance2Points, Round}
