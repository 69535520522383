<template>
  <div class="body">

    <!-- minigame -->
    <v-row class="rounded-lg d-flex" style="background: #F2F2F2">
      <div class="d-flex flex-column container" style="margin: 50px 0px">
        <div class="text-green" style="font-size: 20px">
          <span class="font-weight-bold"> FORCESKETCH MINI GAME </span>
          <span class="text-grey">[LIMITED TIME]</span>
        </div>
        <div style="color: black; font-size: 16px; font-weight: bold">
          A huge thank you to everyone that participated. Submissions are officially closed, but you can check out all the results data on the
          <a href="https://www.ellipse.studio/notebook/6743632320d61ac34cf9ccba/visitor/6757176198d72477a4467148#pages" target="blank">
            Ellipse Dashboard
          </a>
        </div>
        <div class="d-flex flex-wrap">
          <div :style="`cursor: pointer;`"
            class="py-2 pr-2">
            <v-card 
              tile 
              elevation="2" 
              height="250" width="250"
              class="d-flex justify-center align-center mb-1"
              style="border: 1px solid grey"
              @click="$router.push('/canvas/minigame/1'), store.projectId = null">
              <v-icon size=90 color="orange">fa: fa-solid fa-play</v-icon>
            </v-card>
            <span>Long Span Challenge</span> <span class="text-orange">[Closed]</span> 
          </div>
          <div :style="`cursor: pointer;`"
            class="py-2 px-2">
            <v-card 
              tile 
              elevation="2" 
              height="250" width="250"
              class="d-flex justify-center align-center mb-1"
              style="border: 1px solid grey"
              @click="$router.push('/canvas/minigame/2'), store.projectId = null">
              <!-- <v-icon size=90 color="grey">mdi:mdi-lock</v-icon> -->
              <v-icon size=90 color="purple">fa: fa-solid fa-play</v-icon>
            </v-card>
            <span>Tower Challenge <span class="text-purple">[Closed]</span> </span>
          </div>
          <div :style="`cursor: pointer;`"
            class="py-2 px-2">
            <v-card 
              tile 
              elevation="2" 
              height="250" width="250"
              class="d-flex justify-center align-center mb-1"
              style="border: 1px solid grey"
              @click="$router.push('/canvas/minigame/3'), store.projectId = null">
              <!-- <v-icon size=90 color="grey">mdi:mdi-lock</v-icon> -->
              <v-icon size=90 color="primary">fa: fa-solid fa-play</v-icon>
            </v-card>
            <span>Frame Challenge <span class="text-primary">[Closed]</span></span>
          </div>
        </div>
      </div>
    </v-row>

  <div class="container">
    <!-- Templates -->
    <div class="py-10">
      <div>
        <div class="mb-4 text-h6 text-primary">Start A New Sketch</div>
        <v-row class="d-flex">
          <div :style="`cursor: pointer;`"
            class="py-2 px-2">
            <v-card 
              tile 
              elevation="2" 
              height="250" width="250"
              class="d-flex justify-center align-center mb-1"
              style="border: 1px solid grey"
              @click="$router.push('/canvas'), store.projectId = null">
              <v-icon size=90 color="primary">mdi:mdi-plus</v-icon>
            </v-card>
            <span>New Blank Canvas</span>
          </div>
          <div v-for="template in templates" :key="template.name" 
            :style="`cursor: pointer;`" 
            class="py-2 px-2" >
            <router-link :to="{name: 'SavedCanvas', params: {id: `${'template' + template.id}`}}">
              <v-card 
                tile 
                elevation="2" height="250" width="250"
                style="border: 1px solid grey"
                class="d-flex justify-center align-center mb-1"
                @click="store.projectId=`${'template' + template.id}`"
              >
                <v-img :src="template.SVG"  style="width: 100%"></v-img>
              </v-card>
            </router-link>
            <span>{{template.name}}</span>
          </div>
        </v-row>
      </div>
    </div>

    <div>
      <div class="mb-1 text-h6 font-weight-bold d-flex justify-space-between">
        <span class="text-purple">Your Sketches</span>
        <v-btn v-if="canvases && canvases.length > 0" icon elevation="0" @click="displayList = !displayList">
          <v-icon>{{displayList ? 'mdi:mdi-view-module' : 'mdi:mdi-view-headline'}}</v-icon>
          <v-tooltip activator="parent" location="top">{{displayList ? 'View as grid' : 'View as list'}}</v-tooltip>
        </v-btn>
      </div>
      <div v-if="!isAuthenticated">Sign in to save and view your sketches for later.</div>
      <div v-else-if="loadingSketches" class="d-flex">
        <div ref="skeletonRow" style="width: 100%" class="d-flex">
          <v-card v-for="index in skeletonLoaders" :key="index" class="ma-2" width="250" height="315">
            <v-skeleton-loader
              type="image, heading, subtitle"
            ></v-skeleton-loader>
          </v-card>
        </div>
      </div>
     
      <div v-else class="d-flex">
        
        <!-- Card View -->
        <template v-if="!displayList">
          <div v-if="canvases && canvases.length==0">You haven't saved any sketches yet.</div>
          <v-row class="d-flex flex-wrap mb-12" v-if="canvases && canvases.length>0">
           <div :style="`cursor: pointer;`" class="py-2 px-2"
             v-for="canvas in canvases" :key="canvas.id">
            <v-card 
              tile
              width="250px"
              height="315px"
              class="d-flex flex-column"
              style="border: 1px solid grey"
              >
                <router-link :to="{name: 'SavedCanvas', params: {id: canvas.id}}">
                  <v-img :src="canvas.SVG" aspect-ratio="1" @click="sketchClick(canvas)"></v-img>
                </router-link>
                <v-divider></v-divider>
                <div class="pa-3 pt-2">
                  <div class="font-weight-bold d-flex align-center">
                    <span class="ellipsisText">{{canvas.name}}</span>
                    <v-tooltip location="top" text="Shared">
                      <template v-slot:activator="{ props }">
                      <span v-bind="props" v-if="canvas.permissions.length > 1" >
                        <v-icon size="25" class="ml-1">mdi:mdi-account-multiple</v-icon>
                      </span>
                      </template>
                    </v-tooltip>
                    <v-tooltip location="top" text="Viewer Access">
                      <template v-slot:activator="{ props }">
                      <span v-bind="props" v-if="getUserPermissions(canvas.permissions)=='Viewer'" >
                        <v-icon size="25" class="ml-1">mdi:mdi-eye</v-icon>
                      </span>
                      </template>
                    </v-tooltip>
                    <v-tooltip location="top" text="Viewer">
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" v-if="getUserPermissions(canvas.permissions)=='Editor'">
                          <v-icon  size="25" class="ml-1"> mdi:mdi-pencil</v-icon>
                        </span>
                      </template>
                    </v-tooltip>
                  </div>

                  <div style="font-size: 14px">Modified: {{momentTimestamp(canvas.createdAt)}}</div> 
                  
                  <v-menu>
                    <template v-slot:activator="{props}">
                      <v-btn icon variant="plain" size="x-small" v-bind="props"
                        style="position:absolute; bottom:5px; right:5px"
                        v-if="canvas.permissions != 'Viewer'">
                        <v-icon size="20">mdi:mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>              
                    <v-list density="compact">
                      <v-list-item @click="sketchMenuClick('rename', canvas)" value="rename">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-pen-to-square</v-icon>
                          Rename
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item @click="sketchMenuClick('duplicate', canvas)" value="duplicate">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-copy</v-icon>
                          Duplicate
                        </v-list-item-title>
                      </v-list-item> -->
                      <v-list-item @click="sketchMenuClick('share', canvas)" value="share">
                        <v-list-item-title>
                          <v-icon size="small" class="mr-2">mdi:mdi-account-multiple-plus</v-icon>
                          Share
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="sketchMenuClick('delete', canvas)" value="delete">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-trash</v-icon>
                          Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </div>
            </v-card>
           </div>
          </v-row>
        </template>
        
        <!-- List View -->
        <template v-else>
          <v-table style="width: 100%; margin-bottom: 50px">
            <thead>
              <tr>
                <th><b>Name</b></th>
                <th><b>Modified</b></th>
                <th><b>Access</b></th>
                <th><b>Owner</b></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="canvases && canvases.length>0">
              <tr v-for="canvas in canvases" :key="canvas.id" @click="$router.push(`/canvas/${canvas.id}`)">
                <td class="d-flex">
                  <v-col cols="3" class="pa-0">
                    <v-img :src="canvas.SVG"  aspect-ratio="1" style="width:75px"></v-img>
                  </v-col>
                  <v-col>
                    {{canvas.name}} 
                    <v-icon v-if="canvas.permissions.length > 1" size="25" class="ml-1">
                      mdi:mdi-account-multiple
                    </v-icon>
                  </v-col>
                </td>
                <td>{{momentTimestamp(canvas.createdAt)}}</td>
                <td>
                  <v-chip 
                    :color="getUserPermissions(canvas.permissions) =='Owner' ? 'black' : getUserPermissions(canvas.permissions) =='Viewer' ? 'orange' : 'primary'">
                    {{getUserPermissions(canvas.permissions)}}
                    <v-icon v-if="canvas.permissions=='Viewer'" class="ml-1">mdi:mdi-eye</v-icon>
                    <v-icon v-if="canvas.permissions=='Editor'" class="ml-1">mdi:mdi-pencil</v-icon>
                  </v-chip>
                </td>
                <td>{{canvas.createdBy.email}}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{props}">
                      <v-btn icon variant="plain" v-bind="props" size="small" v-if="canvas.permissions != 'Viewer'">
                        <v-icon>mdi:mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>              
                    <v-list density="compact">
                      <v-list-item @click="sketchMenuClick('rename', canvas)" value="rename">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-pen-to-square</v-icon>
                          Rename
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item @click="sketchMenuClick('duplicate', canvas)" value="duplicate">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-copy</v-icon>
                          Duplicate
                        </v-list-item-title>
                      </v-list-item> -->
                      <v-list-item @click="sketchMenuClick('share', canvas)" value="share" >
                        <v-list-item-title>
                          <v-icon size="small" class="mr-2">mdi:mdi-account-multiple-plus</v-icon>
                          Share
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="sketchMenuClick('delete', canvas)" value="delete">
                        <v-list-item-title>
                          <v-icon size="x-small" class="mr-2">fa:fas fa-trash</v-icon>
                          Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </v-table>
        </template>
      </div>

      <!-- delete dialog -->
      <AreYouSure 
        :dialog="deleteDialog" 
        :onClick="Delete"
        :loading="deleteLoading"
        @deleteCancel="deleteDialog=!deleteDialog, selectedSketch=null">
        Are you sure you want to delete the sketch?
        </AreYouSure>

      <!-- rename dialog -->
      <v-dialog
        v-model="renameDialog"
        width="450"
        >
        <v-card class="pa-2">
          <v-card-title>
            Rename Sketch
          </v-card-title>
          <v-text-field
            class="px-4 mt-2" 
            label="Name" 
            variant="outlined" density="compact"
            v-model="newSketchName"
          ></v-text-field>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="red" @click="renameDialog=false, selectedSketch=null">Cancel</v-btn>
            <v-btn color="primary" @click="Rename()" :loading="renameLoading">Rename</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Share Dialog -->
      <ShareDialog 
        :shareDialog="shareDialog" 
        :sketch="selectedSketch" 
        @shareDialogClosed="shareDialog=false">
      </ShareDialog>
    </div>  
  </div>
  </div>
</template>

<script>
import {useStore} from '@/store/store'
import AreYouSure from '@/components/ui/AreYouSure.vue';
import ShareDialog from '@/components/ui/ShareDialog.vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: {
    AreYouSure,
    ShareDialog
  },
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    return {
      store,
      isAuthenticated,
      user
    }
  },
  data() {
    return {
      templates: [],
      canvases: [],
      selectedSketch: null,
      deleteDialog: false,
      renameDialog: false,
      renameLoading: false,
      deleteLoading: false,
      newSketchName: '',
      displayList: false,
      shareDialog: false,
      selectedSketchUsers: null,
      skeletonLoaders: [],
      loadingSketches: false
    };
  },
  async created(){
    this.loadingSketches = true
    this.getTemplates()
    if (this.user && this.isAuthenticated){
      await this.getCanvases()
    }
    this.loadingSketches = false
  },
  computed: {
  },
  watch: {
    "user": function(){
      this.getCanvases()
    }
  },
  async mounted() {
    this.calculateSkeletonLoaders();
  },
  computed: {},
  methods: {
    loadTemplate(name){
      this.store.project = name
      this.$router.push('/canvas')
    }, 
    async getCanvases(){
      let sketches = await this.$api({
        url: "/api/sketch/_sketch/get-all",
        method: "POST",
        data: {
          auth0User: this.user
        }
      })
      this.canvases = sketches.data
    },
    async getTemplates(){
      let templates = await this.$api({
        url: "/api/template/_template/get-all",
        method: "POST",
      })
      this.templates = templates.data
    },
    getUserPermissions(users){
      let user = users.find(user => user.email.toLowerCase() == this.user.email.toLowerCase())
      return user.permissions
    },
    momentTimestamp: function (timestamp) {
      var date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000)
      return moment(date).format("MM/DD/YYYY hh:mm a");
    },
    async Delete() {
      this.deleteLoading = true
      let deleted = await this.$api({
        url: "/api/sketch/_sketch/delete-sketch",
        method: "POST",
        data: {
          imageInfo: JSON.stringify(this.selectedSketch.imageInfo),
          sketchId: this.selectedSketch.id
        }
      })
      if (deleted.data.success){
        await this.getCanvases()
      }
      this.selectedSketch = null
      this.deleteLoading = false
      this.deleteDialog = false
    },
    async Rename(){
      this.renameLoading=true
      await this.$api({
        url: "/api/sketch/_sketch/rename-sketch",
        method: "POST",
        data: {
          id: this.selectedSketch.id,
          name: this.newSketchName
        }
      })
      this.selectedSketch.name = this.newSketchName
      this.selectedSketch = null
      this.renameLoading=false
      this.renameDialog=false
    },
    sketchMenuClick(menuItem, canvas){
      if (menuItem == "rename"){
        this.renameDialog = true
        this.selectedSketch = canvas
        this.newSketchName = canvas.name
      }
      else if (menuItem == 'share'){
        this.selectedSketch = canvas
        this.shareDialog = true
      }
      else {
        this.deleteDialog = true
        this.selectedSketch = canvas
      }
    },
    sketchClick(sketch){
      this.store.projectId = sketch.id
      this.store.users = sketch.permissions
      this.store.userPermissions = this.getUserPermissions(sketch.permissions)
    },
    calculateSkeletonLoaders() {
      const parentWidth = this.$refs.skeletonRow.clientWidth;
      const loaderWidth = 250; 
      const loadersPerRow = Math.floor(parentWidth / loaderWidth);
      this.skeletonLoaders = Array.from({ length: loadersPerRow });
    },
  },
};
</script>

<style scoped>
tr:hover {
  cursor: pointer;
}
.container{
  padding: 0px 40px;
}
/* Media query for screens 900px wide and above */
@media only screen and (min-width: 960px) {
  .container {
    padding: 0px 150px;
  }
}

/* Media query for screens 900px wide and above */
@media only screen and (min-width: 1264px) {
  .container {
    padding: 0px 200px;
  }
}
.body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: 100%;
  }
</style>
