import {useStore} from '@/store/store'
import {LineCommand, BatchCommand, SupportCommand, LoadCommand} from "@/utilities/Commands"
import paper from 'paper'
import * as styles from '../styles/paperStyles.js'
import History from "@/utilities/History"
import * as modifiers from "@/utilities/Modifiers"
import * as utils from '@/utilities/Utils'

export const copy = () => {
  const store = useStore()
  store.startPastePoint = null
  store.pastePoint = null
  store.copy = true

  var elements = []
  var supports = []
  var pointLoads = []
  var lineLoads = []
  store.selectedItems.forEach(item => {
    if (item.data.layer == 'drawing'){
      var lineProps = {
        id: item.id,
        start: {x: item.segments[0].point.x, y: item.segments[0].point.y},
        end: {x: item.segments[1].point.x, y: item.segments[1].point.y},
        startFixity: item.data.start,
        endFixity: item.data.end,
        frameProps: item.data.frameProps,
      }
    elements.push(lineProps)
    }
    else if (item.data.layer == 'support'){
      var supportProps = {
        type: item.data.type,
        location: {x: item.data.location.x, y: item.data.location.y},
        id: item.id
      }
      supports.push(supportProps)
    }
    else if (item.data.type && item.data.type == 'Point Load'){
      var pointLoadProps = {
        type: item.data.type,
        dir: item.data.dir,
        location: {x: item.data.location.x, y: item.data.location.y},
        magnitude: item.data.magnitude,
      }
      pointLoads.push(pointLoadProps)
    }
    else if (item.data.type && item.data.type.includes('Line')){
      var lineLoadProps = {
        type: item.data.type,
        dir: item.data.dir,
        elementId: item.data.elementId,
        magnitude: item.data.magnitude,
      }
      lineLoads.push(lineLoadProps)
    }
  })
  store.copiedData = {
    elements: elements,
    supports: supports,
    lineLoads: lineLoads,
    pointLoads: pointLoads
  }

  store.setSelectedItem(null)
  
  //Snack Bar
  store.snackbarText = "Select Point To Copy From"
  store.snackbarTimeOut = -1
  store.snackbar = true
}

export const paste = () => {
  const store = useStore()
  let vector = store.pastePoint.subtract(store.startPastePoint)
  let batchCommand = new BatchCommand()

  //Lines
  store.copiedData.elements.forEach(element => {
    let start = new paper.Point(element.start)
    let end = new paper.Point(element.end)
    let startTranslated = start.add(vector)
    let endTranslated = end.add(vector)
    var path = new paper.Path(styles.line())
    path.add(startTranslated, endTranslated)
    path.data = {
      start: element.startFixity,
      end: element.endFixity,
      frameProps: element.frameProps,
      layer: store.drawingLayer.name,
    }
    batchCommand.add(new LineCommand(path, true))
    store.tools.draw.addMemberSizeText(path)
    store.drawingLayer.addChild(path)
    store.tools.draw.addDimensions(path)
    store.tools.draw.addEndNode(path)
    if (path.data.start == 'released'){
      let release = modifiers.drawReleaseIcon(path, 'start')
      store.releaseLayer.addChild(release)
    }
    if (path.data.end == 'released'){
      let release = modifiers.drawReleaseIcon(path, 'end')
      store.releaseLayer.addChild(release)
    }
    //Find and add assoc line load
    store.copiedData.lineLoads.forEach(async(load) => {
      if (load.elementId == element.id){
        let isLineMass = load.type == 'Line Mass' ? true : false
        let lineLoad = await store.tools.loads.scaleLineLoad(path, load.magnitude, load.dir, isLineMass)
        store.tools.loads.addLoadText(lineLoad)
        batchCommand.add(new LoadCommand(lineLoad, true))
      }
    })
  });
  //Supports
  store.copiedData.supports.forEach(support => {
    var supportIcon = store.tools.supports.newSupportIcon(support.type)
    store.tools.supports.scaleInitial(supportIcon)
    supportIcon.strokeColor = 'black'
    let location = new paper.Point(support.location)
    let translatedLocation = location.add(vector)
    supportIcon.position = {x: translatedLocation.x, y: translatedLocation.y+supportIcon.bounds.height/2}
    supportIcon.data = {
      type: support.type,
      location: translatedLocation,
      layer: store.supportLayer.name
    }
    store.supportLayer.addChild(supportIcon)
    batchCommand.add(new SupportCommand(supportIcon, true))
  })
  //Point Loads
  store.copiedData.pointLoads.forEach(load => {
    let location = new paper.Point(load.location)
    let translatedLocation = location.add(vector)
    let pointLoad = store.tools.loads.scalePointLoad(translatedLocation, load.magnitude, load.dir)
    store.tools.loads.addLoadText(pointLoad)
    batchCommand.add(new LoadCommand(pointLoad, true))
  })

  History.add(batchCommand)
  if (store.tools.draw.snappingIcon) store.tools.draw.snappingIcon.remove()
  
  //Reset Store Values
  store.copy = false
  store.copiedData = null
  store.pastePoint = null
  store.startPastePoint = null
  store.copyPastePoint = null

  //Snackbar
  store.snackbarTimeOut = null
  store.snackbarText = ''
  store.snackbar = false  
}

export const move = (translationVector) => {
  const store = useStore()

  let batchCommands = new BatchCommand()

  store.nodeLayer.strokeColor = 'black'
  store.selectedItems.forEach(item => {
    let itemType = item.data.layer
    switch (itemType){
      case 'drawing':
        let lineCommand = new LineCommand(null, false, item)
        item.translate(translationVector)
        lineCommand.addCurPath(item)
        batchCommands.add(lineCommand)

        let text = utils.findAssociatedText(item)
        text.translate(translationVector)

        let lineLoads = utils.findAssociatedLoads(item)
        lineLoads.forEach(load => {
          let loadCommand = new LoadCommand(null, false, load)
          load.translate(translationVector)
          loadCommand.addCurLoad(load)
          batchCommands.add(loadCommand)
          let lineLoadText = utils.findAssociatedText(load)
          lineLoadText.translate(translationVector)
        })
        
        let dimLine = utils.getAssociatedDimension(item)
        dimLine.data.start.add(translationVector)
        dimLine.data.end.add(translationVector)
        dimLine.translate(translationVector)
        
        let nodes = utils.findLineNodes(item)
        nodes.forEach(node => {
          node.translate(translationVector)
          node.style.strokeColor = styles.highlightColor
        })
        
        let releases = utils.findLineReleases(item)
        releases.forEach(release => release.translate(translationVector))
        break;
      case 'support':
        let supportCommand = new SupportCommand(null, false, item)
        let supportPoint = new paper.Point(item.data.location)
        item.data.location = supportPoint.add(translationVector)
        item.translate(translationVector)
        supportCommand.addCurSupport(item)
        batchCommands.add(supportCommand)
        break;
      case 'load':
        if (item.data.type == 'Point Load'){
          let loadCommand = new LoadCommand(null, false, item)
          let loadPoint = new paper.Point(item.data.location)
          item.data.location = loadPoint.add(translationVector)
          let loadText = utils.findAssociatedText(item)
          loadText.translate(translationVector)
          item.translate(translationVector)
          loadCommand.addCurLoad(item)
          batchCommands.add(loadCommand)
        }
    }
  })
  History.add(batchCommands)
  if (store.tools.draw.snappingIcon) store.tools.draw.snappingIcon.remove()

  store.snackbar = false
  store.moving = false
  store.moveStartPoint = null
  store.snackbarTimeOut = null
  store.snackbarText = ''
}
