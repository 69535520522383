<template>
    <!--Dialog -->
    <v-dialog
      :model-value="shareDialog"
      class="dialog"
      id="dialog"
      @update:modelValue="dialogClosed"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between" @click="$emit('shareDialogClosed')">
          Share Sketch
          <v-btn icon="mdi-close" variant="text"></v-btn>
        </v-card-title>
        <div class="d-flex align-center" :class="textField">
          <v-text-field
            ref="emailTextField"
            class="px-4 mt-2" 
            label="Add People" 
            variant="outlined"
            v-model="email"
            type="email"
            :rules="emailRules"
            clearable
            :error-messages="errorMessage"
          ></v-text-field>
          <v-btn variant="text" icon flat size="30" color="primary" @click="addUser" class="ml-2 mr-6" 
            :disabled="!isValidEmail" :loading="addUserLoading" style="margin-top: -12px">
            <v-icon size="30">mdi:mdi-plus-circle</v-icon>
          </v-btn>
        </div>
        <div class="px-5 my-5" style="overflow-x: auto; ">
          <div v-for="(user, i) in users" :key="user" class="d-flex justify-space-between mb-2" >
            <div :style="user.permissions == 'Owner' ? 'color:grey' : ''">{{user.email}}</div>
            <div style="width: 150px">
              <v-btn width="150" :disabled="store.userPermissions == 'Viewer' || user.permissions == 'Owner'" 
                elevation="0" variant="text" :loading="loading && loadingIndex==i">
                {{user.permissions}}
                <v-icon class="ml-3">mdi:mdi-menu-down</v-icon>
                <v-menu activator="parent">
                  <v-list density="compact">
                    <v-list-item value="viewer" @click="changeUserRole(user, 'Viewer'), this.loadingIndex = i">
                      <v-list-item-title><v-icon class="mr-2">mdi:mdi-eye</v-icon>Viewer</v-list-item-title>
                    </v-list-item>
                    <v-list-item value="editor" @click="changeUserRole(user, 'Editor'), this.loadingIndex = i">
                      <v-list-item-title><v-icon class="mr-2">mdi:mdi-pencil</v-icon>Editor</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item value="remove" @click="removeUser(user), this.loadingIndex = i">
                      <v-list-item-title><v-icon class="mr-2">mdi:mdi-close</v-icon>Remove</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-if="snackbar" class="d-flex justify-center mb-3">
          <div style="width: 480px; height: 50px" class="bg-primary pa-1 px-2 rounded elevation-3 d-flex justify-space-between align-center">
            <span class="text-white text-capitalize">{{snackbarText}}</span>
            <v-btn variant="text" class="text-decoration-underline" color="white" v-if="removedUser" @click="undoRemoveUser" :loading="undoLoading">
              Undo
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

</template>

<script>
import {useStore} from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import { useAuth0 } from '@auth0/auth0-vue';
import { apiService } from '@/plugins/apiPlugin'

export default {
  props: {
    shareDialog: {type:Boolean},
    sketch: {type: Object}
  },
  components: {
    toggleButton
  },
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    return {store, user, isAuthenticated}
  },
  data: () => ({
    email: '',
    snackbar: false,
    snackbarText: "user removed",
    permissions: [
      "Viewer",
      "Editor",
    ],
    emailRules: [
      (v) => v === "" || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || "Enter valid email",
    ],
    errorMessage: "",
    userDeleted: false,
    removedUser: null,
    loading: false,
    loadingIndex: -1,
    undoLoading: false,
    addUserLoading: false,
    users: []
  }),
  async created(){
    this.getUsers()
  },
  computed: {
    isValidEmail() {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
        return true;
      } else return false;
    },
  },
  watch: {
    "sketch": function(){
      this.getUsers()
    }
  },
  mounted() {
  },
  methods: {
    async getUsers(){
      if (this.sketch){
        let sketchId = this.sketch ? this.sketch.id : this.store.projectId
        let projectPermissions = await apiService({
          url: "/api/permission/_permission/get-permission-by-projectId",
          method: "POST",
          data: {
            projectId: sketchId,
          }
        })
        this.users = projectPermissions.data
      }
    }, 
    async changeUserRole(userPermissions, role){
      this.loading = true
      userPermissions.permissions = role
      await this.updateUserPermissions(userPermissions)
    },
    async removeUser(selectedUserPermission){
      this.loading = true
      let removalIndex = this.users.findIndex(u => u.email.toLowerCase() == selectedUserPermission.email)
      let userPermissionsCopy = JSON.parse(JSON.stringify(selectedUserPermission))
      await this.$api({
        url: "/api/permission/_permission/delete-permission",
        method: "POST",
        data: {
          permissionId: selectedUserPermission.id,
        }
      })
      this.users.splice(removalIndex, 1)
      this.removedUser = userPermissionsCopy
      this.snackbarText = "User removed"
      this.timeOutSnackbar(5000)
      this.loading = false
      this.loadingIndex = -1
    },
    async undoRemoveUser(){
      this.undoLoading = true
      let res = await this.addUserPermissions(this.removedUser)
      if (res) {
        this.users.splice(this.removedUser[1], 0, this.removedUser[0])
        this.snackbarText = 'User added'
        this.timeOutSnackbar(3000)
        this.removedUser = null
      }
      this.undoLoading = false
    },
    timeOutSnackbar(delay){
      this.snackbar = true
      const closeSnackbar = () => {
        this.snackbar = false
      }
      setTimeout(closeSnackbar, delay);
    },
    async addUser(){
      this.addUserLoading = true
      let userAlreadyExists = this.users.map(user=> user.email).includes(this.email.toLowerCase())
      if (userAlreadyExists) {
        this.errorMessage = "User already added"
      }
      else {
        try{
          let user = { email: this.email.toLowerCase() }
          this.addUserPermissions(user)
        }
        catch(error){
          console.log('error adding new user: ', error)
        }
      }
    },
    async updateUserPermissions(userPermissions){
      let updated = await this.$api({
        url: "/api/permission/_permission/update-permission",
        method: "POST",
        data: {
          permissionId: userPermissions.id,
          permission: userPermissions.permissions
        }
      })
      if (updated.data.success) {
        this.snackbarText = "User role changed"
      }
      else {
        this.snackbarText = "Failed to change user role"
      }
      this.timeOutSnackbar(3000)
      this.loading = false
      this.loadingIndex = -1
    },
    async addUserPermissions(user){
      try{
        let permission = user.permissions ? user.permissions : 'Viewer'
        let sketchId = this.sketch ? this.sketch.id : this.store.projectId
        let updated = await this.$api({
          url: "/api/permission/_permission/create-permission",
          method: "POST",
          data: {
            email: user.email,
            permission: permission,
            projectId: sketchId
          }
        })
        this.users.push(updated.data)
        this.snackbarText = 'User added'
      }
      catch(error){
        console.log('error adding new user: ', error)
        this.snackbarText = 'Unable to add user'
      } finally {
        this.email = ""
        this.timeOutSnackbar(3000)
        this.addUserLoading = false
      }
    },
    dialogClosed(){
      this.email = ""
      this.errorMessage = ""
      this.userDeleted = false
    }, 
  },
}
</script>

<style scoped>
.dialog {
  width: 90%; 
  overflow-x: auto; 
  white-space: nowrap;
}
@media (min-width: 601px) {
  .dialog {
    width: 600px; 
  }
}

.textField {
  width: 100%; 
}
</style>
