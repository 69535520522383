<template>
  <!-- Edit Mode -->
  <div v-if="store.activeTool == 'resize'">
    <div  class="d-flex justify-center bg-white elevation-1 rounded-lg pa-1" v-if="store.subTool == 'resize'"
    style="position:absolute; btnContainer">
      <toggleButton
      v-if="store.imageFile != null"
      :tooltip="'Scale Image'"
      @click="store.changeTool('resize', 'calibrate')"
      >
        <caliperIcon width="20" height="20"/>
      </toggleButton>

      <toggleButton
        v-if="store.imageFile != null"
        :tooltip="'Delete Image'"
        @click="removeImage"
        >
          <v-icon color="red" size="large">
            mdi:mdi-trash-can
          </v-icon>
      </toggleButton>
      <template v-else>
        <UploadImage />
      </template>

      <toggleButton
        :tooltip="'Complete Image Editing'"
        @click="complete()"
        >
          <v-icon color="primary">
            mdi:mdi-check-circle
          </v-icon>
      </toggleButton>

    </div>
    
    <div style="position:absolute; margin-left: -100px" v-else>

      <!-- calibration mode -->
      <v-card class="pa-2" width="380px">
        <div style="font-size:16px" class="d-flex justify-center px-2">
          Select two points of known dimension on the image.
        </div>
        <v-card-actions class="d-flex justify-space-between">
            <!-- metric options -->
            <template v-if="store.units == 'metric'">
              <div style="width:150px" class="d-flex align-center">
                <NumberInput 
                  :input="Number(store.inputDistance[0])"
                  :label="input"
                  @valueChange="store.inputDistance[0]=$event">
                </NumberInput>
                <span class="ml-1 mr-3">m</span>
              </div>
            </template>

            <!-- imperial options -->
            <div v-else class="d-flex align-center">
              <NumberInput 
                :input="Number(store.inputDistance[0])"
                :label="input"
                @valueChange="store.inputDistance[0]=$event">
              </NumberInput>
              <span class="ml-1 mr-3">ft</span>
              <NumberInput 
                :input="Number(store.inputDistance[1])"
                :label="input"
                @valueChange="store.inputDistance[1]=$event">
              </NumberInput>
              <span class="ml-1">in</span>
            </div>

            <!-- action buttons -->
            <div class="justify-self-end">
              <v-btn
                v-if="store.inputDistance[0]>0 || store.inputDistance[1]>0" 
                @click="store.tools.resize.scaleFromCalibration()" icon size="30"      
                class="ml-2" density="compact"
                >
                  <v-icon color="primary">mdi:mdi-check</v-icon>
                  <v-tooltip activator="parent" location="top">Scale</v-tooltip>
              </v-btn>
              <v-btn 
                @click="cancel()" icon size="30"      
                class="ml-2" density="compact"
                >
                  <v-icon color="red">mdi:mdi-close</v-icon>
                  <v-tooltip activator="parent" location="top">Cancel</v-tooltip>
              </v-btn>
            </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import {useStore} from '@/store/store'
  import caliperIcon from '@/assets/icons/caliperIcon.vue';
  import toggleButton from '@/components/ui/toggleButton.vue';
  import UploadImage from './UploadImage.vue';
  import NumberInput from '@/components/ui/NumberInput.vue';
  import DoneBtn from '@/components/ui/DoneBtn.vue'

  export default {
    components: {
      caliperIcon,
      toggleButton,
      UploadImage,
      NumberInput,
      DoneBtn
    },
    setup(){
    const store = useStore()
    return {store}
   },
    data: () => ({}),
    created(){
    },
    methods: {
      removeImage(){
        if (!this.store.tools.resize.selectedRaster) this.store.tools.resize.selectedRaster = this.store.imageLayer.children[0]
        if (this.store.tools.resize.selectedRaster.data.boundingRect) this.store.tools.resize.selectedRaster.data.boundingRect.remove() 
        if (this.store.tools.resize.selectedRaster) this.store.tools.resize.selectedRaster.remove();
        this.store.tools.resize.selectedRaster = null
        this.store.imageHandlesLayer.removeChildren()
        this.store.imageFile = null
      },
      cancel(){
        this.store.changeTool('resize', 'resize')
        this.store.imageDimsLayer.removeChildren()
      },
      complete(){
        this.store.changeTool('select', 'select')
        this.store.tools.resize.selectedRaster = null
        this.store.changeBackgroundColor('white')
      },
      validateInput0(e){
        if (Number(e) < 0) {
          this.store.inputDistance[0] = 0
        }
        else this.store.inputDistance[0] = e
      },
      validateInput1(e){
        if (Number(e) < 0) {
          this.store.inputDistance[1] = 0
        }
        else if (Number(e) > 12){
          this.store.inputDistance[1] = 12
        }
        else {
          this.store.inputDistance[1] = e
        }
      },
    }
  };
</script>

<style scoped>
</style>
  