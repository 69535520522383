<template>
  <div class="d-flex mr-1">

    <div class="rounded-lg py-1 d-flex bg-white elevation-1 btnContainer">
      
      <!-- Show Dimensions -->
      <toggleButton 
        :active="store.dimensionLayer.visible"
        :tooltip="'Show Dimensions'" 
        @click="store.toggleShowDimensions()">
        <v-icon style="position:absolute; margin-left:-12px; margin-top: -15px" size="15">
          mdi:mdi-eye
        </v-icon>
        <v-icon style="position:absolute; margin-left:-12px; margin-top:10px" size="18">
          mdi:mdi-format-horizontal-align-left
        </v-icon>
        <v-icon size="18" style="position:absolute; margin-left:12px; margin-top:10px">
          mdi-format-horizontal-align-right
        </v-icon>
      </toggleButton>

      <!-- Show Nodes -->
      <!-- <toggleButton 
        :active="store.nodeLayer.visible"
        :tooltip="'Show Nodes'" 
        @click="store.toggleShowNodes()">
        <v-icon style="position:absolute; margin-left:-12px; margin-top: -15px" size="15">
          mdi:mdi-eye
        </v-icon>
        <v-icon style="transform:rotate(90deg); position:absolute; 
          margin-left:0px; margin-top:10px" size="20">
          mdi:mdi-source-commit
        </v-icon>
      </toggleButton> -->

      <!-- Show Section Sizes -->
      <toggleButton 
        :active="store.memberSizesLayer.visible"
        :tooltip="'Show Section Names'" 
        @click="store.toggleMemberSizes()">
        <v-icon style="position:absolute; margin-left:-12px; margin-top: -15px" size="15">
          mdi:mdi-eye
        </v-icon>
        <v-icon size="12" style="position:absolute; margin-left:8px; margin-top: 8px">
          fa:fas fa-w
        </v-icon>
        <v-icon size="35" style="position:absolute; margin-left:6px; margin-top: 20px">
          mdi:mdi-minus
        </v-icon>
      </toggleButton>

      <!-- Show Loading -->
      <toggleButton 
        :active="store.loadLayer.visible"
        :tooltip="'Show Loading'" class="mr-1"
        @click="store.toggleShowLoading()">
        <v-icon style="position:absolute; margin-left:-12px; margin-top: -15px" size="15">
          mdi:mdi-eye
        </v-icon>
        <v-icon size="22" class="mt-1" style="position:absolute; margin-left:6px">
          mdi:mdi-arrow-down
        </v-icon>
      </toggleButton>

      <!-- Image Upload -->
      <ButtonDropdown 
        v-if="$route.name != 'MiniGame'"
        :active="false" 
        @btnClick="store.toggleShowImageUnderlay"
        :tooltip="'Show Image Underlay'"
        :slot="true"
      >
        <template v-slot:my-icon>
          <v-icon style="position:absolute; margin-left:-12px; margin-top: -15px" size="15">
            mdi:mdi-eye
          </v-icon>
          <v-icon size="18" style="position:absolute; margin-left:4px; margin-top:12px">
            fa:fas fa-image
          </v-icon>
        </template>
        <template v-slot:menu>
          <div class="pa-1" style="width:100%">
            <div class="text-grey" style="font-size:12px">
              Opacity: {{Math.round(store.imageOpacity*100)}}%
            </div>
            <div style="width:120px; padding: 0px 10px" v-if="imageExists">
              <v-slider 
                v-model="store.imageOpacity"
                color="primary"
                hide-details
                min="0" max="1"
                @update:modelValue="changeImageOpactiy"
                >
              </v-slider>
            </div>
            <div v-else class="text-grey" style="font-size:12px">No image underlayed</div>
          </div>
        </template>
        
      </ButtonDropdown>

    </div>

  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import lassoIcon from '@/assets/icons/lassoIcon.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import lineLoad from '@/assets/icons/lineLoad.vue'
import gravityIcon from '../assets/icons/gravityIcon.vue'
import SectionSizeDropDown from '@/components/ui/SectionSizeDropDown.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import SnapToGrid from '@/assets/icons/SnapToGrid.vue';
import GridOn from '@/assets/icons/GridOn.vue'
import uploadImage from "@/components/UploadImage.vue"
import ButtonDropdown from '@/components/ui/ButtonDropdown.vue';

export default {
  components: { 
    toggleButton, 
    lassoIcon, 
    fixIcon, 
    rollerX, 
    rollerY, 
    lineLoad, 
    gravityIcon, 
    SectionSizeDropDown,
    NumberInput,
    SnapToGrid,
    GridOn,
    uploadImage,
    ButtonDropdown
    },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
  }),
  created(){
  },
  computed: {
    imageExists(){
      if (this.store.imageLayer.children.length > 0) return true
      else return false
    }
  },
  mounted() {},
  methods: {
    isActive(prop){
      if (this.store[prop]) return true
      else return false
    },
    isActiveSubTool(toolName){
      if (this.store.subTool == toolName) return true
      else return false
    },
    valueChange(e, prop){
      this.store[prop] = Number(e)
    },
    changeImageOpactiy(opacity){
      let image = this.store.imageLayer.children[0]
      image.opacity = opacity
    }
  }
}
</script>
