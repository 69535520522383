<template>
  <v-text-field
    v-if="!store.onMobile || store.userPermissions=='Viewer'"
    :label="label"
    :model-value="input"
    variant="outlined"
    hide-details
    density="compact"
    type="number"
    style="width: 80px"
    :disabled="disable != 'null' ? disable : store.userPermissions == 'Viewer'"
    @update:modelValue="valueChange($event)"
    :key="key"
  ></v-text-field>
  <template v-else >
    <div style="border:1px solid black; position:relative" class="rounded-lg">
      <span v-if="label != null" style="position:absolute; z-index:10; margin-top:-12px; margin-left:8px; 
        background-color:white; height:15px; padding: 0 4px 0 4px">
        {{label}}
      </span>
      <ScrollPicker
        :selection="input"
        @scroll="valueChange($event)"
      />
    </div>
  </template>
</template>

<script>
import {useStore} from '@/store/store'
import ScrollPicker from '@/components/ui/ScrollPicker.vue'

export default{
  components: {
    ScrollPicker
  },
  props: {
    input: {
      type: Number
    },
    label: {
      type: String
    },
    disable: {
      type: Boolean
    }
  },
  setup(){
    const store = useStore()
    return {store}
  },
  data(){
    return {
      key: 0
    }
  },
  created(){
  },
  computed: {
  },
  mounted() { 
  },
  methods: {
    valueChange(e){
      if (e < 0) this.key++
      else this.$emit('valueChange', e)
    }
  }
}
</script>

<style scoped>
  .v-input:deep(.v-field__input){
    min-height: 36px !important;
    max-height: 36px !important;
  }
</style>
