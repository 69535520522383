import {useStore} from '@/store/store'
import { apiService } from '../plugins/apiPlugin';


export const save = async(user, saveType, name, minigameName, deflectionLimit) => {
  const store = useStore()

  var elements = []
  var supports = []
  var pointLoads = []
  var lineLoads = []
  let imageInfo = []
  let routePath = null

  //Get Line Info
  for (let i = 0; i < store.drawingLayer.children.length; i++){
    let line = store.drawingLayer.children[i]
    var lineProps = {
      id: line.id,
      start: {x: line.segments[0].point.x, y: line.segments[0].point.y},
      end: {x: line.segments[1].point.x, y: line.segments[1].point.y},
      startFixity: line.data.start,
      endFixity: line.data.end,
      frameProps: line.data.frameProps,
    }
    elements.push(lineProps)
  }
  //Get Support Info
  for (let i = 0; i < store.supportLayer.children.length; i++){
    var support = store.supportLayer.children[i]
    var supportProps = {
      type: support.data.type,
      location: {x: support.data.location.x, y: support.data.location.y},
      id: support.id
    }
    supports.push(supportProps)
  }
  var pointL = store.loadLayer.children.filter((load) => load.data.type == 'Point Load')
  var lineL = store.loadLayer.children.filter((load) => load.data.type == 'Line Load')
  
  //Get Load Info
  for (let i=0; i < pointL.length; i++){
    var pointLoad = pointL[i]
    var pointLoadProps = {
      type: pointLoad.data.type,
      dir: pointLoad.data.dir,
      location: {x: pointLoad.data.location.x, y: pointLoad.data.location.y},
      magnitude: pointLoad.data.magnitude,
    }
    pointLoads.push(pointLoadProps)
  }
  for (let i=0; i < lineL.length; i++){
    var lineLoad = lineL[i]
    var lineLoadProps = {
      type: lineLoad.data.type,
      dir: lineLoad.data.dir,
      elementId: lineL[i].data.elementId,
      magnitude: lineLoad.data.magnitude,
    }
    lineLoads.push(lineLoadProps)
  }
  // Save and Download an SVG
  //var fileName = name + ".svg"
  //var url = "data:image/svg+xml;utf8," + encodeURIComponent(store.canvas.project.exportSVG({asString:true, matchShapes:true}));
  // var link = document.createElement("a");
  // link.download = fileName;
  // link.href = url;
  // link.click()

  var dataStored = []
  store.loadLayer.children.forEach(child => {
    dataStored.push(child.data)
    child.data = null
  })
  
  //Hide grid and image layer before taking snapshot of canvas
  store.gridLayer.visible = false
  store.imageLayer.visible = false

  //Create Image Info
  if (store.imageLayer.children.length > 0){
    let raster = store.imageLayer.children[0]
    let imageInfoObj = {
      id:store.imageFile.id,
      scaling:{x: raster.scaling.x, y: raster.scaling.y},
      imgPosition: {x : raster.position.x , y :raster.position.y},
      rotation: raster.rotation,
    }
    imageInfo.push(imageInfoObj)
  }

  //Combine all canvas info      
  var canvasInfo = {
    createdBy: user,
    settings: {
      units: store.units,
      gridSize: store.gridSize,
      gridUnitMetric: store.gridUnitMetric,
      gridUnitImperial: store.gridUnitImperial,
      pointLoadMetric: store.pointLoadMetric,
      pointLoadImperial: store.pointLoadImperial,
      lineLoadMetric: store.lineLoadMetric,
      lineLoadImperial: store.lineLoadImperial,
      deflectionUnitMetric: store.deflectionUnitMetric,
      deflectionUnitImperial: store.deflectionUnitImperial,
      resultantForceUnitMetric: store.resultantForceUnitMetric,
      resultantForceUnitImperial: store.resultantForceUnitImperial,
      gravity: store.gravity,
      beamDegreesFromHorz: store.beamDegreesFromHorz,
      columnDegreesFromVert: store.columnDegreesFromVert,
      imageOpacity: store.imageOpacity,
      canvasCenter: {x: store.canvasCenter.x, y: store.canvasCenter.y},
      zoom: store.canvas.view.zoom
    },
    elements: elements,
    supports: supports,
    pointLoads:pointLoads,
    lineLoads: lineLoads,
    imageInfo: imageInfo,
    SVG: "data:image/svg+xml;utf8," + encodeURIComponent(store.canvas.project.exportSVG({asString:true, matchShapes:true, embedImages:false})),
  }

  store.loadLayer.children.forEach((child, index) => {
    child.data = dataStored[index]
  })
  store.gridLayer.visible = true
  store.imageLayer.visible = true

  let unableToSave = false

  //Create new firestore canvas document
  var sketchObject = {...canvasInfo, name: name}
  try { 
    if (saveType=='saveAs' || saveType=='saveNew'){
      let savedSketch = await apiService({
        url: "/api/sketch/_sketch/create-sketch",
        method: "POST",
        data: {
          sketchInfo: JSON.stringify(sketchObject),
        }
      })
      if (savedSketch.data){
        routePath = "/canvas/" + savedSketch.data.id
        store.projectId = savedSketch.data.id
        try{
          let permission = await apiService({
            url: "/api/permission/_permission/create-permission",
            method: "POST",
            data: {
              projectId: savedSketch.data.id,
              userId: user.sub,
              email: user.email.toLowerCase(),
              permission: 'Owner'
            }
          })
          store.users = [permission.data]
          store.projectName = name
        } catch(error){
          console.log('unable to create new permissions object: ', error)
        } 
      }
    } 
    //Make New Template - Only sxmoore@thorntontomasetti.com can make templates
    else if (saveType=='saveAsTemplate') {
      console.log('sketchobject', sketchObject)
      let savedTemplate = await apiService({
        url: "/api/template/_template/create-template",
        method: "POST",
        data: {
          templateInfo: JSON.stringify(sketchObject),
        }
      })
      if(savedTemplate.data){
        routePath = "/template/" + savedTemplate.data.id
        store.projectName = name
      }
    }
    //Update Template 
    else if (saveType=='saveTemplate'){
      let id = store.projectId.split('template')[1]
      let savedTemplate = await apiService({
        url: "/api/template/_template/update-template",
        method: "POST",
        data: {
          templateInfo: JSON.stringify(sketchObject),
          id: id
        }
      })
      store.projectName = name
    }
    else if (saveType=='saveMiniGame'){
      sketchObject.deflectionLimit = deflectionLimit
      sketchObject.minigameName = minigameName
      let gameTemplate = await apiService({
        url: "/api/minigame/_minigame/create-minigame",
        method: "POST",
        data: {
          templateInfo: JSON.stringify(sketchObject),
        }
      })
    }
    else if (saveType=='overwriteMiniGame'){
      sketchObject.deflectionLimit = deflectionLimit
      sketchObject.minigameName = minigameName
      let gameTemplate = await apiService({
        url: "/api/minigame/_minigame/update-minigame",
        method: "POST",
        data: {
          templateInfo: JSON.stringify(sketchObject),
        }
      })
    }
    //update existing project   
    else {
      let savedSketch = await apiService({
        url: "/api/sketch/_sketch/update-sketch",
        method: "POST",
        data: {
          sketchInfo: JSON.stringify(canvasInfo),
          sketchId: store.projectId,
        }
      })
    }  
    return routePath
  }
  catch (error) {
    console.log(error)
    saveType = false
    store.snackbar = true
    store.snackbarText = "Unable to Save, Try Again"
    unableToSave = true
  }
  
  if (store.imageFile && !unableToSave){
    try {
      if (store.imageFile.file){
        let fileId = store.imageFile.id
        let signedUrl = await apiService({
          url: "/api/sketch/_sketch/upload-file",
          method: "POST",
          data: {
            fileId: fileId,
            projectId: store.projectId,
            contentType: store.imageFile.file.type
          }
        })
        if (signedUrl.data){
          const response = await fetch(signedUrl.data[0], {
            method: 'PUT',
            body: store.imageFile.file,
            headers: {
              'Content-Type': store.imageFile.file.type,
            },
          });
          if (response.ok){
            let metadataAdded = await apiService({
              url: "/api/sketch/_sketch/add-file-metadata",
              method: "POST",
              data: {
                fileId: fileId,
                projectId: store.projectId,
              }
            })
          }
        }
      }
      else {
        if (saveType.includes('saveAs')){
          let metadataAdded = await apiService({
            url: "/api/sketch/_sketch/add-file-metadata",
            method: "POST",
            data: {
              fileId: store.imageFile.id,
              projectId: store.projectId,
            }
          })
        }
      }
    }
    catch(error){
      console.log("error uploading image:", error)
      throw error
    }
  }
}


