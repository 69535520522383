import paper from 'paper'
import * as styles from '../styles/paperStyles'
import {useStore} from '@/store/store'
import * as symbols from '../constants/Symbols'
import * as utils from "@/utilities/Utils"

const canvasColor = "#ffffff"; // Background color for the canvas

export class Grid {
  store = useStore()

  constructor({ stdCanvasH, stdCanvasW, minCellSize, context, canvas }) {

    // Constructor to initialize grid parameters
    this.width = stdCanvasW;
    this.height = stdCanvasH;
    this.axisPosX = stdCanvasW / 2; // Position of the X-axis
    this.axisPosY = stdCanvasH / 2; // Position of the Y-axis
    this.cellSize = minCellSize; // Size of each grid cell

    this.lastAxisPosX = stdCanvasW / 2; // Last X-axis position
    this.lastAxisPosY = stdCanvasH / 2; // Last Y-axis position

    this.minCellSize = minCellSize; // Minimum size for grid cells
    this.canvas = context.canvas; // HTML canvas element
    this.context = context; // Canvas rendering context

    this.zoom = 1
    this.zoomMin = 0
    this.zoomMax = 0
    this.opacityMin = 0
    this.opacityMax = 0

    this.gen = 10
    this.ratio = 1

    this.gridOn = true
    this.offsetX = 0
    this.offsetY = 0

    this.minDivY = 0
    this.minDivX = 0
    this.maxDivX = 0
    this.maxDivY = 0

    this.lastCanvasZoom = 1
    this.canvasZoom = 1

    this.realGridSpacing = 0
  }

  draw() {
    this.context.clearRect(0, 0, this.width, this.height);
    
    // Method to draw the grid on the canvas
    this.context.fillStyle = canvasColor; // Set canvas background color
    this.context.fillRect(0, 0, this.width, this.height); // Fill canvas with background color

    // Calculate the divisions of the grid based on current axis position and cell size
    this.minDivY = -Math.floor(this.axisPosY / this.cellSize);
    this.minDivX = -Math.floor(this.axisPosX / this.cellSize);
    this.maxDivY = Math.ceil((this.height - this.axisPosY) / this.cellSize);
    this.maxDivX = Math.ceil((this.width - this.axisPosX) / this.cellSize);

    // Calculate equivalent grid spacing
    this.realGridSpacing = 1/this.ratio

    // Draw vertical grid lines and values
    for (let lineV = this.minDivY; lineV <= this.maxDivY; lineV++) {
      this.setGridLines("v", lineV); // Draw vertical grid lines
      this.setGridValues("v", lineV); // Draw vertical grid values
    }

    // Draw horizontal grid lines and values
    for (let lineH = this.minDivX; lineH <= this.maxDivX; lineH++) {
      this.setGridLines("h", lineH); // Draw horizontal grid lines
      this.setGridValues("h", lineH); // Draw horizontal grid values
    }

    this.setAxis(); // Draw the X and Y axes
  }

  setLineStyle(line) {
    if (line == "axis") {
      this.context.lineWidth = 1.0;
      this.context.strokeStyle = "rgba(210, 210, 210)";
    } else {
      this.context.lineWidth = 0.5;
      this.context.strokeStyle = "rgba(230, 230, 230)"

      let interval = this.getLineValueInterval()

      if (line % interval == 0){
        this.context.lineWidth = 1
        this.context.strokeStyle = "rgba(210, 210, 210)"
      }
    }
  }

  setGridLines(direction, line) {
    // Method to draw grid lines
    this.setLineStyle(line); // Set line style

    // Draw vertical grid lines
    if (direction === "v") {
      const y = this.axisPosY + this.cellSize * line;
      this.context.beginPath();
      this.context.moveTo(0, y);
      this.context.lineTo(this.width, y);
      if (line == this.minDivY) this.offsetY = y
    } else if (direction === "h") {
      // Draw horizontal grid lines
      const x = this.axisPosX + this.cellSize * line;
      this.context.beginPath();
      this.context.moveTo(x, 0);
      this.context.lineTo(x, this.height);
      if (line == this.minDivX) this.offsetX = x
    }

    this.context.stroke(); // Draw the lines
    this.context.closePath(); // Close the path
  }

  setGridValues(direction, line) {
    // Method to draw grid values
    // Styler
    this.context.font = "12px Arial";
    this.context.fillStyle = "#aaaaaa";

    // Tracer
    this.context.beginPath();
    let interval = this.getLineValueInterval()

    if (direction === "v" && line % interval === 0 && line !== 0) {
      let value = String(utils.formatNum((-line / this.ratio), 2));
      let valueOffset = this.context.measureText(value).width + 15;
      this.context.textAlign = "right";

      if (this.axisPosX >= this.width) {
        this.context.fillText(
          value,
          this.width - 5,
          this.axisPosY - line * -this.cellSize + 15
        );
      } else if (this.axisPosX <= valueOffset + 15) {
        this.context.fillText(
          value,
          valueOffset,
          this.axisPosY - line * -this.cellSize + 15
        );
      } else {
        this.context.fillText(
          value,
          this.axisPosX - 5,
          this.axisPosY + line * this.cellSize + 15
        );
      }
    } else if (direction === "h" && line % interval === 0 && line !== 0) {
      let value = String(utils.formatNum((line / this.ratio), 2));
      this.context.textAlign = "center";

      if (this.axisPosY >= this.height - this.canvas.offsetTop) {
        this.context.fillText(
          value,
          this.axisPosX + line * this.cellSize -15,
          this.height - 20
        );
      } else if (this.axisPosY <= 50) {
        this.context.fillText(
          value, 
          this.axisPosX + line * this.cellSize - 15, 
          70);
      } else {
        this.context.fillText(
          value,
          this.axisPosX + line * this.cellSize -15,
          this.axisPosY + 20
        );
      }
    }

    this.context.closePath();
  }

  getLineValueInterval(){
    //Set interval at which each line value should show
    let interval = this.gen/this.ratio
    if (this.ratio > 1) {
      if (this.ratio == 2) interval = this.ratio * 2
      else interval = this.ratio
    }
    return interval
  }

  setAxis() {
    // Method to draw X and Y axes
    this.setLineStyle("axis"); // Set line style for axes

    this.context.beginPath();

    // Draw horizontal axis line
    this.context.moveTo(0, this.axisPosY);
    this.context.lineTo(this.width, this.axisPosY);
    // Draw vertical axis line
    this.context.moveTo(this.axisPosX, 0);
    this.context.lineTo(this.axisPosX, this.height);

    this.context.stroke(); // Draw the lines
    this.context.closePath(); // Close the path

    // Draw label for origin point (0,0)
    this.context.font = "12px arial";
    this.context.fillStyle = "#aaaaaa"; // Set color for the label
    this.context.textAlign = "center";
    this.context.beginPath();
    this.context.fillText("0", this.axisPosX - 15, this.axisPosY + 20); // Draw label at the origin
    this.context.closePath();
  }

  setPan(mouseStartX, mouseStartY, mousePosX, mousePosY) {
    // Method to handle panning of the grid
    // Calculate the difference in mouse position before and after panning
    const beforeX = mouseStartX / this.cellSize;
    const beforeY = mouseStartY / this.cellSize;

    const afterX = mousePosX / this.cellSize;
    const afterY = mousePosY / this.cellSize;

    const deltaX = afterX - beforeX;
    const deltaY = afterY - beforeY;

    this.axisPosX = this.lastAxisPosX;
    this.axisPosY = this.lastAxisPosY;

    // Update axis position based on panning
    this.axisPosX += deltaX * this.cellSize;
    this.axisPosY += deltaY * this.cellSize;

    this.draw(); // Redraw the grid
  }

  setZoom(zoom, mousePosX, mousePosY) {
    let maxCellSize = 40
    let minCellSize = 20

    this.zoom = zoom;
    
    // Limit the precision of the ratio variable
    this.ratio = parseFloat(this.ratio.toExponential(8));

    //Set Zoom In Limit
    if (this.zoom > 0 && this.ratio >= 10 && this.cellSize >= maxCellSize-1){
      return 
    }
    //Set Zoom Out Limit
    if (this.zoom < 0 && this.ratio <= 0.01 && this.cellSize <= minCellSize+1){
      return 
    }

    // Calculate the mouse position relative to the grid before zooming
    let beforeX = (mousePosX - this.axisPosX) / this.cellSize / this.ratio;
    let beforeY = (mousePosY - this.axisPosY) / this.cellSize / this.ratio;

    // Convert mouse position to canvas position
    let mousePosition = new paper.Point(mousePosX, mousePosY)
    var canvasPosition = this.store.canvas.view.viewToProject(mousePosition);

    // Calculate the position of the mouse relative to the canvas center
    var canvasCenter = this.store.canvas.view.center;
    var posRelativeToCenter = canvasPosition.subtract(canvasCenter);

    // Increase or decrease the cell size
    this.cellSize = this.cellSize + this.zoom * 2;

    //Adjust grid factors
    let zoomingIn = this.zoom > 1
    this.setZoomGridAdjustments(zoomingIn)

    // Calculate the mouse position relative to the grid after zooming
    let afterX = (mousePosX - this.axisPosX) / this.cellSize / this.ratio;
    let afterY = (mousePosY - this.axisPosY) / this.cellSize / this.ratio;

    // Calculate the change in mouse position due to zooming
    const deltaX = afterX - beforeX;
    const deltaY = afterY - beforeY;

    // Adjust the coordinate system's center to compensate for the shift
    this.axisPosX += deltaX * this.cellSize * this.ratio;
    this.axisPosY += deltaY * this.cellSize * this.ratio;

    // Update canvas zoom level and adjust canvas view center
    this.canvasZoom = (this.store.grid.cellSize/30)*this.store.grid.ratio
    let beta = this.lastCanvasZoom/this.canvasZoom
    var offset = canvasPosition.subtract(posRelativeToCenter.multiply(beta)).subtract(canvasCenter);	
    this.store.canvas.view.zoom = this.canvasZoom
    this.store.canvas.view.center= this.store.canvas.view.center.add(offset);
    this.lastCanvasZoom = this.canvasZoom

    this.lastAxisPosX = this.axisPosX;
    this.lastAxisPosY = this.axisPosY;
    
    //Redraw the grid
    this.draw();

    // Scale all elements on the canvas according to the new zoom level
    this.scaleAllCanvasElements(beta)
  }
  scaleAllCanvasElements(beta){
    //Update existing element styles to match zoom
    this.store.memberSizesLayer.children.forEach(memberSize => {
      memberSize.scale(beta, beta)
    })
    this.store.drawingLayer.children.forEach(member => {
      member.style.strokeWidth *= beta
    })
    this.store.releaseLayer.children.forEach(release => {
      release.style.strokeWidth *= beta
      release.scale(beta)
    })
    this.scaleNodes(beta)
    this.scaleSupports(beta)
    this.scaleLoads(beta)
    this.scaleDimensions(beta)
    this.scaleFrames(beta)
    this.scaleReactions(beta)
    this.scaleResults(beta)
    this.scaleImageUnderlay(beta)
  }
  scaleNodes(beta){
    this.store.nodeLayer.children.forEach(node => {
      node.style.strokeWidth *= beta
      node.scale(beta)
    })
  }
  scaleFrames(beta){
    this.store.drawingLayer.children.forEach(line => {
      if (line.data.frameProp && line.data.frameProps.orientation == 90){
        line.dashArray = [line.dashArray[0]*beta, line.dashArray[1]*beta]
      }
    })
  }
  scaleDimensions(beta){
    this.store.dimensionLayer.children.forEach(dimGroup => {
      let vector1 = dimGroup.data.normal.multiply(dimGroup.children[0].length*beta)
      dimGroup.children[0].segments[1].point = dimGroup.children[0].segments[0].point.add(vector1)
      dimGroup.children[1].segments[1].point = dimGroup.children[1].segments[0].point.add(vector1)
      let midPoint1 = dimGroup.children[0].getPointAt(dimGroup.children[0].length/2)
      let midPoint2 = dimGroup.children[1].getPointAt(dimGroup.children[1].length/2)
      dimGroup.children[2].segments[0].point = midPoint1
      dimGroup.children[2].segments[1].point = midPoint2
      dimGroup.children[3].scale(beta, beta)
      dimGroup.children[3].position = dimGroup.children[2].getPointAt(dimGroup.children[2].length/2)
      dimGroup.children[0].strokeWidth *=beta
      dimGroup.children[1].strokeWidth *=beta
      dimGroup.children[2].strokeWidth *=beta
    })
  }
  scaleSupports(scaleFactor){
    this.store.supportLayer.children.forEach(support => {
      let type
      let supportPositionBeforeY = support.bounds.top
      if (support.data && support.data.type){
        type = support.data.type
      } else {
        type = this.store.tools.supports.subTool
      }
      if (type == 'pin' || type.includes('roller')){
        support.scale(scaleFactor)
        support.strokeWidth *= scaleFactor
        if (support.data.location){
          support.position.y = support.data.location.y + support.bounds.height/2
        }
        else {
          support.position.y = supportPositionBeforeY + support.bounds.height/2
        }
      } else {
        support.scale(scaleFactor, support.bounds.topCenter)
        support.children.forEach(child => {
          child.strokeWidth *= scaleFactor
        })
      }
    })
  }
  async scaleLoads(scaleFactor){
    const loadScalingPromises = this.store.loadLayer.children.map(async (load) => {
      if (load.data.type.includes('Line')){
        let loadDir = load.data.dir
        let magnitude = load.data.magnitude
        let assocLine = utils.findLineById(load.data.elementId)
        let loadId = load._id
        let notSelectable = load.data.notSelectable
        let scaledLineLoad
        if (load.data.type.includes('Mass')){
          scaledLineLoad = await this.store.tools.loads.scaleLineLoad(assocLine, magnitude, loadDir, true)
        }
        else {
          scaledLineLoad = await this.store.tools.loads.scaleLineLoad(assocLine, magnitude, loadDir)
        }
        load.remove()
        scaledLineLoad._id = loadId
        if (notSelectable) scaledLineLoad.data.notSelectable = true
        let text = utils.findAssociatedText(scaledLineLoad)
        if (text){
          text.scale(scaleFactor)
          text.position = this.store.tools.loads.getLoadTextLocation(load)
        }
      }
      if (load.data.type == 'Point Load'){
        let index = load.data.dir == "Up" ? 0 : 1
        let indexOpposite = load.data.dir == "Up" ? 1 : 0
        let scaleLocation = this.getLoadScalePoint(load)
        load.scale(scaleFactor, scaleLocation)
        load.children.forEach(child => child.strokeWidth *= scaleFactor)
        let text = utils.findAssociatedText(load)
        if (text){
          let arrowStart = load.children[0].segments[index].point
          let arrowEnd = load.children[0].segments[indexOpposite].point
          let vector = arrowStart.subtract(arrowEnd)
          text.position = arrowEnd.subtract(vector.normalize().multiply(10/this.store.grid.canvasZoom))
          text.scale(scaleFactor)
        }
      }
    });
    await Promise.all(loadScalingPromises)
  }
  getLoadScalePoint(load){
    let scaleLocation 
    if (load.data.dir){
      if (load.data.dir.includes("Up") || load.data.dir.includes("Down")){
        scaleLocation = load.bounds.bottomCenter
      }
      else if (load.data.dir.includes("Left")){
        scaleLocation = load.bounds.leftCenter
      }
      else if (load.data.dir.includes("Right")){
        scaleLocation = load.bounds.rightCenter
      }
    }
    return scaleLocation
  }
  scaleReactions(beta){
    this.store.reactionLayer.children.forEach(reactionGroup => {
      let scalePoint = reactionGroup.bounds.topCenter
      reactionGroup.scale(beta, scalePoint)
      reactionGroup.children.forEach(child => {
        if (child.className != "PointText"){
          child.strokeWidth *= beta
        }
      })
      var correspondingSupport = this.store.supportLayer.children.filter(support => support.id == reactionGroup.data.supportId)
      if (correspondingSupport.length > 0){
        let supportBottom = correspondingSupport[0].bounds.bottomCenter
        reactionGroup.position = {x: supportBottom.x, y: supportBottom.y+reactionGroup.bounds.height/2}
      }
    })
  }
  scaleResults(scaleFactor){
    if (!this.store.analyze.analyzeMode) return 
    for (const layer of Object.keys(this.store.analysisLayers)){
      if (this.store.analysisLayers[layer].children.length > 0){
        this.store.analysisLayers[layer].children.forEach(child => {
          child.strokeWidth *= scaleFactor
        })
      }
    }
    for (const layer of Object.keys(this.store.analysisValuesLayers)){
      if (this.store.analysisValuesLayers[layer].children.length > 0){
        this.store.analysisValuesLayers[layer].children.forEach(text => {
          text.scale(scaleFactor)
          if (layer == 'utilization'){
            let normal = text.data.normal
            let newPosition = text.data.anchorPoint.add({x: -normal.x * 15/this.store.grid.canvasZoom, y: -normal.y * 15/this.store.grid.canvasZoom})
            text.position = newPosition
          }
        })
      }
    }
  }
  scaleImageUnderlay(beta){
    this.store.imageHandlesLayer.children.forEach(handle => {
      handle.scale(beta)
      handle.strokeWidth *= beta
    })
    this.store.imageBoundingRectLayer.children.forEach(boundingRect => {
      boundingRect.strokeWidth *= beta
    })
  }
  pinchZoom(e){
    this.cellSize = (this.store.canvas.view.zoom*30)/this.ratio
    this.axisPosX = this.store.canvas.view.projectToView(this.store.canvasCenter).x
    this.axisPosY = this.store.canvas.view.projectToView(this.store.canvasCenter).y
    this.lastAxisPosX = this.axisPosX
    this.lastAxisPosY = this.axisPosY
    
    let zoomingIn = e.additionalEvent == 'pinchout'
    this.setZoomGridAdjustments(zoomingIn)
    
    this.draw()
    let beta = this.lastCanvasZoom/this.store.canvas.view.zoom
    this.scaleAllCanvasElements(beta)
    this.lastCanvasZoom = this.store.canvas.view.zoom
    this.canvasZoom = this.store.canvas.view.zoom
  }
  setZoomGridAdjustments(zoomingIn){
    let maxCellSize = 40
    let minCellSize = 20

    if (zoomingIn) { 
      if (this.cellSize >= maxCellSize){
        if (this.ratio < 1){ // Grid size bigger than 1
          if (this.ratio == 0.02 || this.ratio == 0.2){ //Grid Transition from 50 --> 20 and 5 --> 1
            this.gen *= 2.5
            this.ratio *= 2.5
            this.cellSize /= 2.5
          }
          else { // double grid size
            this.gen *= 2
            this.ratio *= 2
            this.cellSize /= 2
          }
        }
        else { // Grid size less than 1
          if (this.store.units == 'imperial') {
            if (this.ratio == 4){ //Grid size transition 3 in to 1 in
              this.gen *= 3
              this.ratio *= 3
              this.cellSize = this.cellSize/3
            } else { // double grid size
              this.gen *= 2
              this.ratio *= 2
              this.cellSize /= 2
            }
          }
          else {
            if (this.ratio == 2){
              this.gen *= 2.5
              this.ratio *= 2.5
              this.cellSize /= 2.5
            } else {
              this.gen *= 2
              this.ratio *= 2
              this.cellSize /= 2
            }
          }
        }
      }
    }
    //Zooming out 
    else { 
      if (this.cellSize <= minCellSize){
        if (this.ratio <= 1){ // Grid size bigger than 1
          if (this.ratio == 0.05 || this.ratio == 0.5){ //Grid Transition from 50 --> 20 and 5 --> 1
            this.gen /= 2.5
            this.ratio /= 2.5
            this.cellSize *= 2.5
          }else {
            this.gen /= 2
            this.ratio /= 2
            this.cellSize *= 2
          }
        }
        else { // Grid size less than 1
          if (this.store.units == 'imperial') {
            if (this.ratio == 12){ //Grid size transition 1 in to 3 in
              this.gen /= 3
              this.ratio /= 3
              this.cellSize *=3
            } else {
              this.gen /= 2
              this.ratio /= 2
              this.cellSize *=2
            }
          }
          else {
            if (this.ratio == 5){
              this.gen /= 2.5
              this.ratio /= 2.5
              this.cellSize *= 2.5
            } else {
              this.gen /= 2
              this.ratio /= 2
              this.cellSize *= 2
            }
          }
        }
      }
    }
  }
  getSnappingPoint(event){
    let canvasMousePos = new paper.Point(event.x, event.y)
    let viewMousePos = this.store.canvas.view.projectToView(canvasMousePos)
    let gridPoint = new paper.Point({
      x: Math.round((viewMousePos.x - this.offsetX)/this.cellSize)*this.cellSize + this.offsetX,
      y: Math.round((viewMousePos.y - this.offsetY)/this.cellSize)*this.cellSize + this.offsetY
    })
    let gridPointToProject = this.store.canvas.view.viewToProject(gridPoint)

    return gridPointToProject
  }
}
