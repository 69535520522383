// Plugins
import 'vuetify/styles'
import { createVuetify } from 'vuetify' 
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { installApiPlugin } from "@/plugins/apiPlugin.js";
import ScrollPicker from 'vue3-scroll-picker';
import router from '@/router'
import { GesturePlugin } from '@vueuse/gesture';
import { auth0Service } from '@/auth';
import { createPinia } from 'pinia'
import vuetifyConfig from '@/plugins/vuetify' 

export function registerPlugins (app) {
  const vuetify = createVuetify({
    components,
    directives,
    ...vuetifyConfig, 
  })

  const pinia = createPinia()
  
  app
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(ScrollPicker)
  .use(GesturePlugin)
  .use(auth0Service)
  installApiPlugin(app)
}