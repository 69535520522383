import { auth0Service } from '@/auth';

// The core functionality that can be used directly
async function apiService(config) {
  let token;
  try {
    token = await auth0Service.getAccessTokenSilently();
    let options;
    if (config.data) {
      options = {
        method: config.method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(config.data)
      };
    } else {
      options = {
        method: config.method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
    }
    const response = await fetch(config.url, options);
    if (!response.ok) {
      if (!response.headers.get("content-type")?.includes("application/json")){
        throw new Error(`${response.status}:  ${response.url.toString()}`)
      }
      else {
        let responseJson = await response.json()
        throw Error(responseJson.message);
      }
    }else{
      return await response.json();
    }
  } catch (error) {
    console.log(error)
    throw error; 
  }
}

// The plugin installation function
export function installApiPlugin(app) {
  app.config.globalProperties.$api = apiService;
}

// Export the core functionality for direct use
export { apiService };