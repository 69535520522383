import * as styles from "@/styles/paperStyles.js"
import { useStore } from "@/store/store.js"
import * as utils from "@/utilities/Utils.js"
import paper from "paper"

export function createSymbols(){
  node = createNode()
  pinSupport = createPinSupport(false)
  fixSupport = createFixSupport(false)
  rollerXSupport = createRollerXSupport(false)
  rollerYSupport = createRollerYSupport(false)
  pinSupportDisabled = createPinSupport(true)
  fixSupportDisabled = createFixSupport(true)
  rollerXSupportDisabled = createRollerXSupport(true)
  rollerYSupportDisabled = createRollerYSupport(true)
}
function createNode() {
  const node = new paper.Path.Rectangle(new paper.Point(0, 0), 8)
  node.style = styles.node
  const nodeSymbol = new paper.SymbolDefinition(node)
  return nodeSymbol
}
export function scaleNode(scaleFactor){
  node.item.scale(scaleFactor, scaleFactor)
  node.item.strokeWidth = node.item.strokeWidth*scaleFactor
}

function createPinSupport(disabled){
  var triangle = new paper.Path.RegularPolygon({x:0, y:0}, 3, 15)
  triangle.strokeWidth = 1.5
  if (disabled) triangle.strokeColor = styles.disabledColor
  else triangle.style = styles.supportIcon
  const pinSymbol = new paper.SymbolDefinition(triangle)
  return pinSymbol
}
function createFixSupport(disabled){
  var groundLine = new paper.Path([0,0], [25, 0])
  groundLine.strokeWidth = 1.5
  var line1 = new paper.Path([7, 0], [0, 10])
  line1.strokeWidth = 0.5
  var line2 = new paper.Path([14, 0], [7, 10])
  line2.strokeWidth = 0.5
  var line3 = new paper.Path([21, 0], [14, 10])
  line3.strokeWidth = 0.5
  var fix = new paper.Group(groundLine, line1, line2, line3)
  if (disabled) fix.strokeColor = styles.disabledColor
  else fix.style = styles.supportIcon
  const fixSymbol = new paper.SymbolDefinition(fix)
  return fixSymbol
}
function createRollerXSupport(disabled){
  var circle = new paper.Path.Circle({
    center: {x: 0, y:0},
    radius: 12
  })
  circle.strokeWidth = 1.5
  var line = new paper.Path([-14,12], [14,12])
  line.strokeWidth = 1.5
  let roller =  new paper.Group(circle, line)
  if (disabled) roller.strokeColor = styles.disabledColor
  else roller.style = styles.supportIcon
  const rollerXSymbol = new paper.SymbolDefinition(roller)
  return rollerXSymbol
}
function createRollerYSupport(disabled){
  var circle = new paper.Path.Circle({
    center: {x: 0, y:0},
    radius: 12
  })
  circle.strokeWidth = 1.5
  var line = new paper.Path([12,-14], [12,14])
  line.strokeWidth = 1.5
  let roller = new paper.Group(circle, line)
  if (disabled) roller.strokeColor = styles.disabledColor
  else roller.style = styles.supportIcon
  const rollerYSymbol = new paper.SymbolDefinition(roller)
  return rollerYSymbol
}
export function scalePinSupport(scaleFactor){
  pinSupport.item.scale(scaleFactor, pinSupport.item.bounds.topCenter)
  pinSupport.item.strokeWidth *= scaleFactor
  pinSupportDisabled.item.scale(scaleFactor, pinSupport.item.bounds.topCenter)
  pinSupportDisabled.item.strokeWidth *= scaleFactor
}
export function scaleFixSupport(scaleFactor){
  fixSupport.item.scale(scaleFactor, fixSupport.item.bounds.topCenter)
  fixSupport.item.children.forEach(child => {
    child.strokeWidth *= scaleFactor
  })
  fixSupportDisabled.item.scale(scaleFactor, fixSupportDisabled.item.bounds.topCenter)
  fixSupportDisabled.item.children.forEach(child => {
    child.strokeWidth *= scaleFactor
  })
}
export function scaleRollerSupport(scaleFactor){
  rollerXSupport.item.scale(scaleFactor, rollerXSupport.item.bounds.topCenter)
  rollerXSupport.item.strokeWidth *= scaleFactor
  rollerXSupportDisabled.item.scale(scaleFactor, rollerXSupportDisabled.item.bounds.topCenter)
  rollerXSupportDisabled.item.strokeWidth *= scaleFactor
  rollerYSupport.item.scale(scaleFactor, rollerYSupport.item.bounds.topCenter)
  rollerYSupport.item.strokeWidth *= scaleFactor
  rollerYSupportDisabled.item.scale(scaleFactor, rollerYSupportDisabled.item.bounds.topCenter)
  rollerYSupportDisabled.item.strokeWidth *= scaleFactor
}

export var node = null
export var pinSupportDisabled = null
export var fixSupportDisabled = null
export var rollerXSupportDisabled = null
export var rollerYSupportDisabled = null
export var pinSupport = null
export var fixSupport = null
export var rollerXSupport = null
export var rollerYSupport = null