<template>
  <v-footer elevation=2 density="compact" height="30" app>
    <v-btn flat variant="plain" style="font-size:small" class="pl-2 text-none" href="http://core.thorntontomasetti.com" target="_blank">
      &#169; Thornton Tomasetti | CORE studio {{ new Date().getFullYear() }}
    </v-btn>
    <v-spacer></v-spacer>
    <span style="font-size:small" class="pr-2">      
      <a
        class="pl-2"
        style="text-decoration:none;"
        href="https://coresso.thorntontomasetti.com/eula"
        target="_blank"
      >
        <span color="black">License</span>
      </a>
    </span>
  </v-footer>
</template>

<script>
export default {
  mounted() {},
};
</script>
<style scoped>
.footer {
  align-items: center;
}
</style>
