import { useStore } from "@/store/store";
import * as styles from "../styles/paperStyles";
import paper from "paper";
import * as modifiers from '@/utilities/Modifiers.js'
import * as symbols from "@/constants/Symbols.js"

export class Resize {
  store = useStore();
  constructor() {
    this.subTool = "resize";
    this.hitOptions = {
      segments: true,
      stroke: true,
      fill: false,
      tolerance: 15,
    }
    this.handleHitOptions = {
      segments: true,
      stroke: true,
      fill: true,
      tolerance: 5,
    }
    this.selectedRaster = null;
    this.selectedHandle = null;
    this.calibrationPoints = []
    this.dimStart = null
    this.dimEnd = null
    this.dimLine = null
    this.inputText = null
    this.imageDimsLayerHitResult = null
    this.lastRotation = 0
    this.dimEndPointHit = false
    this.hammertime = null
  }
  activate(subTool) {
    // console.log("activate resize");
    this.subTool = subTool;
    if (this.subTool == "resize") {
      this.store.canvas.tool.onMouseDown = this.onMouseDown;
      this.store.canvas.tool.onMouseDrag = this.onMouseDrag;
      this.store.canvas.tool.onMouseMove = this.onMouseMove;
      if (this.store.onMobile) this.store.canvas.tool.onMouseUp = this.onMouseUp;
    }
    if (this.subTool == 'calibrate'){
      this.store.canvas.tool.onMouseDown = this.onCalibrateMouseDown
      this.store.canvas.tool.onMouseMove = this.onCalibrateMouseMove
      this.store.canvas.tool.onMouseDrag = this.onCalibrationMouseDrag
      this.store.canvas.tool.onMouseUp = this.onCalibrationMouseUp
    }
    
    //Active two finger rotation when on mobile
    if (this.store.onMobile){
      let startRotation
      this.store.pinchHammer.get('rotate').set({enable: true})

      this.store.pinchHammer.on('rotatestart', (event) => {
        if (this.selectedRaster){
          this.store.pinchHammer.get('pinch').set({ enable: false });
          startRotation = event.rotation
        }
      })
      this.store.pinchHammer.on('rotate', (event) => {
        if (this.selectedRaster){
          this.selectedRaster.rotate(-(startRotation-event.rotation))
          this.selectedRaster.data.boundingRect.rotate(-(startRotation-event.rotation))
          startRotation = event.rotation
          this.adjustHandles(this.selectedRaster);
        }
      });
      this.store.pinchHammer.on('rotateend', (event) => {
        this.store.pinchHammer.get('pinch').set({ enable: true });
      })
    }
  }
  deactivate(tool){
    this.store.canvas.tool.onMouseDown = null
    this.store.canvas.tool.onMouseDrag = null
    this.store.canvas.tool.onMouseMove = null
    this.store.canvas.tool.onMouseUp = null
    this.selectedHandle = null
    this.resetCalibrationSettings()
    if (tool != 'resize'){
      this.selectedRaster = null
      this.store.imageHandlesLayer.removeChildren()
      this.store.imageBoundingRectLayer.visible = false
    }
    if (this.store.pinchHammer){
      this.store.pinchHammer.get('rotate').set({enable:false})
      this.store.pinchHammer.get('pinch').set({enable:true})
    }
  }
  onCalibrateMouseDown = (event) => {
    //Add First point
    if (this.calibrationPoints.length < 1){
      this.calibrationPoints.push(event.point)
      this.dimStart = this.createDimEndPoint(event)
      this.dimStart.data = {name: "start"}

      this.dimLine = new paper.Path(styles.line())
      this.dimLine.add({x: event.point.x, y: event.point.y - 15/this.store.grid.canvasZoom})
      this.dimLine.add({x: event.point.x, y: event.point.y - 15/this.store.grid.canvasZoom})
   
      this.dimEnd = this.createDimEndPoint(event)
      this.dimEnd.data = {name: "end"}

      this.store.imageDimsLayer.addChild(this.dimStart)
      this.store.imageDimsLayer.addChild(this.dimEnd)
      this.store.imageDimsLayer.addChild(this.dimLine)
    }
    //Add Second Point
    else if(this.calibrationPoints.length < 2) {
      if (this.store.onMobile){
        this.onCalibrateMouseMove(event)
      }
      this.calibrationPoints.push(event.point)
      this.createInputText(this.dimLine)
    }
    else{
      const hitOptions = {
        segments: true,
        stroke: true,
        fill: true,
        tolerance: 5,
      }

      let imageDimsLayerHitResult = this.store.imageDimsLayer.hitTest(event.point, hitOptions);
      if(imageDimsLayerHitResult){
        if(imageDimsLayerHitResult.item.className !== "Group"){
          this.imageDimsLayerHitResult = imageDimsLayerHitResult.item.parent;
        }
        else this.imageDimsLayerHitResult = imageDimsLayerHitResult.item
        if(this.imageDimsLayerHitResult.data.name === "start" || this.imageDimsLayerHitResult.data.name === "end"){
          this.dimEndPointHit = true;
          this.inputText.remove()
        }else{
          this.dimEndPointHit = false;
        }
      }
    }
  }
  onCalibrateMouseMove = (event) => {
    if (this.calibrationPoints.length == 1){
      this.dimLine.lastSegment.point = {x: event.point.x, y: event.point.y - 15/this.store.grid.canvasZoom}
      this.dimEnd.position = {x: event.point.x, y: event.point.y - 5/this.store.grid.canvasZoom}
    }
  }
  onCalibrationMouseDrag = (event) => {
    if(this.dimEndPointHit){
      this.imageDimsLayerHitResult.position = event.point;
      this.dimLine.segments[this.imageDimsLayerHitResult.index].point = {x: event.point.x, y: event.point.y - 10/this.store.grid.canvasZoom};
    }
  }
  onCalibrationMouseUp = (event) => {
    if(this.calibrationPoints.length == 2){
      if (this.inputText) this.inputText.remove()
      this.createInputText(this.dimLine)
    }
  }
  createInputText(dimLine){
    let vector = dimLine.segments[1].point.subtract(dimLine.segments[0].point)
    let dimLineMidPoint = dimLine.segments[0].point.add(vector.divide(2))
    this.inputText = new paper.PointText(dimLineMidPoint)
    this.inputText.content = 'input'
    this.inputText.style = styles.dimText
    let rotation = modifiers.textRotation(dimLine)
    this.inputText.rotate(rotation, dimLineMidPoint)
  }
  scaleFromCalibration(){
    if (this.calibrationPoints.length < 2) return
    else {
      if (this.store.getInputDistance == 0) return
      var vector = this.calibrationPoints[0].subtract(this.calibrationPoints[1])

      let gridCellSizeProjected = this.store.grid.cellSize/this.store.grid.canvasZoom
      var distance = (vector.length/gridCellSizeProjected)*this.store.grid.realGridSpacing
      console.log(this.calibrationPoints[0], this.calibrationPoints[1], vector, distance)
      var scaleFactor = this.store.getInputDistance/distance
      if (!this.selectedRaster) {
        this.makeSelectedRaster(this.store.imageLayer.children[0])
      }
      this.selectedRaster.scale(scaleFactor, this.calibrationPoints[0])
      this.selectedRaster.data.boundingRect.scale(scaleFactor, this.calibrationPoints[0])
      this.adjustHandles(this.selectedRaster)
      this.store.changeTool('resize', 'resize')
      this.resetCalibrationSettings()
    }
  }
  makeSelectedRaster(item){
    this.selectedRaster = item
    if (!this.selectedRaster.data.boundingRect) {
      let boundingRect = new paper.Path.Rectangle(this.selectedRaster.bounds);
      this.store.imageBoundingRectLayer.addChild(boundingRect)
      boundingRect.strokeColor = styles.selectedImageColor;
      boundingRect.strokeWidth = 1;
      this.selectedRaster.data.boundingRect = boundingRect;
    }
    this.store.imageBoundingRectLayer.visible = true
    this.selectedRaster.data.boundingRect.visible = true
  }
  resetCalibrationSettings(){
    this.store.imageDimsLayer.removeChildren()
    this.calibrationPoints = []
    if (this.inputText) this.inputText.remove()
    this.inputText = null
    this.store.calibrationMode = false
  }  
  createDimEndPoint(event){
    let dimHorz = new paper.Path(styles.line())
    dimHorz.add({x: event.point.x-10/this.store.grid.canvasZoom, y: event.point.y})
    dimHorz.add({x: event.point.x+10/this.store.grid.canvasZoom, y: event.point.y})
    let dimAngle = new paper.Path({x: event.point.x - 5/this.store.grid.canvasZoom, y: event.point.y - 10/this.store.grid.canvasZoom})
    dimAngle.add({x: event.point.x + 5/this.store.grid.canvasZoom, y: event.point.y - 20/this.store.grid.canvasZoom})
    dimAngle.strokeColor = 'black'
    dimAngle.strokeWidth = 3/this.store.grid.canvasZoom
    dimAngle.fillColor = 'white'
    let dimVert = new paper.Path(styles.line())
    dimVert.add({x: event.point.x, y: event.point.y+10/this.store.grid.canvasZoom})
    dimVert.add({x: event.point.x, y: event.point.y - 25/this.store.grid.canvasZoom})
    return new paper.Group(dimHorz, dimVert, dimAngle)
  }
  onMouseDown = (event) => {
    let imageLayerHitResult = this.store.imageLayer.hitTest(event.point, this.hitOptions);
    let handleHitResult = this.store.imageHandlesLayer.hitTest(event.point, this.hitOptions);
    //check if an image has been clicked
    if (imageLayerHitResult) {
      this.makeSelectedRaster(imageLayerHitResult.item)
      this.adjustHandles(this.selectedRaster);

    } //check if a handler has been clicked --> Resize or rotate
    else if (this.store.imageAction == 'resizing' || this.store.imageAction == 'rotating'){
      this.selectedHandle = {
        cornerIndex: handleHitResult.item.data.cornerIndex,
        position: handleHitResult.item.position,
      };
    }
    else if (this.store.onMobile && handleHitResult){
      this.store.imageAction = 'resizing'
      this.selectedHandle = {
        cornerIndex: handleHitResult.item.data.cornerIndex,
        position: handleHitResult.item.position,
      };
    }
     //if nothing has been clicked, clear the selection
    else {
      if (this.selectedRaster) {
        this.selectedRaster.data.boundingRect.visible = false;
        this.selectedRaster = null;
        this.store.imageHandlesLayer.removeChildren();
      }
    }
  };
  onMouseUp = (event) => {
    this.store.imageAction = null
  }
  adjustHandles = (selectedRaster) => {
    this.store.imageHandlesLayer.removeChildren();
    let boundingRect = selectedRaster.data.boundingRect;

    boundingRect.segments.forEach((segment, i) => {
      let halfRectWidth = 4/this.store.grid.canvasZoom
      let handle = new paper.Path.Rectangle(
        {x: segment.point.x-halfRectWidth, y: segment.point.y-halfRectWidth},
        {x: segment.point.x+halfRectWidth, y: segment.point.y+halfRectWidth}
      )
      this.store.imageHandlesLayer.addChild(handle);
      handle.strokeColor = styles.selectedImageColor;
      handle.strokeWidth = 1/this.store.grid.canvasZoom
      handle.fillColor = 'white'
      handle.data.parent = selectedRaster;
      handle.data.cornerIndex = i;
    })
  };
  onMouseDrag = (event) => {
    //Resize image
    if (this.store.imageAction == 'resizing'){
      let oppositeCornerIndex = (this.selectedHandle.cornerIndex + 2) % 4
      let oppositeCorner = this.selectedRaster.data.boundingRect.segments[oppositeCornerIndex].point
      const distBetweenCorners = this.selectedHandle.position.subtract(oppositeCorner)
      const distFromEventToOppositeCorner = event.point.subtract(oppositeCorner)

      let scale
      if (Math.abs(event.point.x-oppositeCorner.x) > Math.abs(event.point.y-oppositeCorner.y)){
        scale = distFromEventToOppositeCorner.x / distBetweenCorners.x
      }
      else {
        scale = distFromEventToOppositeCorner.y / distBetweenCorners.y
      }
      this.selectedHandle.position = event.point
      this.selectedRaster.scale(scale, oppositeCorner)
      this.selectedRaster.data.boundingRect.scale(scale, oppositeCorner)
      this.adjustHandles(this.selectedRaster)
    }
    //Rotate image
    else if (this.store.imageAction == 'rotating'){
      let rotationAngle = event.point.subtract(this.selectedRaster.position).angle - (event.lastPoint.subtract(this.selectedRaster.position)).angle
      this.selectedRaster.rotate(rotationAngle);
      this.selectedRaster.data.boundingRect.rotate(rotationAngle);
      this.adjustHandles(this.selectedRaster);
    }
    //Move image
    else if (this.selectedRaster) {
      let moveFactor = this.selectedRaster.position.add(event.delta)
      this.selectedRaster.setPosition(moveFactor);
      this.selectedRaster.data.boundingRect.setPosition(moveFactor);
      this.adjustHandles(this.selectedRaster);
    }
  };
  onMouseMove = (event) => {
    const resizeHitOptions = {
      segments: true,
      stroke: true,
      fill: true,
      tolerance: 5,
    }
    const rotationHitOptions = {
      segments: true,
      stroke: true,
      fill: true,
      tolerance: 15,
    }
    const imageHitOptions = {
      segments: true,
      stroke: true,
      fill: false,
      tolerance: 5,
    }

    let imageLayerHitResult = this.store.imageLayer.hitTest(event.point, imageHitOptions);
    let resizeHandleHitResult = this.store.imageHandlesLayer.hitTest(event.point, resizeHitOptions);
    let rotationHandleHitResult = this.store.imageHandlesLayer.hitTest(event.point, rotationHitOptions)

    if (rotationHandleHitResult && !resizeHandleHitResult && !imageLayerHitResult){
      this.store.imageAction = 'rotating'

      let element = document.getElementById("canvas");
      
      if(rotationHandleHitResult.item._index == 0){
        element.classList.add("bottomLeftCursor");
      }
      else if(rotationHandleHitResult.item._index == 1){
        element.classList.add("topLeftCursor");
      }
      else if(rotationHandleHitResult.item._index == 2){
        element.classList.add("topRightCursor");
      }
      else{
        element.classList.add("bottomRightCursor");
      }
    }
    else if (resizeHandleHitResult && !imageLayerHitResult){
      this.removeAllClasses();
      this.store.imageAction = 'resizing'
      let cornerIndex = resizeHandleHitResult.item._index
      if (cornerIndex == 1 || cornerIndex == 3){
        document.getElementById("canvas").style.cursor = 'nw-resize'
      }
      else {
        document.getElementById("canvas").style.cursor = 'ne-resize'
      }
    }
    else {
      this.removeAllClasses();
      this.store.imageAction = 'null'
    }
  };
  removeAllClasses(){
    var element = document.getElementById("canvas");
    element.classList.remove("topRightCursor");
    element.classList.remove("topLeftCursor");
    element.classList.remove("bottomLeftCursor");
    element.classList.remove("bottomRightCursor");
    document.getElementById("canvas").style.cursor = 'default'
  }
}
