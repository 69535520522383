<template>
  <v-btn icon variant="plain">
    <v-icon>mdi:mdi-menu</v-icon>
  
    <v-menu activator="parent">
      <v-list density="compact" width="160">
        <v-list-item v-if="canShare" @click="openShareDialog()">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-account-multiple-plus" color="grey-darken-2"></v-icon>
            Share
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          class="d-flex"
          v-if="(store.projectId == null || (store.projectId && !store.projectId.includes('template'))) && 
            store.userPermissions != 'Viewer'"
          @click="store.projectId == null ? openSaveDialog('saveNew') : openSaveDialog('save')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-content-save" color="grey-darken-2"></v-icon>
            Save
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="store.projectId"
          @click="openSaveDialog('saveAs')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-content-save-all" color="grey-darken-2"></v-icon>
            Save Copy
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('saveAsTemplate')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            Create Template
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('saveTemplate')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            Overwrite Template
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('saveMiniGame')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            Create Mini Game
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('overwriteMiniGame')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            overwrite minigame
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>

  <!--Dialog -->
  <v-dialog
    v-model="saveDialog"
    style="width: 85%"
    >
    <v-card>
      <v-card-title v-if="saveType=='save'">Save Sketch</v-card-title>
      <v-card-title v-if="saveType=='saveNew'">Save Sketch</v-card-title>
      <v-card-title v-if="saveType=='saveAs'">Save Copy of Sketch</v-card-title>
      <v-card-title v-if="saveType=='saveAsTemplate'">Save Sketch As A New Template</v-card-title>
      <v-card-title v-if="saveType=='saveTemplate'">Overwrite Existing Template</v-card-title>
      <v-card-title v-if="saveType=='saveMiniGame'">Save Minigame</v-card-title>
      <v-card-title v-if="saveType=='overwriteMiniGame'">Overwrite Minigame</v-card-title>
      <v-card-subtitle style="white-space: normal;">The current canvas view will be used as the thumbnail image</v-card-subtitle>
      <v-text-field
        v-if="saveType != 'save' "
        class="px-4 mt-2" 
        :label="saveType=='saveAsTemplate' ? 'Template Name' : 'Sketch Name'" 
        variant="outlined" density="compact"
        v-model="newName"
      ></v-text-field>
      <v-text-field
        v-if="saveType && saveType.includes('MiniGame')"
        class="px-4 mt-2" 
        label="deflection limit (in)" 
        variant="outlined" density="compact"
        v-model="deflectionLimit"
      ></v-text-field>
      <v-text-field
        v-if="saveType && saveType.includes('MiniGame')"
        class="px-4 mt-2" 
        label="game name" 
        variant="outlined" density="compact"
        v-model="minigameName"
      ></v-text-field>
      <v-card-actions class="d-flex justify-space-around px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="Cancel()" color="grey" class="white--text">
        Cancel
        </v-btn>
        <v-btn @click="Save()" color="primary" :loading = "saveLoading">
        Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Share Dialog -->
  <ShareDialog 
    :shareDialog="shareDialog" 
    :sketch="sketch" 
    @shareDialogClosed="shareDialog=false">
  </ShareDialog>

</template>

<script>
import {useStore} from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import { useAuth0 } from '@auth0/auth0-vue';
import {  save } from '@/composables/Save.js'
import ShareDialog from '@/components/ui/ShareDialog.vue';
import UploadImage from './UploadImage.vue';

export default {
  props: {
    canShare: {
      type: Boolean,
      required: true
    },
    sketch: {
      type: Object,
      required: false
    },
  },  
  components: {
    toggleButton,
    ShareDialog,
    UploadImage
  },
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    return {
      store,
      isAuthenticated, 
      user,
    }
  },
  data: () => ({
    saveDialog: false,
    newName: '',
    saveLoading: false,
    saveType: null,
    imageId:"",
    deflectionLimit: null,
    minigameName: "",
    shareDialog: false
  }),
  created(){
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    openSaveDialog(saveType){
      this.saveType=saveType
      this.saveDialog=true
    },
    async Save(){
      let routerPath = await save(this.user, this.saveType, this.newName, this.minigameName, this.deflectionLimit)
      if (routerPath){
        this.$router.push(routerPath)
      }

      this.newName = null
      this.saveType = null
      this.saveLoading = false
      this.saveDialog = false
    },
    Cancel(){
      this.newName = ''
      this.saveDialog = false
      this.saveType=null
    },
    openShareDialog(){
      this.shareDialog = true
    },
  }
}
</script>

<style scoped>
</style>
