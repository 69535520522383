<template>  
  <div style="height:100vh; width:100%; position:relative;">
    <canvas id="grid" v-show="store.gridOn"
      class="canvas-style" style="z-index: 0"
      :class="!(store.activeTool == 'resize' || store.activeTool == 'select') ? 'crossHairCursor' : ''"
      onkeydown = "keyPressed" resize="true">
    </canvas>
    <canvas id="canvas"
      class="canvas-style" style="z-index: 1; width: calc(100vw - (100vw - 100%))"
      :class="!(store.activeTool == 'resize' || store.activeTool == 'select') ? 'crossHairCursor' : ''"
      onkeydown = "keyPressed" resize="true">
    </canvas>
    <div v-if="loading">loading</div>
    <template v-else>
      <!-- Top Bar Greyed Out -->
      <div v-if="store.activeTool== 'resize'" 
        style="position: absolute; top:0px;width:100%; height: 50px; 
        background-color: rgb(185, 185, 185); z-index:1000 !important">
      </div>

      <!-- Top Bar Normal -->
      <div ref="topBar"
        style="position: absolute; background:white; width:100%; height: 50px; top:0px; z-index:999" 
        class="d-flex align-center elevation-1 justify-space-between">
        <div class="d-flex align-center">
          <LogoBtn />
          <div class="d-flex rounded-lg pa-1 pr-0">
            <toggleButton 
              v-if="!store.onSmallScreen"
              :tooltip="'Reset'" 
              @click="resetCanvas()">
              <v-icon>mdi:mdi-refresh</v-icon>
            </toggleButton>
          </div>
          <v-btn v-if="showProjectName" variant="text" style="max-width: 200px; justify-content:left !important" class="ellipsisText">
            <span class="ellipsisText" style="max-width: 200px;">{{store.projectName}}</span>
          </v-btn>
        </div>
        <div class="d-flex">
          <UndoRedo v-if="!(store.analyzeMode || store.userPermissions == 'Viewer' || store.onSmallScreen)"></UndoRedo >
          <ToolsMiniGame v-if="!store.analyzeMode"></ToolsMiniGame>
          <AnalysisSettings v-if="store.analyzeMode && !store.onSmallScreen"></AnalysisSettings>
          <toggleButton @click="store.toggleAnalyzeMode()" v-if="store.analyzeMode && store.onSmallScreen">
              <v-icon size="30">
                mdi:mdi-close
              </v-icon>
            </toggleButton>
        </div>
        <div class="d-flex pa-1" style="right:0px" >
          <v-btn variant="plain" icon @click="openLeaderBoard()" >
            <v-icon>mdi: mdi-trophy</v-icon>
            <v-tooltip v-if="!store.onMobile" activator="parent" location="bottom">Leaderboard</v-tooltip>
          </v-btn>
          <v-btn variant="plain" @click="instructionsDialog = true" icon>
            <v-icon>fa: fa-regular fa-circle-question</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Top Left -->
      <div style="position:absolute; top: 55px; display: block; left: 5px; z-index:10">
        <template v-if="store.onSmallScreen">
          <AnalysisSettingsSmallScreen v-if="store.analyzeMode"></AnalysisSettingsSmallScreen>
        </template>
        <span v-if="store.lineProps" style="background:white">
          X: {{store.lineProps.x}},  Y: {{store.lineProps.y}},  Length: {{store.lineProps.length}}
        </span>
      </div>

      <!-- Top Center -->
      <div style="position:absolute; top: 15px; display: block; left: 50%; transform: translate(-50px, 0); z-index:1000">
        <UploadImageSettings />
      </div>

      <!-- Bottom Right -->
      <div class="d-flex" style="position: absolute; bottom:8px; right: 5px; z-index: 1">
        <ZoomSettings></ZoomSettings>
        <ViewSettings v-if="!store.onSmallScreen"></ViewSettings>
      </div>
      <div v-if="store.onSmallScreen" class="d-flex" style="position: absolute; bottom:58px; right: 6px; z-index: 1">
        <ViewSettingsSmallScreen />
      </div>
      
      <!-- Analyze Messages -->
      <div v-if="store.analyzeMode" class="errorMessage d-flex justify-center" style="width:100%">
        <span v-if="!store.analyze.finishedComputing" class="px-5" style="background: white; color:blue"
        :style="store.onSmallScreen ? 'position: absolute; top: 30px; margin: 10px' : ''">
          Calculating...
        </span>
        <template v-else>
          <div v-if="store.analyze.warning" class="d-flex flex-column justify-center" 
            :style="store.onSmallScreen ? 'position: absolute; top: 30px; margin: 10px' : ''">
            <span class="px-5" style="background: white; color:red">{{store.analyze.warning}}</span>
            <div class="d-flex justify-center">
              <v-btn color="grey" density="compact" @click="keepDesigning()" elevation="0" variant="outlined" style="width: 200px; z-index: 1">Try Again</v-btn>
            </div>
          </div>
          <div v-else class="d-flex flex-column pa-2 rounded-sm" style="z-index: 200; background-color: rgba(250, 250, 250, 0.7);"
            :style="store.onSmallScreen ? 'position: absolute; top: 30px; margin: 10px' : ''">
            <span v-html="resultsMessage"></span>
            <div class="d-flex justify-center mt-1">
              <v-btn
                color="black" density="compact" style="background-color; white"
                @click="keepDesigning()" elevation="0" 
                >
                Keep Designing
              </v-btn>
              <v-btn 
                :disabled="id==1 || id==2 || id==3"
                color="primary" density="compact" class="ml-2" 
                @click="openResultsDialog()" 
                elevation="0" 
                v-if="minigameDesignPasses && this.newBestWeight" 
                >
                Submit Design
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div v-if="store.hintMessage" class="errorMessage d-flex justify-center" style="width:100%">
        <span class="px-5" style="background: white;">{{store.hintMessage}}</span>
      </div>
      <div v-if="store.analyze" class="errorMessage d-flex justify-center" style="width:100%">
        <span v-if="store.analyze.okayToRunWarning" 
          class="px-5" style="background: white; color:red"
        >{{store.analyze.okayToRunWarning}}</span>
      </div>
      
      <!-- Element Properties Panel -->
      <elementProps v-if="store.selectedItems.length > 0 && store.selectionComplete 
        && !store.analyzeMode" style="z-index:1">
      </elementProps>

      <!-- Coordinates Icon -->
      <coordinates-icon width="40" height="40" style="position:absolute; bottom: 15px; left: 15px"/>

      <!-- Instructions Dialog -->
      <v-dialog v-model="instructionsDialog" :style="store.onSmallScreen ? 'width: 95%' : 'width: 700px'">
        <v-alert v-if="id==1 || id==2 || id==3" 
            color="warning"
            title="Submissions Closed"
        >
          <span>Submissions for this game are now closed, but you can still play! <br>Check out all the results on the
            <a v-if="id==1" href="https://www.ellipse.studio/notebook/6743632320d61ac34cf9ccba/visitor/6743632320d61ac34cf9ccbe#pages" target="blank">
              Ellipse Dashboard
            </a>
            <a v-else-if="id==2" href="https://www.ellipse.studio/notebook/6743632320d61ac34cf9ccba/visitor/674ddd3cac039524298bc39c#pages" target="blank">
              Ellipse Dashboard
            </a>
            <a v-else-if="id==3" href="https://www.ellipse.studio/notebook/6743632320d61ac34cf9ccba/visitor/6757176198d72477a4467148#pages" target="blank">
              Ellipse Dashboard
            </a>
          </span>
        </v-alert>
        <v-card :class="store.onSmallScreen ? 'pa-4' : 'pa-6'">
          <div style="font-size:24px; color: #717171; font-weight:bold" >
            Instructions
          </div>
          <div style="font-size:32px; font-weight:bold; margin-top: -8px" class="text-uppercase">
            {{minigameName}}
          </div>
          <div class="d-flex instructionRow mt-5">
            <div class="circle" style="background-color: rgb(var(--v-theme-purple))">1</div>
            <div>
              Add frame elements to meet the following criteria
              <ul style="margin-left: 20px;">
                <li>Deflection Limit: <span style="font-weight:bold; color: black">{{deflectionLimit}} in</span></li>
                <li>Utilization Limit: <span style="font-weight:bold; color: black">100%</span></li>
              </ul>
            </div>
            <v-spacer />
            <div><v-icon size="large">mdi:mdi-pencil</v-icon></div>
          </div>
          <div class="d-flex instructionRow">
            <div class="circle" style="background-color: rgb(var(--v-theme-green))">2</div>
            <div>
              Run the analysis to check your design
            </div>
            <v-spacer />
            <div><v-icon size="large">mdi:mdi-play</v-icon></div>
          </div>
          <div class="d-flex instructionRow">
            <div class="circle" style="background-color: rgb(var(--v-theme-orange))">3</div>
            <div class="mr-6">
              Submit your design and see how your structure ranks against others by weight
            </div>
            <v-spacer />
            <div><v-icon size="large">mdi:mdi-trophy</v-icon></div>
          </div>
          <div class="d-flex">
            <v-spacer />
            <v-btn size="large" color="black" @click="instructionsDialog=false" elevation="0">Play Now</v-btn>
          </div>
        </v-card>
      </v-dialog>

      <!-- Results Dialog -->
      <v-dialog v-model="resultsDialog" :style="store.onSmallScreen ? 'width: 95%' : 'width: 700px'">
        <v-card :class="store.onSmallScreen ? 'pa-1' : 'pa-6'" >
            <v-card-subtitle class="d-flex mr-2 font-weight-bold">
              [{{minigameName}}]
            </v-card-subtitle>
            <v-card-title class="d-flex font-weight-bold" style="margin-top: -10px">
              CHALLENGE COMPLETED
            </v-card-title>
            <v-card-subtitle class="d-flex mr-2" style="margin-top: -10px; white-space: normal">
              <template v-if="!nameEntered">Enter your name to be submitted in the rankings</template>
              <template v-else>Current Ranking <span class="text-purple ml-1 font-weight-bold">#{{userRank}}</span></template>
            </v-card-subtitle>
            <template v-if="!nameEntered">
              <div class="mx-4 mt-8">
                <v-text-field
                  variant="outlined"
                  label = 'Name'
                  v-model="submissionName"
                ></v-text-field>
              </div>
              <div>
                <div class="d-flex mt-3 mx-4">
                  <v-spacer />
                  <v-btn size="large" color="black" flat @click="submit()" :loading="submitLoading">submit</v-btn>
                </div>
              </div>
            </template>
            <template v-else>
              <v-card-text>
                <v-data-table
                  :height="300"
                  :items="rankings"
                  :headers="rankingsHeaders"
                  item-key="id"
                  density="compact"
                  fixed-header
                  items-per-page="10"
                  >
                  <template v-slot:item.rank="{ item }">
                    <div>
                      <!-- Check if ranking is 1, then display trophy icon -->
                      <v-icon v-if="item.rank === 1" size="20" style="margin-left: -30px" color="orange">mdi: mdi-trophy</v-icon>
                      {{ item.rank }}
                    </div>
                  </template>
                </v-data-table>
                <div>
                  <div class="d-flex mt-3 mx-4">
                    <v-spacer />
                    <v-btn size="large" color="black" flat @click="playAgain()">Play again</v-btn>
                  </div>
                </div>
              </v-card-text>
            </template>
        </v-card>
      </v-dialog>

      <!-- Leaderboard Dialog -->
      <v-dialog v-model="leaderboardDialog" :style="store.onSmallScreen ? 'width: 95%' : 'width: 700px'">
        <v-card :class="store.onSmallScreen ? 'pa-1' : 'pa-6'" flat>
          <v-card-title class="d-flex justify-space-between font-weight-bold">
            LEADERBOARD
            <v-spacer />
            <v-btn size="x-small" @click="leaderboardDialog=false" variant="plain" icon><v-icon size="x-large">mdi: mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-subtitle class="d-flex mr-2 font-weight-bold" style="margin-top: -10px">[{{minigameName}}] </v-card-subtitle>
          <v-card-text>
            <div style="width: 100%" >
              <v-data-table
                :height="300"
                :items="rankings"
                :headers="rankingsHeaders"
                item-key="id"
                density="compact"
                fixed-header
                items-per-page="10"
                >
                <template v-slot:item.rank="{ item }">
                  <div>
                    <!-- Check if ranking is 1, then display trophy icon -->
                    <v-icon v-if="item.rank === 1" size="20" style="margin-left: -30px" color="orange">mdi: mdi-trophy</v-icon>
                    {{ item.rank }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import paper from 'paper'
import * as grid from '@/tools/Grid'
import {Grid} from '@/tools/Grid'
import elementProps from '@/components/minigame/ElementProps.vue'
import {useStore} from '@/store/store'
import Save from '@/components/Save.vue'
import Share from '@/components/Share.vue'
import UndoRedo from '@/components/UndoRedo.vue'
import * as styles from '@/styles/paperStyles'
import {CreatePaperScopeAndLayers, CreateTools, GenerateScrollPickerValues} from '@/composables/setUp.js'
import coordinatesIcon from '@/assets/icons/coordinatesIcon.vue'
import History from "@/utilities/History"
import {PanActivate} from "@/viewportControls/Pan.js"
import {ActivatePinchZoom, WheelZoom} from "@/viewportControls/zoom.js"
import Tools from '@/components/Tools.vue'
import ToolsMiniGame from '@/components/minigame/ToolsMiniGame.vue'
import * as utils from '@/utilities/Utils'

import ViewSettings from '@/components/ViewSettings.vue'
import ZoomSettings from '@/components/ZoomSettings.vue'
import AnalysisSettings from '@/components/AnalysisSettings.vue'
import toggleButton from '@/components/ui/toggleButton.vue'
import UploadImage from '@/components/UploadImage.vue'
import UploadImageSettings from '@/components/UploadImageSettings.vue'
import Settings from '@/components/Settings.vue'
import LogoBtn from '@/components/ui/LogoBtn.vue'
import { useAuth0 } from '@auth0/auth0-vue';
import {ref as vueRef} from 'vue'
import ViewSettingsSmallScreen from '../components/ViewSettingsSmallScreen.vue'
import AnalysisSettingsSmallScreen from '../components/AnalysisSettingsSmallScreen.vue'
import { storeToRefs } from 'pinia'


export default {
  components: {
    grid, 
    elementProps, 
    Save, 
    coordinatesIcon, 
    Share, 
    UndoRedo, 
    Tools, 
    ViewSettings,
    ZoomSettings,
    AnalysisSettings,
    toggleButton,
    UploadImage,
    UploadImageSettings,
    Settings,
    LogoBtn,
    ToolsMiniGame,
    ViewSettingsSmallScreen,
    AnalysisSettingsSmallScreen
  },
 props: ['id'],
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    
    store.canvas = new paper.PaperScope()
    const topBar = vueRef(null)
    return {
      store, 
      user, 
      isAuthenticated,
      topBar,
    }
  },
  data: () => ({
    maxZoomOut: 0.3,
    pinchDistance: 0,
    modelInfo: null,
    loading: false,
    lastCenter: null,
    bottomLeftMostPoint: null,
    instructionsDialog: true,
    resultsDialog: false,
    rankings: null,
    deflectionLimit: null,
    submissionName: '',
    nameEntered: false,
    leaderboardDialog: false,
    userRank: null,
    rankingsHeaders: [
      {title: 'Rank', align: 'center', key: 'rank' },
      {title: 'Name', align: 'start', key: 'submissionName'},
      {title: 'Weight Added', align: 'start', key: 'weight'},
      {title: 'Max Utilization', align: 'center', key: 'utilization'},
      {title: 'Max Deflection', align: 'center', key: 'deflection'}
    ],
    userBestWeight: null,
    newBestWeight: false,
    minigameName: null,
    submitLoading: false
  }),
  watch: {
    //Prevent touch zoom on topBar
    "topBar": function(){ 
      if(this.topBar){
        this.topBar.addEventListener('touchstart', function touchHandler(event){
          if(event.touches.length > 1){
            event.preventDefault()
          }
        })
      }
    }
  },
  async created(){
    this.loading = true
    //check what kind of devise is being used
    if (window.matchMedia("(pointer: coarse)").matches){
      this.store.onMobile = true
    }
    this.checkOnSmallScreen()
    
    //Update store projectId on refresh 
    if (!this.store.projectId) {
      this.store.projectId = this.id
    }
    console.log('id', this.id)

    if (this.store.user && this.store.user.name) this.submissionName = this.store.user.name
    this.getUserBestWeight()
    
    //Set minigame name
    switch(this.id){
      case '1':
        this.minigameName = 'Long Span'
        break;
      case '2': 
        this.minigameName = 'Tower'
        break;
      case '3': 
        this.minigameName = 'Frame'
        break;
    }
  },
  async mounted() {
    //set up
    CreatePaperScopeAndLayers()
    this.store.utilizationLayer.visible = true
    CreateTools()
    GenerateScrollPickerValues()
    
    this.lastCenter = new paper.Point({x: document.body.clientWidth/2, y: document.body.clientHeight/2})
    const canvas = document.getElementById('canvas')

    if (this.id){
      await this.getModelInfo()
      this.store.grid = this.initGrid()
      if (this.id && this.modelInfo){
        await this.DrawSketch()
      }
    }
    else this.store.grid = this.initGrid()
            
    //add pinch and scroll zoom
    canvas.addEventListener('wheel', WheelZoom, {passive: false})
    // window resizing
    window.addEventListener("resize", this.resize);
    // listen for undo/redo key press
    document.addEventListener('keypress', this.keyPressed)
    //disable default right click functionality
    document.addEventListener('contextmenu', event => event.preventDefault());

    // Activate Panning
    PanActivate(canvas)
    
    // Activate Pinch Zoom
    if (this.store.onMobile) ActivatePinchZoom()
    this.loading = false
  },
  computed: {
    canShare(){
      if (this.id && !this.id.includes('template') && this.store.userPermissions != 'Viewer') return true
      else false
    },
    showProjectName(){
      if (this.store.projectName && window.innerWidth > 800) return true
      else false
    },
    minigameDesignPasses(){
      if (this.store.minigameDeflection && this.store.minigameUtilization){
        if (this.store.minigameDeflection < Number(this.deflectionLimit) && this.store.minigameUtilization < 100) return true
        else return false
      } else return false
    },
    resultsMessage() {
      const { minigameDeflection, minigameUtilization, minigameWeight } = this.store;
      const deflection = utils.Round(minigameDeflection, 2);
      const utilization = utils.Round(minigameUtilization, 2) + '%';
      const addedWeight = utils.Round(minigameWeight, 2) + ' lbs';

      const deflectionColor = minigameDeflection < this.deflectionLimit ? 'green' : 'red';
      const utilizationColor = minigameUtilization < 100 ? 'green' : 'red';

      let message = '';

      if (this.userBestWeight && (minigameWeight < this.userBestWeight) && this.minigameDesignPasses) {
        this.userBestWeight = minigameWeight;
        this.newBestWeight = true;
        message = `<strong class="d-flex justify-center">New Personal Best Achieved!</strong>`;
      } else if (this.userBestWeight && !this.newBestWeight && this.minigameDesignPasses) {
        message = `<strong class="d-flex justify-center">You did not beat your best weight of: ${utils.Round(this.userBestWeight, 2)} lbs</strong>`;
      }

      if (!message) {
        if (deflectionColor === 'red' && utilizationColor !== 'red') {
          message = `<strong class="d-flex justify-center">Your structure exceeds the deflection limit of [${this.deflectionLimit} in]</strong>`;
        } else if (deflectionColor !== 'red' && utilizationColor === 'red') {
          message = `<strong class="d-flex justify-center">Your structure exceeds the utilization limit of [100%]</strong>`;
        } else if (deflectionColor === 'red' && utilizationColor === 'red') {
          message = `<strong class="d-flex justify-center">Your structure exceeds the deflection limit of [${this.deflectionLimit} in] and utilization limit of [100%]</strong>`;
        }
      }

      return `
        ${message}
        Weight Added: <strong>${addedWeight}</strong>, 
        Max Deflection: <strong><span style="color: ${deflectionColor};">${deflection} in</span></strong>, 
        Max Utilization: <strong><span style="color: ${utilizationColor};">${utilization}</span></strong>
      `;
    }
  },
  methods: {
    initGrid() {
      const gridCanvas = document.getElementById('grid');
      const context = gridCanvas.getContext("2d", { alpha: false });
      
      let {stdCanvasH, stdCanvasW} = this.setGridDims()
      gridCanvas.style.width = stdCanvasW + "px";
      gridCanvas.style.height = stdCanvasH + "px";
      
      let grid = new Grid({
        stdCanvasH,
        stdCanvasW,
        minCellSize: 30,
        context,
        gridCanvas,
      });
      grid.draw()
      return grid
    },
    keyPressed(event){
      if (!this.store.analyzeMode) {
        if (event.ctrlKey && event.charCode == 26){ //ctrl+z
          History.undo()
        }
        if (event.ctrlKey && event.charCode == 25){ //ctrl+y
          History.redo()
        }
      }
    },
    checkOnSmallScreen(){
      if (window.innerWidth < 620){
        this.store.onSmallScreen = true
      } 
      else {
        this.store.onSmallScreen = false
      }
    },
    resize(){
      this.setGridDims()
      let currentCenter = new paper.Point({x: window.innerWidth/2, y: window.innerHeight/2})
      let offset = currentCenter.subtract(this.lastCenter)
      let offsetCalibratedToZoomLevel = offset.divide(this.store.canvas.view.zoom)
      this.store.grid.axisPosX += offset.x
      this.store.grid.axisPosY += offset.y
      this.store.grid.lastAxisPosX = this.store.grid.axisPosX
      this.store.grid.lastAxisPosY = this.store.grid.axisPosY 
      this.store.grid.height = window.innerHeight
      this.store.grid.width =  document.body.clientWidth

      this.lastCenter = currentCenter
      this.store.grid.draw()
      this.store.canvas.view.center = this.store.canvas.view.center.subtract(offsetCalibratedToZoomLevel)
      this.checkOnSmallScreen()
    },
    setGridDims(){
      const gridCanvas = document.getElementById('grid');
      const context = gridCanvas.getContext("2d", { alpha: false });

      const stdCanvasW = document.body.clientWidth
      const stdCanvasH = window.innerHeight
      const optCanvasW = stdCanvasW * window.devicePixelRatio;
      const optCanvasH = stdCanvasH * window.devicePixelRatio;

      if (window.devicePixelRatio > 1) {
        gridCanvas.width = optCanvasW;
        gridCanvas.height = optCanvasH;
        context.scale(window.devicePixelRatio, window.devicePixelRatio);
      } else {
        gridCanvas.width = stdCanvasW;
        gridCanvas.height = stdCanvasH;
      }
      gridCanvas.style.width = stdCanvasW + "px";
      gridCanvas.style.height = stdCanvasH + "px";
      
      return {stdCanvasW, stdCanvasH}
    },
    async getSketchUsersAndPermissions(){
      let permissions
      if (this.id.includes('template')) {
        permissions = [this.user];
      }
      else {
        let res = await this.$api({
          url: "/api/permission/_permission/get-permission-by-projectId",
          method: "POST",
          data: {
            projectId: this.id,
          }
        })
        permissions = res.data
      }
      this.store.users = permissions;
      let curUser = permissions.find(permission => permission.email.toLowerCase() == this.user.email.toLowerCase());
      if (curUser) {
        this.store.userPermissions = curUser.permissions;
      } else {
        console.log('unable to get current user permissions')
      }
    },

    async getModelInfo(){
      let minigame = await this.$api({
        url: "/api/minigame/_minigame/get-minigame",
        method: "POST",
        data: {
          minigameId: this.id,
        }
      })
      
      let modelInfo = minigame.data
      this.modelInfo = minigame.data
      this.deflectionLimit = modelInfo.deflectionLimit
      
      //Set Canvas Center
      if (modelInfo.settings.canvasCenter){
        this.store.canvas.view.center = modelInfo.settings.canvasCenter
      } 
      else { //Find left bottom most frame point and set as canvas center
        let bottomLeftMostPoint = {x: 10000000000, y: 0}
        modelInfo.elements.forEach(line => {
          if (line.start.y > bottomLeftMostPoint.y || (line.start.y === bottomLeftMostPoint.y && line.start.x < bottomLeftMostPoint.x)) {
            bottomLeftMostPoint = line.start;
          }
          if (line.end.y > bottomLeftMostPoint.y || (line.end.y === bottomLeftMostPoint.y && line.end.x < bottomLeftMostPoint.x)) {
            bottomLeftMostPoint = line.end;
          }
        })
        this.store.canvas.view.center = bottomLeftMostPoint
        this.bottomLeftMostPoint = bottomLeftMostPoint
      }
    },
    getUserPermissions(users){
      let currentUser = users.find(user => user.email.toLowerCase() == this.user.email.toLowerCase())
      return currentUser.permissions
    },
    async DrawSketch(){
      const modelInfo = this.modelInfo

      //set settings
      for (const [key, value] of Object.entries(modelInfo.settings)){
        if (key == 'zoom'){
          // let zoomDiff = modelInfo.settings.zoom - 1
          // let zoomFactor = zoomDiff/0.10
          // this.store.grid.setZoom(zoomFactor, this.store.grid.axisPosX, this.store.grid.axisPosY)
        } else {
          this.store[key] = value
        }
      }
      //Draw Frames
      modelInfo.elements.forEach(element => {
        var path = new paper.Path(styles.line())
        //If sketch was created with old grid, need to recalibrate element start/end points
        let start = this.bottomLeftMostPoint ? this.mapToNewGrid(element.start) : element.start
        let end = this.bottomLeftMostPoint ? this.mapToNewGrid(element.end) : element.end
        path.add(start, end)
        path._id = element.id
        path.data = {
          start: element.startFixity,
          end: element.endFixity,
          frameProps: element.frameProps,
          layer: this.store.drawingLayer.name,
          notSelectable: true
        }
        if (!path.data.frameProps.hasOwnProperty('orientation')){
          path.data.frameProps.orientation = 0
        }
        this.store.tools.draw.addMemberSizeText(path)
        this.store.drawingLayer.addChild(path)
        this.store.tools.draw.addDimensions(path)
        this.store.tools.draw.addEndNode(path)
      })
      //Draw Supports
      modelInfo.supports.forEach(support => {
        var supportIcon = this.store.tools.supports.newSupportIcon(support.type)
        this.store.tools.supports.scaleInitial(supportIcon)
        supportIcon.strokeColor = 'black'
        let supportLocation = this.bottomLeftMostPoint ? this.mapToNewGrid(support.location) : support.location
        let supportPosition =  {x: supportLocation.x, y: supportLocation.y + supportIcon.bounds.height/2}
        supportIcon.position = supportPosition
        supportIcon.data = {
          type: support.type,
          location: supportLocation,
          layer: this.store.supportLayer.name,
          notSelectable: true
        }
        this.store.supportLayer.addChild(supportIcon)
      })
      //Line Loads
      modelInfo.lineLoads.forEach(async (load) => {
        //find assoc line
        let line = this.store.drawingLayer.children.find(child => child._id == load.elementId)
        let isLineMass = load.type == 'Line Mass' ? true : false
        let lineLoad = await this.store.tools.loads.scaleLineLoad(line, load.magnitude, load.dir, isLineMass)
        lineLoad.data.notSelectable = true
        this.store.tools.loads.addLoadText(lineLoad)
      })
      //Point Loads
      modelInfo.pointLoads.forEach(load => {
        let location = this.bottomLeftMostPoint ? this.mapToNewGrid(load.location) : load.location
        let pointLoad = this.store.tools.loads.scalePointLoad(location, load.magnitude, load.dir)
        pointLoad.data.notSelectable = true
        this.store.tools.loads.addLoadText(pointLoad)
      })

      //Image Underlay
      if(modelInfo.imageInfo){
        modelInfo.imageInfo.forEach(async i => {
          let imageURL = await this.$api({
            url: "/api/sketch/_sketch/get-image-download-url",
            method: "POST",
            data: {
              imageId: i.id,
            }
          })
          if (imageURL.data){
            var raster = new paper.Raster({source:imageURL.data[0], position:i.imgPosition});
            raster.onLoad = () => {
              raster.rasterName = i.id;
              let boundingRect = new paper.Path.Rectangle(raster.bounds)
              boundingRect.position = i.imgPosition
              boundingRect.strokeColor = styles.selectedImageColor;
              boundingRect.strokeWidth = 1;
              raster.scale(i.scaling)
              boundingRect.scale(i.scaling)
              raster.rotate(i.rotation)
              boundingRect.rotate(i.rotation)
              raster.opacity = this.store.imageOpacity
              raster.data.boundingRect = boundingRect
              this.store.imageBoundingRectLayer.addChild(boundingRect)
              this.store.imageLayer.addChild(raster)
              this.store.imageBoundingRectLayer.visible = false
            }
          }
          this.store.imageFile = {id: i.id}
        })
      }
    },
    mapToNewGrid(point){
      let scaleFactor = 5/this.modelInfo.settings.gridSize
      let x = (point.x - this.bottomLeftMostPoint.x)*(3/scaleFactor) + this.bottomLeftMostPoint.x
      let y = (point.y - this.bottomLeftMostPoint.y)*(3/scaleFactor) + this.bottomLeftMostPoint.y
      return {x: x, y: y}
    },
    async playAgain(){
      this.resultsDialog = false
      this.rankings = null
      this.submissionName = this.store.user.name
      this.nameEntered = false
      this.userRank = null
      await this.keepDesigning()
    },
    async keepDesigning(){
      this.store.toggleAnalyzeMode()
      this.store.minigameWeight = null
      this.store.minigameDeflection = null
      this.store.minigameUtilization = null
      this.newBestWeight = false
      await this.getUserBestWeight()
    },
    openResultsDialog(){
      this.resultsDialog = true
    },
    async submit(){
      this.submitLoading = true
      var elements = []
      var supports = []
      var pointLoads = []
      var lineLoads = []

      //Get Line Info
      for (let i = 0; i < this.store.drawingLayer.children.length; i++){
        let line = this.store.drawingLayer.children[i]
        var lineProps = {
          id: line.id,
          start: {x: line.segments[0].point.x, y: line.segments[0].point.y},
          end: {x: line.segments[1].point.x, y: line.segments[1].point.y},
          startFixity: line.data.start,
          endFixity: line.data.end,
          frameProps: line.data.frameProps,
        }
        elements.push(lineProps)
      }
      //Get Support Info
      for (let i = 0; i < this.store.supportLayer.children.length; i++){
        var support = this.store.supportLayer.children[i]
        var supportProps = {
          type: support.data.type,
          location: {x: support.data.location.x, y: support.data.location.y},
          id: support.id
        }
        supports.push(supportProps)
      }
      var pointL = this.store.loadLayer.children.filter((load) => load.data.type == 'Point Load')
      var lineL = this.store.loadLayer.children.filter((load) => load.data.type == 'Line Load')
      
      //Get Load Info
      for (let i=0; i < pointL.length; i++){
        var pointLoad = pointL[i]
        var pointLoadProps = {
          type: pointLoad.data.type,
          dir: pointLoad.data.dir,
          location: {x: pointLoad.data.location.x, y: pointLoad.data.location.y},
          magnitude: pointLoad.data.magnitude,
        }
        pointLoads.push(pointLoadProps)
      }
      for (let i=0; i < lineL.length; i++){
        var lineLoad = lineL[i]
        var lineLoadProps = {
          type: lineLoad.data.type,
          dir: lineLoad.data.dir,
          elementId: lineL[i].data.elementId,
          magnitude: lineLoad.data.magnitude,
        }
        lineLoads.push(lineLoadProps)
      }

      //Combine all canvas info      
      var canvasInfo = {
        createdBy: this.store.user,
        settings: {
          units: this.store.units,
          gridSize: this.store.gridSize,
          gridUnitMetric: this.store.gridUnitMetric,
          gridUnitImperial: this.store.gridUnitImperial,
          pointLoadMetric: this.store.pointLoadMetric,
          pointLoadImperial: this.store.pointLoadImperial,
          lineLoadMetric: this.store.lineLoadMetric,
          lineLoadImperial: this.store.lineLoadImperial,
          deflectionUnitMetric: this.store.deflectionUnitMetric,
          deflectionUnitImperial: this.store.deflectionUnitImperial,
          resultantForceUnitMetric: this.store.resultantForceUnitMetric,
          resultantForceUnitImperial: this.store.resultantForceUnitImperial,
          gravity: this.store.gravity,
          beamDegreesFromHorz: this.store.beamDegreesFromHorz,
          columnDegreesFromVert: this.store.columnDegreesFromVert,
          imageOpacity: this.store.imageOpacity,
          canvasCenter: {x: this.store.canvasCenter.x, y: this.store.canvasCenter.y}
        },
        elements: elements,
        supports: supports,
        pointLoads:pointLoads,
        lineLoads: lineLoads,
      }

      let data = {
        weight: this.store.minigameWeight,
        utilization: this.store.minigameUtilization,
        deflection: this.store.minigameDeflection,
        game: this.id,
        submissionName: this.submissionName,
        canvasInfo: canvasInfo,
        userId: this.store.user.userId
      }

      let savedMiniGameSubmission = await this.$api({
        url: "/api/minigame/_minigame/submit",
        method: "POST",
        data: {
          submission: JSON.stringify(data),
        }
      })
      let userSubmissionId = savedMiniGameSubmission.data.id
      await this.getResults(userSubmissionId)
      await this.getUserBestWeight()
      this.submitLoading = false
      this.nameEntered = true
    },
    async getResults(userSubmissionId){
      //get results
      let res = await this.$api({
        url: "/api/minigame/_minigame/get-rankings",
        method: "POST",
        data: {
          minigameId: this.id,
          numSubmissionsToReturn: null,
          userSubmissionId: userSubmissionId,
        }
      })
      if (res.data){
        this.rankings = res.data.topRankings
        if (res.data.userRank) this.userRank = res.data.userRank+1
      }
    },
    async getUserBestWeight(){
      let res = await this.$api({
        url: "/api/minigame/_minigame/get-user-best-weight",
        method: "POST",
        data: {
          minigameId: this.id,
          userId: this.store.user.userId,
        }
      })
      if (res.data){
        this.userBestWeight = res.data.weight
      }
      else this.userBestWeight = Infinity
    },
    async openLeaderBoard(){
      await this.getResults(null)
      this.leaderboardDialog = true
    },
    resetCanvas(){
      if (this.store.analyzeMode) this.store.toggleAnalyzeMode()
      this.store.drawingLayer.removeChildren()
      this.store.supportLayer.removeChildren()
      this.store.loadLayer.removeChildren()
      this.store.loadValuesLayer.removeChildren()
      this.store.memberSizesLayer.removeChildren()
      this.store.nodeLayer.removeChildren()
      this.DrawSketch()
      History.clear()
    }
  },
  unmounted(){
    if (this.store.activeTool){
      this.store.tools[this.store.activeTool].deactivate()
    }
    if (this.store.canvas.project){
      this.store.canvas.project.clear()
    }

    this.store.projectId = null
    
    //TODO - split stores up into different parts
    let user = this.store.user
    this.store.$reset()
    this.store.setUser(user)

    window.removeEventListener('resize', this.onResize, { passive: true })
    document.removeEventListener('keypress', this.keyPressed)
    History.clear()
  },
}

</script>

<style scoped>
  .canvas-style {
    touch-action: manipulation;
    position: absolute;
    top: 0px;
    left: 0px
  }
  canvas[resize]{
    width: 100vw;
    height: 100vh
  }
  .toolsUI{
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    margin-left: auto;
    margin-right: auto
  }
  .errorMessage{
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    margin-left: auto;
    margin-right: auto
  }
  .uploadImageUI{
    position: absolute;
    margin-left: 100px;
    top: 20px;
  }
  .textOutline {
    text-shadow:  1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black;
  }
  .circle {
    width: 25px;
    height: 25px;
    min-width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    margin-right: 16px;
    color: white
  }
  .instructionRow {
    color: #717171;
    margin-bottom: 24px !important
  }
</style>
