 <template>
  <v-dialog :model-value="createCustomSectionDialog" 
    persistent style="width: 570px">
    <v-card>

      <!-- Section Created -->
      <template v-if="sectionCreated">
        <v-card-title>
          {{sectionName}} was created
        </v-card-title>
        <v-card-text>
          Saved Sections can be found under the <v-icon>mdi-star-outline</v-icon> when filtering by shape
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" @click="startNewSection()">Create New Section</v-btn>
          <v-btn color="primary" @click="openCloseDialog()">Close</v-btn>
        </v-card-actions>
      </template>

      <!-- Create Section -->
      <template v-else>
        <v-card-title class="d-flex justify-space-between">
          <span>Create Custom Section</span>
          <v-icon @click="openCloseDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Custom Section Name" 
            density="compact"
            v-model="sectionName"
            variant="outlined"
            hide-details>
          </v-text-field>
          <div class="mb-2 mt-5">
            <span>Select Material</span>
            <v-select
              label="Material"
              :items="allMaterials"
              :hint="selectedMaterial ? getMaterialDescription(selectedMaterial) : ''"
              persistent-hint
              return-object
              single-line
              density="compact"
              v-model="selectedMaterial"
              variant="outlined"
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
              <template v-slot:selection="{item}">
                {{item.value.name}}
              </template>
              <template v-slot:item="{item, index}">
                <v-list-item :value="item" @click="selectedMaterial = item.value">
                  <v-list-item-title>{{ item.value.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getMaterialDescription(item.value) }}</v-list-item-subtitle>
                </v-list-item>
                <v-divider v-if="index === 0 || index === customMaterials.length"></v-divider>
              </template>
            </v-select>
          </div>

          <!-- Custom Material Selection -->
          <div v-if="selectedMaterial && selectedMaterial.name == 'Custom'" class="mx-4">
            <div class="d-flex justify-space-between mb-2 px-1">
              <v-text-field 
                label="Material Name" 
                density="compact"
                v-model="customMaterialName"
                variant="outlined"
                hide-details>
              </v-text-field>
            </div>
            
            <div class="d-flex flex-wrap">
              <v-col cols="12" class="pa-0 ma-0 mb-2" v-for="(materialProp, index) in customMaterialProps" :key="index">
                <v-text-field 
                  :label="`${materialProp.label} | ${materialProp.unit}`" 
                  density="compact"
                  v-model="materialProp.value"
                  type="number"
                  variant="outlined"
                  class="px-1"
                  hide-details>
                </v-text-field>
              </v-col>
              <v-spacer />
            </div>
            
            <div class="d-flex mb-2">
              <UnitSelection :defaultUnit="materialUnit" @unitChange="changeMaterialUnit"></UnitSelection>
              <v-col cols="6" class="d-flex pa-0 ma-0 ml-2 align-center justify-center">
                <v-btn :disabled="missingMaterialInputs" :loading="saveMaterialLoading"
                  @click="saveMaterial()" color="primary" block style="height: 40px">
                  <span class="text-white">Save</span>
                </v-btn>
              </v-col>
            </div>
          </div>

          <!-- Custom Section -->
          <div class="mt-2">
            <span>Define Section Properties</span>

            <div class="py-2 mx-3">
              <!-- <div class="d-flex justify-space-between">
                <span style="font-size:12px;" >Define By Shape</span>
              </div> -->
              <div class="d-flex justify-space-between">
                <div class="rounded d-flex align-center" style="border: 1px solid lightGrey; width:fit-content; height: 42px; padding:1px">
                  <template v-for="(shape, index) in shapes" :key="index">
                    <v-btn size="36"  @click="toggleActiveShape(shape)"
                      flat :style="shape.active ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : ''" style="margin:1px">
                      <v-icon>{{shape.icon}}</v-icon>
                      <v-tooltip activator="parent" location="top">{{shape.name}}</v-tooltip>
                    </v-btn>
                    <v-divider v-if="index==shapes.length-2" vertical thickness="1" inset color="black" class="mx-1 border-opactiy-100" />
                  </template>
                </div>
                <UnitSelection :defaultUnit="sectionUnit" @unitChange="changeSectionUnit"></UnitSelection>
              </div>
            </div>

            <!-- Define Section Properties -->
            <div class="d-flex mt-2 mx-3">
              <div class="d-flex flex-column" style="width: 55%">
                <!-- Width -->
                <template v-for="sectionProp in activeShape.props" :key="sectionProp">
                  <div class="px-0 mb-2">
                    <v-text-field 
                    :label="activeShapeName == 'Wide Flange' && sectionProp == 'thickness' ? `Web Thickness (tw) | ${getCustomSectionProps(sectionProp).unit}` : `${getCustomSectionProps(sectionProp).label} | ${getCustomSectionProps(sectionProp).unit}`" 
                    density="compact"
                    type="number"
                    v-model="getCustomSectionProps(sectionProp).value"
                    variant="outlined"
                    hide-details
                    ></v-text-field>
                  </div>
                </template>
              </div>

              <!-- Calculated Section Properties -->
              <template v-if="activeShapeName != 'Custom'">
                <v-divider vertical class="mx-4 border-opacity-50"/>
                <div class="d-flex flex-column">
                  <div>
                    <b>Ixx: </b> {{utils.Round(calcIxx, 2)}} {{sectionUnit == 'imperial' ? 'in⁴' : 'cm⁴'}}
                  </div>
                  <div>
                    <b>Iyy: </b> {{utils.Round(calcIyy, 2)}} {{sectionUnit == 'imperial' ? 'in⁴' : 'cm⁴'}}
                  </div>
                  <div>
                    <b>Area: </b> {{utils.Round(calcArea, 2)}} {{sectionUnit == 'imperial' ? 'in²' : 'cm²'}}
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="mt-6">
            <div class="d-flex mb-2">
                <v-btn @click="saveSection" :loading="saveSectionLoading"
                  color="primary" block 
                  :disabled="missionSectionInputs">
                  <span class="text-white">Save</span>
                </v-btn>
            </div>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import UnitSelection from '@/components/ui/UnitSelection.vue'
import * as utils from '@/utilities/Utils'
import {useStore} from '@/store/store'

export default {
  props: {
    createCustomSectionDialog: {
      type: Boolean,
      required: true
    },
  },
  components: {
    UnitSelection
  },
  setup(){
    const store = useStore()
    return {store, utils}
  },
  data: () => ({
    materials: [{name: "Custom", subtitle: "Define Custom Material"}],
    selectedMaterial: null,
    sectionCreated: false,
    saveMaterialLoading: false,
    saveSectionLoading: false,
    sectionName: null,

    customMaterials: [],
    defaultMaterials: [],

    materialUnit: null,
    sectionUnit: null,

    //Material Prop Units
    customMaterialProps: {
      density: {value: null, label: 'Density (γ)', unitOptions: ['lb/ft³', 'kg/m³'], unit: null},
      E: {value: null, label: 'Elastic Modulus (E)', unitOptions: ['ksi', 'GPa'], unit: null},
      yieldStress: {value: null, label: 'Yield Stress (Fy)', unitOptions: ['ksi', 'MPa'], unit: null},
      //tensileStrength: {value: null, label: 'Tens. Strength (ft)', unitOptions: ['ksi', 'MPa'], unit: null},
      //compressiveStrength: {value: null, label: 'Comp. Strength (fc)', unitOptions: ['ksi', 'MPa'], unit: null},
    },
    customMaterialName: '',

    //Section Geometry Values
    customSectionProps: {
      width: {value: null, label: 'Width (w)', unitOptions: ['in', 'cm'], unit: null},
      diameter: {value: null, label: 'Diameter (d)', unitOptions: ['in', 'cm'], unit: null},
      height: {value: null, label: 'Height (h)', unitOptions: ['in', 'cm'], unit: null},
      thickness: {value: null, label: 'Thickness (t)', unitOptions: ['in', 'cm'], unit: null},
      flangeThickness: {value: null, label: 'Flange Thickness (tf)', unitOptions: ['in', 'cm'], unit: null},
      Ixx: {value: null, label: 'Moment of Inertia (Ixx)', unitOptions: ['in⁴', 'cm⁴'], unit: null},
      Iyy: {value: null, label: 'Moment of Inertia (Iyy)', unitOptions: ['in⁴', 'cm⁴'], unit: null},
      area: {value: null, label: 'Area (A)', unitOptions: ['in²', 'cm²'], unit: null},
    },

    shapes: [
      {active: true, icon: "mdi:mdi-format-italic", name: "Wide Flange", type: 'Custom_I', props: ['width', 'height', 'thickness', 'flangeThickness']},
      {active: false, icon: "mdi:mdi-checkbox-blank-outline", name: "HSS", type: 'Custom_HSS', props: ['width', 'height', 'thickness']},
      {active: false, icon: "mdi:mdi-checkbox-blank-circle-outline", name: "HSRO", type: 'Custom_HSRO', props: ['diameter', 'thickness']},
      {active: false, icon: "mdi:mdi-checkbox-blank", name: "Rectangle", type: 'Custom_Rect', props: ['width', 'height']},
      {active: false, icon: "mdi-star-outline", name: 'Custom', type: 'Custom', props: ['width', 'height', 'Ixx', 'Iyy', 'area']}
    ],
  }),
  async created(){
    //Set Default units values
    if (this.store.units == 'imperial') {
      this.sectionUnit = 'imperial'
      this.materialUnit = 'imperial'
    } else {
      this.sectionUnit = 'metric'
      this.materialUnit = 'metric'
    }
    this.setMaterialPropUnits()
    this.setSectionPropUnits()
    
    let [customMaterials, defaultMaterials] = await this.getMaterials()
    this.customMaterials = customMaterials
    this.defaultMaterials = defaultMaterials
  },
  computed: {
    missingMaterialInputs(){
      return Object.values(this.customMaterialProps).some(prop => prop.value === null) || this.customMaterialName == null;
    },
    missionSectionInputs(){
      let sectionProps = this.getSectionProps(this.activeShapeName)
      return Object.values(sectionProps).some(prop => prop.value === null) || this.sectionName == null || this.selectedMaterial == null || (this.selectedMaterial && this.selectedMaterial.name == 'Custom')
    },
    allMaterials(){
      let allMaterials = [{name: "Custom", subtitle: "Define Custom Material"}]
      allMaterials.push(...this.customMaterials)
      allMaterials.push(...this.defaultMaterials)
      return allMaterials
    },
    activeShapeName(){
      let activeShape = this.shapes.find(shape => shape.active)
      return activeShape.name
    },
    activeShape(){
      let activeShape = this.shapes.find(shape => shape.active)
      return activeShape
    }, 
    calcIxx(){
      switch (this.activeShapeName){
        case 'Wide Flange':
          let outerI = this.customSectionProps.width.value * Math.pow(this.customSectionProps.height.value, 3) /12
          let innerI = (this.customSectionProps.width.value-this.customSectionProps.thickness.value)*Math.pow((this.customSectionProps.height.value-2*this.customSectionProps.flangeThickness.value),3)/12
          return outerI - innerI
        case 'HSS':
          let outerIHSS = (this.customSectionProps.width.value * Math.pow(this.customSectionProps.height.value, 3)) / 12;
          let innerIHSS = ((this.customSectionProps.width.value - 2*this.customSectionProps.thickness.value) * Math.pow(this.customSectionProps.height.value - 2*this.customSectionProps.thickness.value, 3)) / 12;
          return outerIHSS - innerIHSS
        case 'HSRO':
          let outerDiameter = this.customSectionProps.diameter.value
          let innerDiameter = this.customSectionProps.diameter.value - 2*this.customSectionProps.thickness.value
          return Math.PI*(Math.pow(outerDiameter, 4) - Math.pow(innerDiameter, 4))/64
        case 'Rectangle':
          return (this.customSectionProps.width.value * Math.pow(this.customSectionProps.height.value, 3)) / 12;
      }
    },
    calcIyy(){
      switch (this.activeShapeName){
        case 'Wide Flange':
          let I1 = (this.customSectionProps.height.value - 2*this.customSectionProps.flangeThickness.value) * Math.pow(this.customSectionProps.thickness.value, 3)/12
          let I2 = 2*this.customSectionProps.flangeThickness.value*Math.pow(this.customSectionProps.width.value, 3)/12
          return I1 + I2
        case 'HSS':
          let outerIHSS = (this.customSectionProps.height.value * Math.pow(this.customSectionProps.width.value, 3)) / 12;
          let innerIHSS = ((this.customSectionProps.height.value - 2*this.customSectionProps.thickness.value) * Math.pow(this.customSectionProps.width.value - 2*this.customSectionProps.thickness.value, 3)) / 12;
          return outerIHSS - innerIHSS
        case 'HSRO':
          let outerDiameter = this.customSectionProps.diameter.value
          let innerDiameter = this.customSectionProps.diameter.value - 2*this.customSectionProps.thickness.value
          return Math.PI*(Math.pow(outerDiameter, 4) - Math.pow(innerDiameter, 4))/64
        case 'Rectangle':
          return (this.customSectionProps.height.value * Math.pow(this.customSectionProps.width.value, 3)) / 12;
      }
    },
    calcArea(){
      switch (this.activeShapeName){
        case 'Wide Flange': 
          let flangeArea = 2 * this.customSectionProps.width.value * this.customSectionProps.flangeThickness.value;
          let webArea =(this.customSectionProps.height.value - 2 * this.customSectionProps.flangeThickness.value) * this.customSectionProps.thickness.value;
          return flangeArea + webArea
        case 'HSS': 
          let hssArea = (this.customSectionProps.width.value * this.customSectionProps.height.value) - ((this.customSectionProps.width.value - 2 * this.customSectionProps.thickness.value) * (this.customSectionProps.height.value - 2 * this.customSectionProps.thickness.value));
          return hssArea;
        case 'HSRO': 
          let outerDiameter = this.customSectionProps.diameter.value
          let innerDiameter = this.customSectionProps.diameter.value - 2*this.customSectionProps.thickness.value
          let hsroArea = Math.PI * (Math.pow(outerDiameter, 2) - Math.pow(innerDiameter, 2))/4
          return hsroArea
        case 'Rectangle':
          return this.customSectionProps.width.value * this.customSectionProps.height.value
      }
    }
  },
  mounted() {
  },
  methods: {
    getCustomSectionProps(shapeProp){
      for (let prop in this.customSectionProps){   
        if (prop == shapeProp) {
          return this.customSectionProps[prop]
        }
      }
    }, 
    getMaterialDescription(item){
      if (item.subtitle) return item.subtitle
      else {
        let yieldStressValue = item.yieldStress ? item.yieldStress.value : item.compressiveStrength.value
        let yieldStressUnit = item.yieldStress ? item.yieldStress.unit : item.compressiveStrength.unit
        return `E: ${item.E.value} ${item.E.unit}, fy: ${yieldStressValue} ${yieldStressUnit}, γ: ${item.density.value} ${item.density.unit} `
      }      
    },
    async saveMaterial(){
      this.saveMaterialLoading = true
      let materialProps = this.getMaterialProps()
      materialProps.name = this.customMaterialName

      let created = await this.$api({
        url: "/api/material/_material/create-material",
        method: "POST",
        data: {
          materialProps: materialProps
        }
      })
      if (!created.error){
        let newMaterial = created.data
        this.selectedMaterial = newMaterial
        this.customMaterials.push(newMaterial)
      }
      this.saveMaterialLoading = false
    },
    async getMaterials(){
      let materials = await this.$api({
        url: "/api/material/_material/get-materials",
        method: "POST",
        data: {
          unit: this.store.units
        }
      })
      return materials.data
    },
    async saveSection(){
      this.saveSectionLoading = true
      let material = this.selectedMaterial.id ? this.selectedMaterial.id : this.selectedMaterial.name

      let sectionProps = this.getSectionProps(this.activeShapeName)
      sectionProps.name = this.sectionName
      sectionProps.material = material
      sectionProps.sectionType = this.activeShape.type
      if (this.activeShapeName != 'Custom') sectionProps.area = {value: this.calcArea, unit: this.customSectionProps.area.unit}

      let created = await this.$api({
        url: "/api/section/_section/create-section",
        method: "POST",
        data: {
          sectionProps: sectionProps
        }
      })
      if (!created.error){
        this.sectionCreated = true
      }
      this.saveSectionLoading = false
      this.$emit('sectionCreated')
      this.selectedMaterial = null
    },
    getSectionProps(section){
      let shapeProps = this.shapes.find(shape => shape.name == section).props
      let sectionProps = {}
      shapeProps.forEach(prop => {
        let currentSectionProp = this.customSectionProps[prop]
        sectionProps[prop] = {
          value: currentSectionProp.value,
          unit: currentSectionProp.unit
        }
      })
      return sectionProps
    },
    getMaterialProps(){
      let materialProps = {}
      for (let materialProp in this.customMaterialProps){
        materialProps[materialProp] = {
          value: this.customMaterialProps[materialProp].value,
          unit: this.customMaterialProps[materialProp].unit
        }
      }
      return materialProps
    },
    toggleActiveShape(selectedShape){
      this.shapes.forEach(shape => {
        if (shape.name == selectedShape.name) shape.active = true
        else shape.active = false
      })
    },
    changeMaterialUnit(unit){
      this.materialUnit = unit
      this.setMaterialPropUnits()
    },
    changeSectionUnit(unit){
      this.sectionUnit = unit
      this.setSectionPropUnits()
    },
    setMaterialPropUnits(){
      for (let materialProp in this.customMaterialProps){
        let options = this.customMaterialProps[materialProp].unitOptions
        if (this.materialUnit == 'imperial') this.customMaterialProps[materialProp].unit = options[0]
        else this.customMaterialProps[materialProp].unit = options[1]
      }
    },
    setSectionPropUnits() {
      for (let sectionProp in this.customSectionProps){
        let options = this.customSectionProps[sectionProp].unitOptions
        if (this.sectionUnit == 'imperial') this.customSectionProps[sectionProp].unit = options[0]
        else this.customSectionProps[sectionProp].unit = options[1]
      }
    },
    openCloseDialog(){
      this.$emit('toggleDialogOpening')
      this.sectionCreated = false
    },
    startNewSection(){
      this.sectionCreated = false
      this.sectionName = null
      for (let sectionProp in this.customSectionProps){
        this.customSectionProps[sectionProp].value = null
      }
      this.shapes.forEach(shape => {
        shape.active = false
      })
      this.shapes[0].active = true
    }
  }
}
</script>