<template>
  <div class="pa-1 d-flex" v-if="!loading">
    <div class="d-flex rounded align-center" 
      style="border: 1px solid black; min-height: 40px; background:white">

      <!-- Select -->
      <v-btn v-if="isActiveTool('select')" @click="isActiveSubTool('select') ? store.changeTool('select', 'lasso') : store.changeTool('select', 'select')" size="36" style="margin:0 1px 0 1px" flat icon>
        <v-icon v-if="isActiveSubTool('select') || !isActiveSubTool('select') && select">mdi:mdi-cursor-default-outline</v-icon>
        <lassoIcon v-if="isActiveSubTool('lasso')" width="23" height="23" :color="'black'"/>
      </v-btn>

      <v-btn v-else-if="!expanded"
        size="36" style="margin:0 1px 0 1px" flat icon>
        <template v-if="!expanded">
          <v-icon v-if="isActiveTool('draw')">
            {{ isActiveSubTool('pen') ? 'mdi:mdi-vector-polyline' : 'mdi:mdi-vector-line' }}
          </v-icon>
          <template v-if="isActiveTool('supports')">
            <v-icon v-if="isActiveSubTool('pin')">mdi:mdi-triangle-outline</v-icon>
            <fixIcon v-if="isActiveSubTool('fix')"/>
            <rollerX v-if="isActiveSubTool('rollerX')"/>
            <rollerY v-if="isActiveSubTool('rollerY')"/>
          </template>
          
          <v-icon v-if="isActiveTool('loads') && isActiveSubTool('pointRight')">
            mdi:mdi-arrow-right
          </v-icon>
          <v-icon v-if="isActiveTool('loads') && isActiveSubTool('pointLeft')">
            mdi:mdi-arrow-left
          </v-icon>
          <v-icon v-if="isActiveTool('loads') && isActiveSubTool('pointUp')">
            mdi:mdi-arrow-up
          </v-icon>
          <v-icon v-if="isActiveTool('loads') && isActiveSubTool('pointDown')">
            mdi:mdi-arrow-down
          </v-icon>

          <template v-if="isActiveTool('loads') && store.subTool.includes('line')">
            <LineLoad v-if="isActiveSubTool('lineDown')"/>
            <LineLoad v-if="isActiveSubTool('lineUp')" style="transform:rotate(180deg)"/>
            <LineLoad v-if="isActiveSubTool('lineRight')" style="transform:rotate(-90deg)"/>
            <LineLoad v-if="isActiveSubTool('lineLeft')" style="transform:rotate(90deg)"/>
            <template v-if="isActiveSubTool('lineMass')">
              <WaveIcon :color="'black'" width="28" height="28" :strokeWidth="24"/>
              <v-icon size="10" color="grey" style="position:absolute; margin-left: -10px; margin-top:-12px">fa:fas fa-circle</v-icon>
              <v-icon size="10" color="grey" style="position:absolute; margin-right: -10px; margin-top: 12px">fa:fas fa-circle</v-icon>
            </template>
          </template>
        </template>
      </v-btn>
      <v-divider vertical class="border-opacity-100"></v-divider>
      
      <template v-if="expanded">
        <!-- Line -->
        <toggleButton
          v-if="isActiveTool('select') || isActiveTool('resize')"
          :tooltip="'Draw'" 
          @click="store.changeTool('draw', store.tools.draw.subTool)"
          :active="isActiveTool('draw')">
          <v-icon size="22">
            mdi: mdi-pencil
          </v-icon>
        </toggleButton>
        <div v-if="isActiveTool('draw')" class="d-flex align-center mr-1">
          <toggleButton
            :tooltip="'Polyline'" 
            @click="store.changeTool('draw', 'pen')"
            :active="isActiveSubTool('pen')">
            <v-icon>
              mdi:mdi-vector-polyline
            </v-icon>
          </toggleButton>
          <toggleButton
            :tooltip="'Line'" 
            @click="store.changeTool('draw', 'pencil')"
            :active="isActiveSubTool('pencil')">
            <v-icon>
              mdi:mdi-vector-line
            </v-icon>
          </toggleButton>
          <SectionSizeDropDown :inputSize="store.frameSize" @sizeSelected="store.frameSize=$event"/>
        </div>
        
        <!-- Support -->
        <toggleButton v-if="isActiveTool('select') || isActiveTool('resize')" 
          :tooltip="'Supports'" 
          @click="store.changeTool('supports', store.tools.supports.subTool)"
          :active="isActiveTool('supports')">
          <v-icon size="20">
            mdi:mdi-triangle-outline
          </v-icon>
        </toggleButton>
        <div v-if="store.activeTool=='supports'" class="d-flex align-center">
          <toggleButton 
            :tooltip="'Pin'" :active="isActiveSubTool('pin')"
            @click="store.changeTool('supports', 'pin')">
            <v-icon>
            mdi:mdi-triangle-outline
          </v-icon>
          </toggleButton>
          <toggleButton 
            :tooltip="'Fix'" :active="isActiveSubTool('fix')"
            @click="store.changeTool('supports', 'fix')">
            <fixIcon/>
          </toggleButton>
          <toggleButton 
            :tooltip="'RollerX'" :active="isActiveSubTool('rollerX')"
            @click="store.changeTool('supports', 'rollerX')">
            <rollerX/>
          </toggleButton>
          <toggleButton 
            :tooltip="'RollerY'" :active="isActiveSubTool('rollerY')"
            @click="store.changeTool('supports', 'rollerY')">
            <rollerY/>
          </toggleButton>
        </div>

        <!-- Point Load -->
        <toggleButton 
          v-if="isActiveTool('select') || isActiveTool('resize')" 
          class="d-flex align-center"
          :tooltip="'Point Load'" 
          @click="store.changeTool('loads', store.tools.loads.subToolPoint)"
          :active="isActiveTool('PointLoad')">
          <v-icon size="25">
            mdi:mdi-arrow-down
          </v-icon>
        </toggleButton>

        <div v-if="store.activeTool=='loads' && store.subTool.includes('point')" class="d-flex flex-column align-center">
          <div class="d-flex" style="margin-top: 2px">
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'pointDown')"
              :active="isActiveSubTool('pointDown')">
              <v-icon size="25" class="mt-1">
                mdi:mdi-arrow-down
              </v-icon>
            </toggleButton>
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'pointUp')"
              :active="isActiveSubTool('pointUp')">
              <v-icon size="25" class="mt-1">
                mdi:mdi-arrow-up
              </v-icon>
            </toggleButton>
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'pointRight')"
              :active="isActiveSubTool('pointRight')">
              <v-icon size="25" class="mt-1">
                mdi:mdi-arrow-right
              </v-icon>
            </toggleButton>
            <toggleButton 
              class="d-flex align-center" 
              @click="store.changeTool('loads', 'pointLeft')"
              :active="isActiveSubTool('pointLeft')">
              <v-icon size="25" class="mt-1">
                mdi:mdi-arrow-left
              </v-icon>
            </toggleButton>
          </div>
          <NumberInput 
            class="mx-2 my-1"
            @valueChange="valueChange($event, 'pointLoad')"
            :input="Number(store.pointLoad)" :label="store.getPointLoadUnit">
          </NumberInput>
        </div>

        <!-- Line Load -->
        <toggleButton 
          v-if="isActiveTool('select') || isActiveTool('resize')" 
          class="d-flex align-center"
          :tooltip="'Line Load'" 
          @click="store.changeTool('loads', store.tools.loads.subToolLine)"
          :active="isActiveTool('LineLoad')">
          <LineLoad />
        </toggleButton>

        <div v-if="store.activeTool=='loads' && store.subTool.includes('line')" class="d-flex flex-column align-center">
          <div class="d-flex" style="margin-top: 2px"> 
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'lineDown')"
              :active="isActiveSubTool('lineDown')">
              <LineLoad />
            </toggleButton>
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'lineUp')"
              :active="isActiveSubTool('lineUp')">
              <LineLoad style="transform:rotate(180deg)"/>
            </toggleButton>
            <toggleButton 
              class="d-flex align-center"
              @click="store.changeTool('loads', 'lineRight')"
              :active="isActiveSubTool('lineRight')">
              <LineLoad style="transform:rotate(-90deg)"/>
            </toggleButton>
            <toggleButton 
              class="d-flex align-center" 
              @click="store.changeTool('loads', 'lineLeft')"
              :active="isActiveSubTool('lineLeft')">
              <LineLoad style="transform:rotate(90deg)"/>
            </toggleButton>
            <toggleButton 
              class="mr-2"
              :tooltip="'Apply load as distributed point-masses for mode calculations'" 
              @click="store.changeTool('loads', 'lineMass')"
              :active="isActiveSubTool('lineMass')">
              <WaveIcon :color="'black'" width="28" height="28" :strokeWidth="24"/>
              <v-icon size="10" color="grey" style="position:absolute; margin-left: -10px; margin-top:-12px">fa:fas fa-circle</v-icon>
              <v-icon size="10" color="grey" style="position:absolute; margin-right: -10px; margin-top: 12px">fa:fas fa-circle</v-icon>
            </toggleButton>
          </div>
          <NumberInput 
            @valueChange="valueChange($event, 'lineLoad')"
            :input="Number(store.lineLoad)" :label="store.getLineLoadUnit" class="mx-2 my-1">
          </NumberInput>

        </div>
      </template>

      <v-divider v-if="store.activeTool != 'select'" vertical class="border-opacity-100"></v-divider>
      <v-btn @click="expanderClick()"
        size="34" style="margin:0 1px 0 1px" flat icon>
        <v-icon size="small">{{expanded ? 'fa: fas fa-angle-left': 'fa: fas fa-angle-right'}}</v-icon>
      </v-btn>
    </div>
  </div>

</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import lassoIcon from '@/assets/icons/lassoIcon.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import gravityIcon from '../assets/icons/gravityIcon.vue'
import SectionSizeDropDown from '@/components/ui/SectionSizeDropDown.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import LineLoad from '../assets/icons/lineLoad.vue';
import ButtonDropdown from '@/components/ui/ButtonDropdown.vue';
import DoneBtn from '@/components/ui/DoneBtn.vue';
import WaveIcon from '@/assets/icons/waveIcon.vue'

export default {
  components: { 
    toggleButton, 
    lassoIcon, 
    fixIcon, 
    rollerX, 
    rollerY, 
    gravityIcon, 
    SectionSizeDropDown,
    NumberInput,
    LineLoad,
    ButtonDropdown,
    DoneBtn,
    WaveIcon,
  },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    user: null,
    outOfComputes: false,
    computeLog: null,
    loading: false,
    expanded: false,
    select: false
  }),
  async created(){
    this.loading = true
    this.user = await this.store.getUser()
    this.computeLog = await this.store.getComputeLog()
    this.loading = false
  },
  computed: {},
  mounted() {},
  methods: {
    isActiveTool(toolName){
      if (this.store.activeTool === toolName ) return true
      else return false
    },
    isActiveSubTool(toolName){
      if (this.store.subTool == toolName) return true
      else return false
    },
    valueChange(e, prop){
      this.store[prop] = e
      if (prop.includes('Scale')){
        var forceToScale = prop.replace('Scale', '')
        this.store.analyze.redrawResult(forceToScale)
      }
    },
    expanderClick(){
      if (!this.isActiveTool('select')){
        if (this.expanded) this.store.changeTool('select', this.store.tools.select.subTool)
        else this.expanded = true
      }
      else {
        this.expanded = !this.expanded
      }
    },    
    async analyze(){
      let computeSuccessful = await this.store.toggleAnalyzeMode()
      if (computeSuccessful){
        try{
          let incrementedCompute = await this.$api({
            url: "/api/computes/_computes/increment-compute",
            method: "POST",
            data: {
              auth0User: this.user
            }
          })
          this.store.setComputeLog(incrementedCompute.data)
        } catch(error){
          
        }
      }
    }
  }
}
</script>
