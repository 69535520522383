<template>
  <div class="d-flex mr-1">

    <div class="rounded-lg py-1 d-flex bg-white elevation-1 btnContainer">

      <toggleButton 
        :tooltip="'Zoom Out'" 
        @click="zoomOut()">
        <v-icon size="18">
          fa:fa-solid fa-minus
        </v-icon>
      </toggleButton>
      <v-btn flat class="text-none" variant="plain" :ripple="false" style="height: 35px; width: 60px">
        {{formatGridSpacing}}
      </v-btn>
      <toggleButton 
        :tooltip="'Zoom In'" 
        @click="zoomIn()">
        <v-icon size="18">
          fa:fa-solid fa-plus
        </v-icon>
      </toggleButton>

      <v-divider vertical class="mx-2 border-opacity-90" thickness="1" ></v-divider>

      <!-- Ortho -->
      <toggleButton 
        :active="store.drawOrtho"
        :tooltip="'Ortho Drawing'" 
        @click="store.toggleDrawOrtho()">
        <v-icon style="transform:rotate(180deg)">fa: fas fa-t</v-icon>
      </toggleButton>

      <!-- Grid Snapping -->
      <toggleButton 
        v-if="store.userPermissions != 'Viewer'"
        :active="store.snapToGrid"
        :tooltip="'Snap To Grid'" 
        @click="store.toggleSnapToGrid">
        <SnapToGrid width="22"/>
      </toggleButton>

      <!-- Grid On -->
      <toggleButton 
        :active="store.gridOn"
        :tooltip="'Toggle Grid'" 
        @click="store.toggleGrid()">
        <GridOn width="24" height="24"/>
      </toggleButton>
    </div>

  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import lassoIcon from '@/assets/icons/lassoIcon.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import lineLoad from '@/assets/icons/lineLoad.vue'
import gravityIcon from '../assets/icons/gravityIcon.vue'
import SectionSizeDropDown from '@/components/ui/SectionSizeDropDown.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import SnapToGrid from '@/assets/icons/SnapToGrid.vue';
import GridOn from '@/assets/icons/GridOn.vue'
import {formatNum} from "@/utilities/Utils"

export default {
  components: { 
    toggleButton, 
    lassoIcon, 
    fixIcon, 
    rollerX, 
    rollerY, 
    lineLoad, 
    gravityIcon, 
    SectionSizeDropDown,
    NumberInput,
    SnapToGrid,
    GridOn,
    },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
  }),
  created(){},
  computed: {
    formatGridSpacing(){
      let gridSpacing = this.store.grid.realGridSpacing
      if (this.store.units == 'imperial'){
        if (gridSpacing < 1) return `${formatNum(gridSpacing*12, 2)} in`
        else return `${formatNum(gridSpacing, 2)} ft`
      }
      else {
        if (gridSpacing < 1) return `${formatNum(gridSpacing * 100, 2)} cm`
        else return `${formatNum(gridSpacing, 2)} m`
      }
    }
  },
  mounted() {},
  methods: {
    isActive(prop){
      if (this.store[prop]) return true
      else return false
    },
    isActiveSubTool(toolName){
      if (this.store.subTool == toolName) return true
      else return false
    },
    valueChange(e, prop){
      this.store[prop] = Number(e)
    },
    zoomOut(){
      let grid = this.store.grid
      if (grid.zoom < 0 && grid.ratio <= 0.01 && grid.cellSize <= 25){
      return 
      }
      let axisPosX = this.store.grid.axisPosX
      let axisPosY = this.store.grid.axisPosY
      this.store.grid.setZoom(-5, axisPosX, axisPosY)
    },
    zoomIn(){
      let grid = this.store.grid
      if (grid.zoom > 0 && grid.ratio >= 10 && grid.cellSize >= 35){
        return 
      }
      let axisPosX = this.store.grid.axisPosX
      let axisPosY = this.store.grid.axisPosY
      this.store.grid.setZoom(5, axisPosX, axisPosY)
    }
  }
}
</script>
