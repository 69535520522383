<template>
  <v-btn size="36" class="rounded-0 rounded-s"  @click="$emit('btnClick')"
    flat :style="active ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : 'border: 1px solid grey' ">
    <v-icon v-if="icon">{{icon}}</v-icon>
    <slot v-else name="my-icon"></slot>
    <v-tooltip activator="parent" location="bottom" v-if="!store.onMobile && tooltip">{{tooltip}}</v-tooltip>
  </v-btn>
  <v-btn class="rounded-0 rounded-e mr-1"
    flat :style="active ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : 'border: 1px solid grey' "
    style='margin-left:-2px; min-width: 15px; max-width:15px; height: 36px; padding: 10px'>
    <v-icon size="15">
      fa:fa-solid fa-caret-down
    </v-icon>
    <v-menu activator="parent" :close-on-content-click="false">
      <v-card class="d-flex pa-1 bg-white" style="margin-left: -70px">
        <template v-if="!slot">
          <toggleButton v-if="store.onMobile"
            @touchstart="decreaseMouseDown()" @touchend="decreaseMouseUp()">
            <v-icon size="18">
              fa:fa-solid fa-minus
            </v-icon>
          </toggleButton>
          <toggleButton v-else
            @mousedown="decreaseMouseDown()" @mouseup="decreaseMouseUp()">
            <v-icon size="18">
              fa:fa-solid fa-minus
            </v-icon>
          </toggleButton>
          <div v-if="store.onMobile" style="width:40px; height:40px" class="d-flex justify-center align-center">
            <span class="font-weight-500">{{store[prop]}}</span>
          </div>
          <input v-else type="number" id="numericInput" name="numericInput" v-model="store[prop]" @input="inputChange">
          <toggleButton v-if="store.onMobile"
            @touchstart="increaseMouseDown()" @touchend="increaseMouseUp()" >
            <v-icon size="18">
              fa:fa-solid fa-plus
            </v-icon>
          </toggleButton>
          <toggleButton v-else
            @mousedown="increaseMouseDown()" @mouseup="increaseMouseUp()">
            <v-icon size="18">
              fa:fa-solid fa-plus
            </v-icon>
          </toggleButton>
        </template>
        <slot v-else name="menu"></slot>
      </v-card>
    </v-menu>
  </v-btn>
</template>

<script>
import {useStore} from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import NumberInput from './NumberInput.vue'

export default {
  props: {
    icon: {type: String},
    tooltip: {type: String},
    active: {type: Boolean},
    prop: {type: String},
    slot: {type: Boolean}
  },
  emits: ['btnClick'],
  components: {
    toggleButton,
    NumberInput,
  },
  setup(){
    const store = useStore()
    return {store}
  },
  data: () => ({
    editNumber: false,
    repeatIncrease: null,
    repeatDecrease: null
  }),
  created(){
  },
  computed: {},
  mounted() {},
  methods: {
    increase(){
      this.store[this.prop]++
      this.store.analyze.redrawResult(this.prop.split('Scale')[0])
      this.valueChanged = true
    },
    decrease(){
      if (this.store[this.prop] < 2) return
      this.store[this.prop]--
      this.store.analyze.redrawResult(this.prop.split('Scale')[0])
    },
    increaseMouseDown(){
      this.increase()
      this.repeatIncrease = setInterval(this.increase, 100)
    },
    increaseMouseUp(){
      clearInterval(this.repeatIncrease)
    },
    decreaseMouseDown(){
      this.decrease()
      this.repeatDecrease = setInterval(this.decrease, 100)
    },
    decreaseMouseUp(){
      clearInterval(this.repeatDecrease)
    },
    inputChange(){
      this.store.analyze.redrawResult(this.prop.split('Scale')[0])
    }
  }
}
</script>

<style scoped>
input {
  height: 40px;
  width: 40px;
  padding: 5px;
  box-sizing: border-box;
}
/* Chrome, Safari, Edge, Opera - remove input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>