<template>
  <div class="body">
    <!-- <v-alert
      class="mb-0"
      :v-model="true"
      border="start"
      closable
      close-label="Close Alert"
      color="secondary"
      prominent
      icon="mdi:mdi-rocket-launch"
      v-if="!store.promptPWAInstall"
    >
      <b v-if="alertMessage" v-html="alertMessage"></b>
    </v-alert> -->

    <v-container>
       
      <!-- Game Announcement --> 
      <!-- <v-row class="mt-4 rounded-lg pa-10 mx-2" style="background: #F2F2F2">
        <div class="d-flex flex-column">
          <div>
            <div style="
              width: 140px;
              height: 36px;
              border: 1px solid rgb(var(--v-theme-primary));
              color: rgb(var(--v-theme-primary));
              background-color: white;
              border-radius: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: text; font-weight:bold
            ">
              LIMITED TIME
            </div>
          </div>
          <div style="line-height: 1;" class="my-2 mb-4"> 
            <span style="font-family:ruda; font-weight: bold" class="mr-2" :style="smallWidth ? 'font-size:40px' : 'font-size: 64px'">FORCESKETCH</span>
            <span style=" font-weight: bold" :style="smallWidth ? 'font-size:40px' : 'font-size: 64px'"
              class="text-green">
              MINIGAME
            </span>
          </div>
          <div style="font-size: 24px; color: #808080; margin-top: -15px">
            Compete against others to design the least weight structure
          </div>
        </div>        
      </v-row> -->
      
      <v-row class="d-flex justify-center px-12 flex-column align-center" 
        style="text-align:center; padding-top: 80px; padding-bottom:75px">
        <div class="mainHeader">
          <span class="text-primary">Smart</span> Structural Sketching
        </div>
        <div class="subHeader my-6">
          Effortlessly create 2D structural models, <br> just like sketching on paper
        </div>
        <v-btn color="black" size="x-large" @click="getStartedClick()">
          Get Started
        </v-btn>
      </v-row>

      <v-row class="d-flex flex-wrap justify-center pa-12 align-center" wrap>
        <v-col class="ml-8">
          <div class="header">
            Quickly <span class="text-green">Analyze</span> 2D Sketches
          </div>
          <div class="subHeader my-6">
            Draw out your ideas and analyze them in real-time
          </div>
        </v-col>
        <v-col >
          <v-img :src="require('@/assets/imgs/homepage1.png')"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center pa-12 align-center mb-12">
        <v-col class="mx-10">
          <v-img :src="require('@/assets/imgs/homepage2.png')"></v-img>
        </v-col>
        <v-col>
          <div class="header">
            <span class="text-purple">Results</span> At Your Fingertips
          </div>
          <div class="d-flex flex-column">
            <div class="subHeader my-6">
              Easily view and scale results
            </div>
            <ul class="ml-12 subHeader">
              <li>Reactions</li>
              <li>Deflections</li>
              <li>Moment</li>
              <li>Shear</li>
              <li>Axial</li>
              <li>Utilization</li>
              <li>Mode Shapes</li>
            </ul>
          </div> 
        </v-col>
      </v-row>

      <v-row class="d-flex flex-wrap justify-center px-12 align-center my-12" wrap>
        <v-col cols="6">
          <div class="header ml-12">
            More <span class="text-orange">Features</span>
          </div>
          
          <div class="d-flex flex-column mt-4">
            <ul class="subHeader ml-12 style">
                <li>Available in Metric and Imperial Units</li>
                <li>Extensive Default Section Library</li>
                <li>Create Custom Sections and Materials</li>
                <li>Save and Share Sketches</li>
                <li>Show and Hide Dimensions</li>
                <li>Copy and Move Elements</li>
              </ul>
          </div> 
        </v-col>
        <v-col cols="6" class="ml-12">
          <v-card>
            <v-img style="border-radius: 10px; filter: brightness(1.02)" :src="require('@/assets/imgs/ForceSketchGif.gif')"></v-img>
          </v-card>
          
        </v-col>
      </v-row>



      <!-- <v-row class="d-flex justify-center pa-12 align-center flex-column" style="margin-bottom:75px; text-align:center">
        <div class="header mt-10 mb-5">
          Choose the <span class="text-orange">Plan</span> That's Right For You
        </div> 
        <v-row class="d-flex flex-wrap justify-center">
          <FreePlan class="mx-4 mt-8" @selected="getStartedClick('Free')"/>
          <BasicPlan class="mx-4 mt-8" @selected="getStartedClick('Student')"/>
          <IndividualPlan class="mx-4 mt-8" @selected="getStartedClick('Individual')"/>
          <EnterprisePlan class="mx-4 mt-8"/>
        </v-row>
      </v-row>  -->
    </v-container> 
  </div>
</template>

<script>
import FreePlan from '@/components/plans/Free.vue'
import BasicPlan from '@/components/plans/Student.vue'
import IndividualPlan from '@/components/plans/Individual.vue'
import EnterprisePlan from '@/components/plans/Enterprise.vue'
import {useStore} from '@/store/store'
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: { 
    FreePlan, 
    BasicPlan, 
    IndividualPlan,
    EnterprisePlan, 
  },
  setup () {
    const store = useStore()
    const { loginWithRedirect } = useAuth0();
    return {
      store, 
      login: () => {
        loginWithRedirect();
      },
    }
  },
  data() {
    return {
      dialog: false,
      alert: true,
      alertMessage: null
    };
  },
  created() {
    this.alertMessage = 'Thanks for using ForceSketch. This application is currently a free public beta. During this time you can use ForceSketch freely; however, this application is under active development and features and functionality may be added and removed on a daily basis. We welcome your feedback and hope you enjoy. <br> ~ The Thornton Tomasetti | CORE studio team'
  },
  computed: {
    smallWidth(){
      if (window.innerWidth < 555) return true
      else false
    }
  },  
  methods: {
    getStartedClick(plan){
      if (!this.store.user){
        this.login()
        this.store.selectedPlan = plan
      }
      else this.$router.push('/dashboard')
    }
  }
};
</script>

<style scoped>
  .mainHeader{
    font-size:64px; 
    font-weight:bold;
    background: white;
    width:fit-content
  }
  .header{
    font-size:50px; 
    font-weight:bold;
    background: white;
    width:fit-content
  }
  .subHeader{
    font-size:24px; 
    color: grey;
    background-color: white;
    width:fit-content
  }
  .body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;
  }
  .v-col{
    max-width: 500px;
    min-width: 300px
  }
  .textOutline {
    text-shadow:  1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black;
  }

  .minigame {
    font-size: 64px;
  }
  @media (min-width: 400px) {
    .minigame {
      font-size: 40px;
    }
  }
</style>
