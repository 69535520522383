<template>
  <div v-if="!store.getterUser" class="mr-5" color="black">
    <v-btn variant="text" class="text-none" @click="login">
      <span class="font-weight-light">Sign in</span>
    </v-btn>
  </div>
  <div v-else class="mr-5" color="black">
    <v-menu>
      <template v-slot:activator="{props}">
        <v-btn icon v-if="store.getterUser">
          <v-avatar color="green" size="small" v-bind="props">
            <span class="text-white">{{store.getterUser.email.slice(0,2)}}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list density="compact"> 
          <v-list-item @click="$router.push('/account')" prepend-icon="mdi-account-circle" >
            Account
          </v-list-item>
          <v-list-item @click="logout()" prepend-icon="mdi-exit-to-app">
            Sign Out
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {useStore} from '@/store/store'
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  setup () {
    const store = useStore()
    const { loginWithRedirect} = useAuth0();
    return {
      store, 
      login: () => {
        loginWithRedirect();
      },
    }
  },
  data: () => ({
  }),
  computed: {
  },
  created(){
  },
  mounted(){
  },
  methods: {
    logout() {
      this.$auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    },
  }
};
</script>

<style scoped>

</style>
